<template>
  <div class="preview-overlay">
    <div class="bg-header">
      <img class="icon" src="/image/icon-preview.svg">
      <span class="title">{{ previewImage.name }}</span>
      <button class="close" @click="$emit('close')"><i class="icon-close color-white"/></button>
    </div>
    <div class="preview-image">
      <pdf class="image" :src="previewImage.image" v-if="this.previewImage.name.includes('pdf') || this.previewImage.name.includes('PDF')" />
      <img class="image" :src="previewImage.image" v-else>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: "PreviewFileImage",
  props: ['previewImage'],
  components:{
    pdf
  },
  methods: {
    selected(_period) {
      this.$emit('selected', _period)
    }
  }
}
</script>

<style scoped>

.preview-overlay {
  position: fixed;
  top: 0px;
  left: 0;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 400;
}

.bg-header {
  background-color : #000000;
  width: 100%;
  height: 55px;
  display: flex;
}

.bg-header .icon {
  margin-left: 26px;
  margin-top: auto;
  margin-bottom: auto;
  width: 16px;
  height: 14px;
}

.bg-header .title {
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  width: calc(100% - 88px);
  height: 24px;
  line-height: 24px;
  color: #FFFFFF;
  font-size: 16px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bg-header .close {
  margin: auto 18px auto auto;
  width: 18px;
  height: 18px;
  vertical-align: center;
}

.preview-image {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 55px);
  display: flex;
}

.preview-image .image {
  width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}

</style>