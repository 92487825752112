<template>
  <div class="all-content">
    <div class="certification-wrap">
      <div class="certification-box bg-pink">
        <span>PIN</span>
        <p class="mb-30">핀번호를 한번 더 입력해 주세요.</p>
        <div class="certification-bullets">
          <span :class="{'bullets-active' : pin.length >= 1}"></span>
          <span :class="{'bullets-active' : pin.length >= 2}"></span>
          <span :class="{'bullets-active' : pin.length >= 3}"></span>
          <span :class="{'bullets-active' : pin.length >= 4}"></span>
          <span :class="{'bullets-active' : pin.length >= 5}"></span>
          <span :class="{'bullets-active' : pin.length >= 6}"></span>
        </div>
        <button class="btn-under color-white reset-pin-btn" @click="$router.replace({name:'SignUpPinRegist'})">핀번호 다시입력</button>
      </div>
      <div class="number-area">
        <ul>
          <li>
            <button @click="numBtn('1')">1</button>
            <button @click="numBtn('2')">2</button>
            <button @click="numBtn('3')">3</button>
          </li>
          <li>
            <button @click="numBtn('4')">4</button>
            <button @click="numBtn('5')">5</button>
            <button @click="numBtn('6')">6</button>
          </li>
          <li>
            <button @click="numBtn('7')">7</button>
            <button @click="numBtn('8')">8</button>
            <button @click="numBtn('9')">9</button>
          </li>
          <li>
            <button @click="cancel()" class="cancel">취소</button>
            <button @click="numBtn('0')">0</button>
            <button @click="backspace()">
              <img src="/image/cancel.svg" alt="지우기">
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUpPinCheck",
  data() {
    return {
      pin: '',
      old_pin: '',
      maxFailCnt: 5,
      failCnt: 0,
    }
  },
  mounted() {
    this.old_pin = this.$route.params.old_pin
  },
  methods: {
    numBtn(_num) {
      this.pin = this.pin + _num
      if (this.pin.length === 6 ) {
        if (this.pin === this.old_pin) {
          //핀 등록
          let formData = new FormData()
          formData.append('pinNumber', this.old_pin)
          formData.append('pinNumberCheck', this.pin)
          this.$post(this.$USER_PIN, 'signuppincheck_userpin', formData, true,() => {
            //등록 성공
            window.setPin(this.pin)
            this.$router.replace({name: 'SignUpPinFinish'})
          }, (result) => {
            this.httpError(result.data)
          })

          //등록 실패
        } else {
          this.failCnt += 1
          this.notify('error', '핀번호가 일치하지 않습니다(' + this.failCnt + '/' + this.maxFailCnt + ')')
          this.pin = ''
          if (this.failCnt >= this.maxFailCnt){
            this.notify('error', '핀번호를 다시 설정해주세요')
            this.$router.replace({name: 'SignUpPinRegist'})
          }
        }
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      this.$router.replace({name: 'SignUpPinRegist'})
    }
  }
}
</script>

<style scoped>

</style>