<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <!--      <p>회원가입 정보 입력</p>-->
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <div class="charge-form">
          <em class="pink-title">기본 정보를<br>등록해주세요</em>
          <div v-if="groupType !== '5'">
            <p class="mb-10 mt-30 fz-18">입주건물 주소<span class="color-red">*</span></p>

            <div class="d-flex-row">
              <div class="select-disable">
                <RPInputBorder
                    :type="'text'"
                    :placeholder="'우편번호'"
                    :model="zipCode"
                    disable="true"
                    @update="(_model) => {this.zipCode=_model}"
                />
              </div>
              <button class="input-address btn-dgray-A09E9E ml-10 font-14"
                      @click="searchAddress">
                주소검색
              </button>
            </div>
            <!--            <span class="color-red">{{ errors.address }}</span>-->
            <div class="select-disable mt-10">
              <RPInputBorder
                  :type="'text'"
                  :model="address"
                  disable="true"
                  @update="(_model) => {this.address=_model}"
              />
            </div>
            <div class="select mt-10">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'상세주소를 입력해주세요'"
                  :model="addressDetail"
                  @update="(_model) => {this.addressDetail=_model}"
              />
            </div>
          </div>

          <p class="mb-10 mt-30 fz-18">{{ accountInfoStr(groupType) }}<span class="color-red">*</span></p>
          <div class="d-flex-row">
            <div class="select-disable">
              <RPInputBorder
                  :type="'text'"
                  :model="lessor"
                  :disable="true"
                  :placeholder="lessorPlaceHolder"
                  @update="(_model) => {this.lessor=_model}"
              />
            </div>
            <button class="input-address btn-dgray-A09E9E ml-10 font-14"
                    @click="authAccount">
              계좌인증
            </button>
          </div>
          <!--          <span class="color-red">{{ errors.accountInfo }}</span>-->

          <div class="select-disable mt-10">
            <RPInputBorder
                :type="'text'"
                :model="lessorBankName"
                :disable="true"
                :placeholder="'은행이름'"
                @update="(_model) => {this.lessorBankName=_model}"
            />
          </div>

          <div class="select-disable mt-10">
            <RPInputBorder
                :type="'number'"
                :model="lessorAccount"
                :disable="true"
                :placeholder="'계좌번호'"
                @update="(_model) => {this.lessorAccount=_model}"
                :input-mode="'numeric'"
            />
          </div>

          <p class="mb-10 mt-30 fz-18">입금자명 설정<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'입금자명을 입력해주세요'"
                :model="depositor"
                @update="(_model) => {this.depositor=_model}"
            />
          </div>
          <p class="mb-10 mt-30 fz-18">납입금액<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'납입금액을 입력해주세요'"
                :model="amount"
                @update="(_model) => {this.amount=_model}"
                :input-mode="'numeric'"
                :withComma="true"
            />
          </div>
          <!--          <span class="color-red">{{ errors.depositor }}</span>-->

          <p class="mb-10 mt-30 fz-18">{{ groupTypeStr(groupType) }}명<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="groupTypeStr(groupType) + '명을 입력해주세요'"
                :model="rentName"
                @update="(_model) => {this.rentName=_model}"
            />
          </div>

          <div v-if="groupType === '5'">
            <p class="mb-10 mt-30 fz-18">사용용도<span class="color-red">*</span></p>
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'사용용도를 입력해주세요'"
                  :model="usePurpose"
                  @update="(_model) => {this.usePurpose=_model}"
              />
            </div>
            <!--            <span class="color-red">{{ errors.usePurpose }}</span>-->
          </div>

          <p class="mb-10 mt-30 fz-18">첨부서류 (최대5개까지)<span class="color-red">*</span></p>
          <span class="color-gray-B8B8B8 fz-15">첨부 파일등록은 필수입니다<br>jpg/png/pdf 파일을 등록해주세요</span>

          <div class="mt-15 d-flex-row h-image-filebox-row">
            <div class="d-flex" :class="{ 'pl-10' : index > 0 }" v-for="(file, index) in files" :key="index">
              <!--              <div v-if="files.length > 0">-->
              <div class="w-image-file" v-if="index < 3">
                <div class="bg-add-image" @click="showPreview(index)">
                  <pdf :src="fileImgSrcList[index]"
                       v-if="file.name.includes('pdf') || file.name.includes('PDF')" class="pdf"/>
                  <img :src=fileImgSrcList[index] v-else class="w-100 h-100">
                </div>
                <div class="d-flex-row">
                  <p class="mr-auto w-image-file image-file-name">
                    {{ file.name }}</p>
                  <!--                    <input type="text" class="input-box question-input-box mr-auto" style="width: calc(100% - 40px)"-->
                  <!--                           readonly-->
                  <!--                           v-model="file.name"/>-->
                  <button type="button" class="btn-delete-file"
                          @click="deleteFiles(index)"><i class="icon-close color-darkgray"></i></button>
                </div>

              </div>
            </div>

            <div :class="{ 'pl-10' : files.length > 0 }" v-if="this.files.length < 3">
              <button class="bg-add-image fz-15 color-gray-B8B8B8" @click="uploadImage"><img class="pb-10"
                                                                                             src="/image/icon-add-file.svg"><br>등록
              </button>
              <input ref="fileUploader" type="file" class="input-box question-input-box" @change="upload($event)"
                     v-bind:id="'imageFileUpload'" accept="image/*, .pdf" hidden multiple>
            </div>
          </div>
          <!--          <span class="color-red">{{ errors.files }}</span>-->
          <!--        </div>-->

          <div class="mt-15 d-flex-row h-image-filebox-row" v-if="this.files.length > 2">
            <div class="d-flex" :class="{ 'pl-10' : index > 3 }" v-for="(file, index) in files" :key="index">
              <!--              <div v-if="files.length > 0">-->
              <div class="w-image-file" v-if="index > 2">
                <div class="bg-add-image" @click="showPreview(index)">
                  <pdf :src="fileImgSrcList[index]"
                       v-if="file.name.includes('pdf') || file.name.includes('PDF')" class="pdf"/>
                  <img :src=fileImgSrcList[index] v-else class="w-100 h-100">
                </div>
                <div class="d-flex-row">
                  <p class="mr-auto image-file-name"
                     style="width: calc(100% - 30px);">
                    {{ file.name }}</p>
                  <!--                    <input type="text" class="input-box question-input-box mr-auto" style="width: calc(100% - 40px)"-->
                  <!--                           readonly-->
                  <!--                           v-model="file.name"/>-->
                  <button type="button" class="btn-delete-file"
                          @click="deleteFiles(index)"><i class="icon-close color-darkgray"></i></button>
                </div>

              </div>
            </div>

            <div :class="{ 'pl-10' : files.length > 3 }" v-if="files.length < 5">
              <button class="bg-add-image fz-15" style="color: #B8B8B8;" @click="uploadImage">
                <img src="/image/icon-add-file.svg" class="pb-10"><br>등록
              </button>
              <input ref="fileUploader" type="file" class="input-box question-input-box" @change="upload($event)"
                     v-bind:id="'imageFileUpload'" accept="image/*, .pdf" hidden multiple>
            </div>
          </div>
          <!--          <span class="color-red">{{ errors.files }}</span>-->


        </div>
      </div>
      <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext()">심사 신청</button>
    </div>

    <preview-file-image
        v-show="showPreviewImage"
        :preview-image="previewImage"
        @close="showPreviewImage=false"/>

  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import EventBus from "@/main";
import PreviewFileImage from "@/components/payment/rentPayment/PreviewFileImage";
import pdf from 'vue-pdf'

export default {
  name: "RentPayment",
  components: {
    PreviewFileImage,
    RPInputBorder,
    pdf,
  },
  data() {
    return {
      type: this.$route.params.type ? this.$route.params.type : 0,
      groupType: '',  // 0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타

      cardList: [], // 등록되어있는 카드 리스트
      bankList: [], // 은행리스트
      fee: '',  // 수수료(%)
      tax: '',  // 부가세율

      zipCode: '',
      address: '',
      addressDetail: '',

      lessor: '', // 임대인 이름
      lessorPlaceHolder: '',
      lessorBankCode: '', // 은행코드
      lessorBankName: '', // 은행
      lessorAccount: '',  // 계좌
      mid: '',  // 계좌 인증 후 생성되는 고유 코드

      depositor: '',  // 예금주
      amount: '', // 납입금액
      rentName: '', // 월세명

      usePurpose: '', // 사용용도(기타타입)

      files: [],  // 첨부파일
      fileImgSrcList: [], // 이미지
      showPreviewImage: false,
      previewImage: {'image': null, 'name': ''},

      // errors: {
      //   address: '',
      //   accountInfo: '',
      //   depositor: '',
      //   usePurpose: '',
      //   files: '',
      // }
      editInfo: {},
      files_edit: [],
      deleteIdxs: [],
      amountNoFee: '',
    }
  },
  mounted() {
    this.clearData()

    EventBus.$on(this.$EVENT_ACCOUNT, function (data) {
      // console.log('test data' + data.lessorAccount + data.lessorBankCode + data.lessor)
      this.lessor = data.lessor
      this.lessorBankCode = data.lessorBankCode

      for (let i = 0; i < this.bankList.length; i++) {
        if (this.bankList[i].code === data.lessorBankCode) {
          this.lessorBankName = this.bankList[i].name
          break
        }
      }

      this.lessorAccount = data.lessorAccount
    }.bind(this))
  },
  methods: {
    getBankList() {
      this.$get(this.$BANK, 'chargeregistaccount_getbanklist', (result) => {
        this.bankList = result.data
        console.log(this.bankList)
      }, (result) => {
        this.httpError(result.data)
      })
    },
    searchAddress() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          // console.log('postdata', data)
          let fullAddr = data.address; // 최종 주소 변수
          let extraAddr = ''; // 조합형 주소 변수

          // 기본 주소가 도로명 타입일때 조합한다.
          if (data.addressType === 'R') {
            //법정동명이 있을 경우 추가한다.
            if (data.bname !== '') {
              extraAddr += data.bname;
            }
            // 건물명이 있을 경우 추가한다.
            if (data.buildingName !== '') {
              extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
            fullAddr += (extraAddr !== '' ? ' (' + extraAddr + ')' : '');
          }

          this.zipCode = data.zonecode;
          this.address = fullAddr;
        }
      }).open()
    },
    authAccount() {
      console.log(this.bankList)
      this.$router.push({name: 'RegistAccount', params: {bankList: this.bankList, groupType: this.groupType}})
    },
    uploadImage() {
      window.document.getElementById('imageFileUpload').click()
    },
    upload(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      if (files.length > 5) {
        this.notify('error', '파일은 5개까지만 첨부 가능합니다.')
        return
      }

      for (let i = 0; i < files.length; i++) {
        if (this.files.length < 5) {
          this.files.push(files[i])
          // console.log('files' + this.files[i].name)

          this.fileImgSrcList.push(URL.createObjectURL(files[i]))

        } else {
          this.notify('error', '파일은 5개까지만 첨부 가능합니다.')
          return
        }
      }

      this.$refs.fileUploader.value = ''

      this.changeNextBtn()
    },
    showPreview(_index) {
      this.previewImage.name = this.files[_index].name
      this.previewImage.image = this.fileImgSrcList[_index]
      this.showPreviewImage = true
    },
    deleteFiles(_index) {
      if (this.files[_index].idx) {
        this.deleteIdxs.push(this.files[_index].idx)
      }
      this.files.splice(_index, 1)
      this.fileImgSrcList.splice(_index, 1)

      this.changeNextBtn()
    },
    // errorClear() {
    //   this.errors = {
    //     address: '',
    //     accountInfo: '',
    //     depositor: '',
    //     files: '',
    //   }
    // },
    goNext() {
      // this.errorClear()
      // 기타(groupType = 5) 일 경우 : 주소 안받고, 사용용도 필요
      // 필수값 체크 (입주건물 주소, 임대인 계좌정보, 입금자명 설정, 첨부서류)
      this.type = String(this.type)
      this.groupType = String(this.groupType)
      console.log(this.type)
      console.log(this.groupType)
      console.log(this.amount)
        let amount = Number(this.amount.replaceAll(',', ''))
        console.log(amount)
      if (!this.type || !this.groupType) {
        this.notify('error', '오류가 발생 했습니다. 처음부터 다시 진행 해주세요.')
        // this.$router.back()
        return
      }

      if (this.groupType !== '5' && (this.zipCode === '' || this.address === '')) { // 기타 일 경우, 주소 안받음
        this.notify('error', '주소를 검색해주세요')
        return
      }

      if (this.lessor === '' || this.lessorAccount === '' || this.lessorBankName === '') {
        this.notify('error', '계좌인증을 해주세요')
        return
      }

      if (this.depositor === '') {
        this.notify('error', '입금자명을 입력해주세요')
        return
      }

      if (!amount) {
        this.notify('error', '납입금액을 입력해주세요')
        return
      }

      if (this.rentName === '') {
        this.notify('error', this.groupTypeStr(this.groupType) + '을 입력해주세요')
        return
      }

      if (this.groupType === '5' && this.usePurpose === '') {  // 기타 타입 일 경우, 사용용도
        this.notify('error', '사용용도를 입력해주세요')
        return
      }

      if (this.files.length === 0) {
        this.notify('error', '첨부서류를 등록해주세요')
        return
      }


      let formData = new FormData()

      //type : 0:등록, 1:재심사
      formData.append('type', this.type)
      formData.append('groupType', this.groupType)
      if (this.type !== '0') {
        formData.append('idx', this.editInfo.idx)
      }
      formData.append('address', this.address)
      formData.append('addressDetail', this.addressDetail)
      formData.append('zipCode', this.zipCode)
      if (this.groupType === '5') {
        formData.append('purpose', this.usePurpose)
      }
      formData.append('lessor', this.lessor)
      formData.append('lessorBankCode', this.lessorBankCode)
      formData.append('lessorBankName', this.lessorBankName)
      formData.append('lessorAccount', this.lessorAccount)
      formData.append('depositor', this.depositor)
      formData.append('amount', amount)
      formData.append('name', this.rentName)

      for (let i = 0; i < this.files.length; i++) {
        if (!this.files[i].idx) {
          formData.append('files', this.files[i])
        }
      }

      if (this.type !== '0') {
        for (let i = 0; i < this.deleteIdxs.length; i++) {
          formData.append('deleteIdx', this.deleteIdxs[i])
        }
      }

      this.$post(this.$MONTHLY_STEPONE, 'rentpayment_stepone', formData, true, (result) => {
        console.log(result, 'step one result')
        this.$router.replace({
          name: 'RentRegistSuccess', params: {
            date: result.data.date,
            amount: result.data.amount,
          }
        })
      }, (result) => {
        this.httpError(result.data)
      })
    },
    //0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타
    accountInfoStr(_val) {
      if (_val === '1') {
        return '임대인 / 아파트 계좌정보'
      } else if (_val === '3') {
        return '업체 계좌정보'
      } else if (_val === '5') {
        return '받는분 계좌정보'
      } else {
        return '임대인 계좌정보'
      }
    },
    //0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타
    groupTypeStr(_val) {
      if (_val === '0') {
        return '월세'
      } else if (_val === '1') {
        return '관리비'
      } else if (_val === '2') {
        return '보증금'
      } else if (_val === '3') {
        return '수리비'
      } else if (_val === '4') {
        return '교육비'
      } else if (_val === '5') {
        return '기타'
      } else {
        return '기타'
      }
    },
    clearData() {
      // this.groupType = ''
      // this.cardList = []
      // this.bankList = []
      // this.fee = ''

      this.zipCode = ''
      this.address = ''
      this.addressDetail = ''

      this.lessor = ''
      this.lessorBankCode = ''
      this.lessorBankName = ''
      this.lessorAccount = ''
      this.mid = ''

      this.depositor = ''
      this.amount = ''
      this.rentName = ''

      this.usePurpose = ''

      this.files = []
      this.fileImgSrcList = []
      this.deleteIdxs = []
      this.showPreviewImage = false
      this.previewImage = {'image': null, 'name': ''}

      this.groupType = this.$route.params.groupType
      this.type = this.$route.params.type

      //0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타
      if (this.groupType === '3' || this.groupType === '5') {
        this.lessorPlaceHolder = '예금주'
      } else {
        this.lessorPlaceHolder = '임대인 이름(예금주)'
      }
      this.getBankList()
    },
    changeNextBtn() {
      if (!this.type || !this.groupType || (this.groupType !== '5' && (this.zipCode === '' || this.address === '')) || this.lessor === '' || this.lessorAccount === '' || this.lessorBankName === '' ||
          this.depositor === '' || this.amount === '' || (this.groupType === '5' && this.usePurpose === '') || this.files.length === 0) {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      } else {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-pink"
      }
    },
  },
  watch: {
    '$route.params.randomVal': {
      handler: function (val) {
        if (val) {
          this.clearData()
        }
      }
    },
    zipCode() {
      this.changeNextBtn()
    },
    address() {
      this.changeNextBtn()
    },
    lessor() {
      this.changeNextBtn()
    },
    lessorAccount() {
      this.changeNextBtn()
    },
    lessorBankName() {
      this.changeNextBtn()
    },
    depositor() {
      this.changeNextBtn()
    },
    usePurpose() {
      this.changeNextBtn()
    },

  },
}
</script>

<style scoped>

.bg-add-image {
  width: calc((100vw - 60px) / 3);
  height: calc((100vw - 60px) / 3);
  /*left: 20px;*/

  border: 1px dashed #B8B8B8;
  /*box-sizing: border-box;*/
}

.bg-add-image .pdf {
  margin-right: auto;
  width: 70%;
  max-height: 100%;
}

.h-image-filebox-row {
  height: calc(((100vw - 60px) / 3) + 22px);
}

.w-image-file {
  width: calc((100vw - 60px) / 3)
}

.image-file-name {
  font-size: 10px;
  display: block;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 3px;
}

.btn-delete-file {
  margin-left: auto;
  margin-right: 5px;
  align-self: center;
  position: relative;
}

</style>
