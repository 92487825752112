<template>
  <div class="all-content" style="background: #F2F3F5">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
    </header>
    <div class="header-wrap history-main-pay-tab bg-white t-c d-flex-row w-100 sub-header border-bottom">
      <div class="pb-10 w-50" @click="$router.replace({ name : 'PGPaymentHistory' })">결제내역</div>
      <div class="pb-10 w-50 active">카톡예약</div>
<!--      <div class="pb-10 w-auto" :class="{'active' : selectedTab === 1 }" @click="selectTab(1)">카톡예약</div>-->
<!--      <div class="pb-10 w-auto" :class="{'active' : selectedTab === 2 }" @click="selectTab(2)">카톡예약 내역</div>-->
    </div>
    <div class="content-wrap-pg" v-if="selectedTab === 1">
      <div v-if="smsInfo != null && smsInfo.length > 0" class="pl-20 pr-20 pb-15">
        <section v-for="(item, index) in smsInfo" :key="index">
          <!--          <div :class="[ { 'pt-25' : index === 0 }, { 'pt-50' : index > 0 }]" class="mb-15">-->
          <div :class="[{ 'pt-50' : index > 0 }]" class="mb-15">
            <p class="fz-13 color-gray-4F4F4F">{{ item.exposureDate }}</p>
          </div>

          <section v-for="(detailItem, index) in item.elements" :key="'detail' + index"
                   @click="$router.push({name:'PaymentSMSHistoryDetail', params : { idx: detailItem.paymentTransactionIdx, state: detailItem.state }})"
                   class="white-box-br-10">
            <div class="p-box mb-15">
              <div class="d-flex-row items-center jc-between border-bottom pb-10">
                <span class="fz-14 color-gray-4F4F4F" :class="{'color-pink' : detailItem.state === '5'}">{{ detailItem.state | paymentStateFilter }}</span>
                <span class="fz-14 color-pink" v-if="detailItem.state === '5'">{{ detailItem.exposureSendTime }}</span>
                <span class="fz-14 color-gray-4F4F4F" v-else>완료{{ detailItem.successCount }}회 / 실패{{ detailItem.failureCount }}회</span>

              </div>
              <div class="mt-15">
                <span class="fz-14 color-gray-4F4F4F" :class="{ 'cancel-text' : detailItem.state === '4' }">{{ detailItem.goodsName }}</span>
              </div>
              <div class="d-flex-row jc-between mt-3 pb-5">
                <span v-if="detailItem.totalCount > 1" class="fz-14 color-gray-4F4F4F">{{
                    `${detailItem.clientContact.substring(0, 3)}-${detailItem.clientContact.substring(3, 7)}-${detailItem.clientContact.substring(7, 11)}`
                  }} 외 {{ detailItem.totalCount - 1 }} 명</span> <!-- 연락처 -->
                <span v-else class="fz-14 color-gray-4F4F4F">{{
                    `${detailItem.clientContact.substring(0, 3)}-${detailItem.clientContact.substring(3, 7)}-${detailItem.clientContact.substring(7, 11)}`
                  }}</span> <!-- 연락처 -->
                <span class="fz-18 color-gray-4F4F4F" :class="{ 'cancel-text' : detailItem.state === '4' }">{{ detailItem.amount | numberWithCommas }} 원</span>
              </div>
            </div>
          </section>
        </section>
      </div>

      <div class="list-none-wrap noCard" v-else>
        <div class="list-none">
          <p class="list-none-title fz-20 color-darkgray-4F4F4F">카톡 예약내역이 없습니다.</p>
        </div>
      </div>

    </div> <!-- /content-wrap -->

    <div v-if="selectedTab === 1" style="height: 74px; position: fixed; top: 110px; background: #F2F3F5;"
         class="d-flex-row pl-25 pr-25 jc-between w-100">
      <div class="d-flex-row mt-20 mb-30 jc-center mr-auto"
           @click="openMonthlyList">
            <span class="fz-18 mr-5 color-gray-4F4F4F">
              {{ selectedYear + '년' }}
              {{ selectedMonth + '월' }}
            </span>
        <button type="button" class="btn-back"><i class="icon-arrow-down"></i></button>
      </div>
    </div>

    <div v-if="selectedTab === 2" class="content-wrap-quick">
      <!--        <div v-if="smsInfo != null && smsInfo.length > 0" class="pl-20 pr-20 pb-15">-->
      <!--          <section v-for="(item, index) in smsInfo" :key="index">-->
      <!--            <div :class="[ { 'pt-5' : index === 0 }, { 'pt-50' : index > 0 }]" class="mb-15">-->
      <!--              <p class="fz-13 color-gray-4F4F4F">{{ item.exposureDate }}</p>-->
      <!--            </div>-->

      <!--            <section v-for="(detailItem, index) in item.elements" :key="'detail' + index"-->
      <!--                     @click="$router.push({name:'PaymentSMSHistoryDetail', params : { idx: detailItem.paymentTransactionIdx }})"-->
      <!--                     class="white-box-br-10">-->
      <!--              <div class="p-box mb-15">-->
      <!--                <div class="d-flex-row items-center jc-between border-bottom pb-10">-->
      <!--                  <span class="fz-14 color-gray-4F4F4F">결제완료</span>-->
      <!--                  <span class="fz-14 color-gray-4F4F4F">완료{{ detailItem.successCount }}회 / 실패{{ detailItem.failureCount }}회</span>-->
      <!--                </div>-->
      <!--                <div class="mt-15">-->
      <!--                  <span class="fz-14 color-gray-4F4F4F">{{ detailItem.goodsName }}</span>-->
      <!--                </div>-->
      <!--                <div class="d-flex-row jc-between mt-3 pb-5">-->
      <!--                <span v-if="detailItem.waitCount > 1" class="fz-14 color-gray-4F4F4F">{{-->
      <!--                    `${detailItem.clientContact.substring(0, 3)}-${detailItem.clientContact.substring(3, 7)}-${detailItem.clientContact.substring(7, 11)}`-->
      <!--                  }} 외 {{ detailItem.waitCount }} 명</span> &lt;!&ndash; 연락처 &ndash;&gt;-->
      <!--                  <span v-else class="fz-14 color-gray-4F4F4F">{{-->
      <!--                      `${detailItem.clientContact.substring(0, 3)}-${detailItem.clientContact.substring(3, 7)}-${detailItem.clientContact.substring(7, 11)}`-->
      <!--                    }}</span> &lt;!&ndash; 연락처 &ndash;&gt;-->
      <!--                  <span class="fz-18 color-gray-4F4F4F">{{ detailItem.amount | numberWithCommas }} 원</span>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </section>-->
      <!--          </section>-->
      <!--        </div>-->

      <div class="list-none-wrap noCard">
        <div class="list-none">
          <p class="list-none-title fz-20 color-darkgray-4F4F4F">카톡 예약내역이 없습니다.</p>
        </div>
      </div>
    </div>

    <history-month-select
        ref="historyMonthSelect"
        v-show="showMonthlyList"
        :years="yearlyDateList"
        :months="monthlyDateList"
        @close="showMonthlyList = false"
        @selected="getSelected"
    />
  </div>
</template>

<script>
import HistoryMonthSelect from "../HistoryMonthSelect";

export default {
  name: "SMSReservationHistory",
  components: {HistoryMonthSelect},
  data() {
    return {
      selectedTab: 1,

      smsInfo: [], // amount, clientContact, dday, exposureSendTime, failureCount, goodsName, paymentTransactionIdx, sendTime, state, successCount, totalCount, waitCount

      beforeRequestList: [],
      afterRequestList: [],

      selectedYear: '',
      selectedMonth: '',

      showMonthlyList: false,
      yearlyDateList: [],
      monthlyDateList: [
        {
          date: '01',
          name: '1월',
        },
        {
          date: '02',
          name: '2월',
        },
        {
          date: '03',
          name: '3월',
        },
        {
          date: '04',
          name: '4월',
        },
        {
          date: '05',
          name: '5월',
        },
        {
          date: '06',
          name: '6월',
        },
        {
          date: '07',
          name: '7월',
        },
        {
          date: '08',
          name: '8월',
        },
        {
          date: '09',
          name: '9월',
        },
        {
          date: '10',
          name: '10월',
        }, {
          date: '11',
          name: '11월',
        }, {
          date: '12',
          name: '12월',
        },
      ],
    }
  },
  created() {
  },
  mounted() {
    // if (this.$route.params.tabIndex) {
    //   this.selectedTab = this.$route.params.tabIndex
    // }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'PGPaymentHistory') {
        vm.initData()
      }
    })
  },
  methods: {
    // selectTab(_index) {
    //   this.selectedTab = _index
    //   this.$route.params.tabIndex = _index
    //
    //   // this.getReservedSMS()
    // },
    initData(){
      let current = new Date()
      this.selectedYear = String(current.getFullYear())
      let month = current.getMonth() + 1
      this.selectedMonth = month < 10 ? '0' + month : String(month)

      this.initYearlyDateList()
      this.$refs.historyMonthSelect.pickedYear = this.selectedYear
      this.$refs.historyMonthSelect.pickedMonth = this.selectedMonth
      this.getReservedSMS()
    },
    getReservedSMS() { // 카톡 예약 탭
      this.smsInfo = []

      let params = new URLSearchParams()
      params.append('date', `${this.selectedYear}-${this.selectedMonth}`)

      this.$get(this.$RESERVED_SMS + '?' + params.toString(), this.$options.name + '_getReservedSMS', (result) => {
        this.smsInfo = result.data

      }, (result) => {
        this.httpError(result.data)

      })
    },
    initYearlyDateList() {
      this.yearlyDateList = []

      let currentYear = new Date().getFullYear()
      for (let i = 0; i <= 10; i++) {
        let year = currentYear - (10 - i)
        this.yearlyDateList.push({date: year.toString(), name: year + '년'})
      }
    },
    openMonthlyList() {
      this.showMonthlyList = true
    },
    getSelected(_pickedYear, _pickedMonth) {
      this.selectedYear = _pickedYear
      this.selectedMonth = _pickedMonth

      this.getReservedSMS()
    },
  },
  filters: {
    paymentStateFilter: (state) => {
      if (state === '0'){
        return '결제요청'
      } else if (state === '1') {
        return '결제완료'
      } else if (state === '2') {
        return '결제실패'
      } else if (state === '3'){
        return '결제취소요청'
      } else if (state === '4') {
        return '결제취소'
      } else if (state === '5'){
        return '결제예정시간'
      }
    },
    // 0: 요청
    // 1: 성공
    // 2: 실패
    // 3: 취소 실패
    // 4: 취소 성공
    // 5: 요청 전
  },

}
</script>

<style scoped>

</style>