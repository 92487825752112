<template>
  <div class="all-content bg-white deli-agent">
  <div class="content-wrap">
    <Menu ref="myInfoMenu"/>
    <div class="content-main">
      <div class="main-wrap pb-10">
        <div class="charge-form">
          <div v-show="deliApp.length > 0">
            <p class="mb-10 fz-16 group">배달앱</p>
            <div v-for="deli in deliApp" :key="deli.type">
              <p class="fz-14 mt-20 pl-10">{{ deli.name }}</p>
              <div class="mt-10 select">
                <div class="input-close h-100 border-zero">
                  <input class="h-100 fz-16 color-darkgray input-disabled" :value="deli.id" disabled/>
                </div>
              </div>
            </div>
          </div>

          <div v-show="cardSales.length > 0" :class="{ 'mt-30' : deliApp.length > 0 }">
            <p class="mb-10 fz-16 group">카드매출</p>
            <div v-for="card in cardSales" :key="card.type">
              <p class="fz-14 mt-20 pl-10">{{ card.name }}</p>
              <div class="mt-10 select">
                <div class="input-close h-100 border-zero">
                  <input class="h-100 fz-16 color-darkgray input-disabled" :value="card.id" disabled/>
                </div>
              </div>
            </div>
          </div>
          <div v-show="vans.length > 0" class="mt-30">
            <p class="mb-10 fz-16 group">VAN사 정보</p>
            <div v-for="van in vans" :key="van.type">
              <p class="fz-14 mt-20 pl-10">{{ van.name }}</p>
              <div class="mt-10 select">
                <div class="input-close h-100 border-zero">
                  <input class="h-100 fz-16 color-darkgray input-disabled" :value="van.id" disabled/>
                </div>
              </div>
            </div>
          </div>
          <p class="mt-30 mb-30 t-c color-darkgray-4F4F4F">매출알림톡 정보 수정을 원할 경우 <br/>고객센터에 문의하세요.</p>
        </div>
      </div>
      <footer class="list-box p-20 d-flex t-c section bg-white mt-30">
          <p>1877-5916</p>
          <p>Copyright ©2021 WHOLEBIG Co., Ltd.</p>
          <p>All rights reserved.</p>
      </footer>
    </div>
  </div>
</div>
</template>

<script>
import Menu from '@/components/myinfo/MenuCmp'

export default {
  name: "SalesAlert",

  components: {
    Menu: Menu,
  },

  data() {
    return {
      deliApp: [],
      cardSales: [],
      vans: [],
    }
  },

  created() {
    this.$get(this.$USER_SALES_NOTIFICATION, 'user_sales_noti',(result) => {
      let notiInfo = result.data

      notiInfo.forEach(noti => {
        if(noti.group === '0') {
          this.deliApp = noti.data
        } else if(noti.group === '1') {
          this.cardSales = noti.data
        } else {
          this.vans = noti.data
        }
      })

      // console.log(this.cardSales)
    }, () => {
      // console.log(result)
    })
  },

  mounted() {
    this.$refs.myInfoMenu.focusTab(2)
  },
}
</script>

<style scoped>

</style>
