<template>
  <div class="confirm-wrap" v-if="show" @click.self="close()">
    <div class="confirm-layer">
      <div class="character" v-show="showImage">
        <img src="/image/wb_logo.png" alt="캐릭터">
      </div>
      <div class="confirm-layer-box pt-0" :class="{'mt-30' : !showImage}">
        <h3 v-if="title !== ''">{{ title }}</h3>
        <p :class="{ 'pt-10': title !== '' }" v-html="msg"></p>
      </div>
      <button class="btn btn-m btn-pink" @click="ok()">확 인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      show: false,
      title: '',
      msg: '',
      showImage: false,
      callback: null,
      bgClickClose: false,
    }
  },
  methods: {
    showAlert(title, msg, callback, showImage, bgClickClose) {
      this.title = title
      this.msg = msg
      this.callback = callback
      this.showImage = showImage
      this.bgClickClose = bgClickClose

      this.show = true
    },
    close() {
      if (this.bgClickClose){
        this.show = false
      }
    },
    ok() {
      // this.close()
      this.show = false
      this.callback()
    },
  }
}
</script>

<style scoped>
.overlay_popup {
  z-index: 100;
  background: #fff;
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  top: 50%;
}
.overlay_bg {
  z-index: 100;
}
</style>
