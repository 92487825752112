<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list">
        <p>
          <img class="check-pink mb-30 mt-50" src="/image/bang_pink.svg" alt="캐릭터">
          <em class="pink-title weight-light">등록된 아이디가 없습니다.</em>
          <small class="t-c mt-10 font-14 color-gray">확인 후 다시 시도하세요.</small>
        </p>
        <p>
          <img src="/image/wb_logo.png" alt="캐릭터">
        </p>
      </div>
    </div>
    <button class="btn-l btn-pink" @click="$router.back()">확인</button>
  </div>
</template>

<script>
export default {
  name: "SsoFindPwNoId"
}
</script>

<style scoped>

</style>
