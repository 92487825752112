<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list">
        <p>
          <img class="check-pink mt-50" src="/image/check_blue.png" alt="캐릭터">
          <em class="t-c pink-title weight-light">비밀번호가 변경되었습니다.</em>
<!--          <strong class="mt-20 fz-20 weight-normal t-c">{{ $route.params.number | cardNumberMasking }}</strong>-->
          <!--          <strong v-show="$route.params.alias !== ''" class="mt-20 fz-16 weight-normal t-c color-darkgray">신용카드 별명 : {{ $route.params.alias }}</strong>-->
        </p>
        <p>
          <img src="/image/wb_logo.png" alt="캐릭터">
        </p>
<!--        <em class="mb-30 t-c">{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</em>-->
      </div>
    </div>
    <button class="btn-pink mr-auto btn-m" @click="goLogin">확 인</button>
  </div>
</template>

<script>
export default {
  name: "ChangePasswordSuccess",
  methods: {
    goLogin() {
      this.$get(this.$AUTH_LOGOUT, 'myinfo_checkid',() => {
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('refreshToken')
        localStorage.removeItem('passToken')
        // localStorage.removeItem('useLocalBio')
        this.$router.replace({ name: 'Login' })
      }, (result) => {
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
