<template>
    <div class="all-content">
        <header class="header-wrap">
        </header>
        <div class="content-wrap bg-white content-row pb-80">
            <div class="confirm-list">
                <p>
                    <img src="/image/wb_logo.png" alt="캐릭터">
                    <span>
            {{ payAmount | numberWithCommas }}
            <i> 원</i>
            <br/>
            결제완료
          </span>
                    <em>결제가 완료되었습니다.</em>
                </p>
                <ul class="price-list">
                    <li>
                        <strong>카드번호</strong>
                        <em>{{ cardNo }}</em>
                    </li>
                    <li>
                        <strong>결제일시</strong>
                        <em>{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</em>
                    </li>
                    <li class="b-none">
                    </li>
                </ul>
            </div>
        </div>
        <div class="align-bottom d-flex-row w-100">
            <button class="btn-dgray-4D4D4D mr-auto btn-half" @click="printReceipt">영수증 확인</button>
            <button class="btn-pink ml-auto btn-half" @click="$router.go(-2)">확인</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "HandPaymentSuccess",
    data() {
        return {
            payAmount: '',
            cardNo: '',
            paymentInfo: {
                approvalCE: {
                    payResponseAmt: "",
                    payResponseApprovalNo: "",
                    payResponseApprovalYMDHMS: "",
                    payResponseCardId: "",
                    payResponseCardNm: "",
                    payResponseCertYn: "",
                    payResponseCode: "",
                    payResponseContractYn: "",
                    payResponseInstallment: "",
                    payResponseMsg: "",
                    payResponseOrderNo: "",
                    payResponsePartCancelFlag: "",
                    payResponsePayDate: "",
                    payResponsePayTime: "",
                    payResponsePayType: "",
                    payResponsePgSeq: "",
                    payResponseProductType: "",
                    payResponseRemainAmt: "",
                    payResponseSellMm: "",
                    payResponseTestYn: "",
                    payResponseTranSeq: "",
                    payResponseZerofeeYn: ""
                },
                shopData: {
                    shopName: "",
                    business: "",
                    address: "",
                    addressDetail: "",
                    name: "",
                    shopHp: "",
                    goodsName: ""
                },
                cardNo: "",
                idx: "",
            },
            timeout: null,
        }
    },
    mounted() {
        this.payAmount = this.$route.params.payAmount
        this.paymentInfo = JSON.parse(this.$route.params.paymentInfo)
        this.cardNo = this.paymentInfo.cardNumber

        window.addEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
        window.addEventListener(this.$EVENT_PRINT, this.printResult)
    },
    beforeDestroy() {
        window.removeEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
        window.removeEventListener(this.$EVENT_PRINT, this.printResult)

        clearTimeout(this.timeout)
    },
    methods: {
        printReceipt() {
            this.$router.push({name: 'PaymentHistoryDetail', params: {idx: this.paymentInfo.idx, confirmReceipt: true}})
            //디바이스 연결 상태 요청
            // window.requestConnectDevice()
        },
        connectResult(e) {
            let result = e.detail
            if (result === '1') {

                this.timeout = setTimeout(() => {
                    let date = this.paymentInfo.approvalCE.payResponseApprovalYMDHMS.substr(0, 4) + '.' +
                        this.paymentInfo.approvalCE.payResponseApprovalYMDHMS.substr(4, 2) + '.' +
                        this.paymentInfo.approvalCE.payResponseApprovalYMDHMS.substr(6, 2) + ' ' +
                        this.paymentInfo.approvalCE.payResponseApprovalYMDHMS.substr(8, 2) + ':' +
                        this.paymentInfo.approvalCE.payResponseApprovalYMDHMS.substr(10, 2) + ':' +
                        this.paymentInfo.approvalCE.payResponseApprovalYMDHMS.substr(12, 2)

                    let installment = (this.paymentInfo.approvalCE.payResponseInstallment * 1) === 0 ? '일시불' :
                        (this.paymentInfo.approvalCE.payResponseInstallment * 1) + ' 개월'

                    //인쇄
                    window.addPrintBoldString('영수증\n')
                    window.addPrintString('상호명 : ' + this.paymentInfo.shopData.shopName + '\n')
                    window.addPrintString('대표자 : ' + this.paymentInfo.shopData.name + '\n')
                    window.addPrintString('사업자번호 : ' + this.paymentInfo.shopData.business + '\n')
                    window.addPrintString('전화번호 : ' + this.paymentInfo.shopData.shopHp + '\n')
                    window.addPrintString('주소 : ' + this.paymentInfo.shopData.address + ' ' + this.paymentInfo.shopData.addressDetail + '\n')
                    window.addPrintBoldString(this.cardNo + '\n')
                    window.addPrintString('거래일시 : ' + date + '\n')
                    window.addPrintBoldString('승인번호 : ' + this.paymentInfo.approvalCE.payResponseApprovalNo + '\n')
                    window.addPrintString('할부 : ' + installment + '\n')
                    window.addPrintBoldString('거래금액 : ' + this.$options.filters.numberWithCommas(this.paymentInfo.approvalCE.payResponseAmt) + ' 원\n')
                    window.addPrintString('감사합니다.\n')

                    window.requestPrintStart()
                }, 1000)

            } else {
                this.notify('error', '기기 연결에 실패했습니다.', false)
            }
        },
        printResult(e) {
            let result = e.detail
            if (result === '1') {
                console.log('인쇄에 성공했습니다.')
            } else {
                console.log('인쇄에 실패했습니다.')
            }
        }
    }
}
</script>

<style scoped>

</style>
