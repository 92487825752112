<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list h-60">
      <div class="d-flex-row jc-between">
        <h5>할부기간 선택</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul class="content-list">
        <li>
          <a @click="selected('default')">
            <span>D+5 (영업일 기준)</span>
          </a>
        </li>
        <li>
          <a @click="selected('user')">
            <span>사용자 선택</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectPaymentType",
  methods: {
    selected(_period) {
      this.$emit('selected', _period)
    }
  }
}
</script>

<style scoped>

</style>