<template>
    <div class="all-content bg-white sms-pay scroll-hidden" ref="container">
        <header class="header-wrap">
            <button type="button" class="btn-back" @click="$router.push({ name: 'SMSPayment' })"><i
                class="icon-back"></i>
            </button>
            <p>연락처</p>
        </header>

        <div class="content-wrap">
            <div class="main-wrap pb-25">
                <div class="charge-form pt-0">
                    <section>
                        <div class="white-box">
                            <div class="payment-SMS-detail-top-box">
                                <div class="d-flex-row jc-between">
                                    <span class="payment-SMS-detail-tit">상품명</span>
                                    <span class="payment-SMS-detail-body">{{ goodsName }}</span>
                                </div>
                                <div class="d-flex-row jc-between mt-10 mb-10 jc-between">
                                    <span class="payment-SMS-detail-tit">상품금액</span>
                                    <span class="payment-SMS-detail-body">{{ amount | numberWithCommas }} 원</span>
                                </div>
                                <div class="d-flex-row jc-between mt-10 mb-10">
                                    <span class="payment-SMS-detail-tit">부과세</span>
                                    <span class="payment-SMS-detail-body" v-if="taxType === '0'">과세</span>
                                    <span class="payment-SMS-detail-body" v-else>비과세</span>
                                </div>
                                <div class="d-flex-row jc-between mt-10 mb-10">
                                    <span class="payment-SMS-detail-tit">발송</span>
                                    <span class="payment-SMS-detail-body" v-if="sendType === '0'">즉시발송</span>
                                    <span class="payment-SMS-detail-body" v-else>{{ sendTime }}</span>
                                </div>
                                <div class="d-flex-row jc-between mt-10">
                                    <span class="payment-SMS-detail-tit">메모</span>
                                    <span class="payment-SMS-detail-body">{{ memo }}</span>
                                </div>
                            </div>
                        </div>
                    </section>

                    <p class="mb-10 mt-30 fz-16">연락처<span class="color-red">*</span><span class="note">&#8251; 카톡이 없는 경우 SMS로 전송합니다.</span>
                    </p>
                    <div class="d-flex-row">
                        <div class="select no-right-border w-60">

                            <RPInputBorder
                                :type="'text'"
                                :placeholder="'이름'"
                                :model="name"
                                @update="(_model) => {this.name = _model}"
                                :max-length="11"
                            />
                        </div>
                        <div class="select no-right-border">

                            <RPInputBorder
                                :type="'number'"
                                :placeholder="'전화번호 입력'"
                                :model="phoneNumber"
                                @update="(_model) => {this.phoneNumber = _model}"
                                :max-length="11"
                                :input-mode="'numeric'"
                            />
                        </div>
                        <button class="input-address btn-gray-E8E8E8 font-16" @click="addPhoneNumber">
                            추가
                        </button>
                    </div>
                    <button class="search-box" @click="searchContact">내 연락처에서 찾기</button>
                </div><!-- /charge-form -->
            </div><!-- /main-wrap -->
            <div class="bar-10"></div>

            <div v-if="displayBeginning !== ''" class="fixed-beginning-add">
                <strong class="fz-15 color-darkgray">{{ displayBeginning }}</strong>
            </div>

            <!-- 연락처가 있을 때 -->
            <div id="contactListDiv" v-if="clientContactList.length > 0" class="content-row pt-25 pb-50">
                <p class="pb-10">총 {{ clientContactList.length }}명</p>

                <div class="d-flex-row">
                    <div style="width: calc(100% - 30px)">
                        <div v-for="(char, index) in charCodeList" :key="index">
                            <div v-if="char.dataCount > 0" :class="{ 'pt-20' : index > 0 }">
                                <!--          <strong :id="'charKeyDiv' + index" class="fz-15 color-darkgray" v-if="index === (charCodeList.length - 1)">#</strong>-->
                                <strong :id="'charKeyDiv' + index" class="fz-15 color-darkgray">{{ char.key }}</strong>
                                <div class="select contact" v-for="(contact, index) in displayContactInfo[char.key]"
                                     :key="'contact' + index" :class="{ 'mt-10' : index === 0 }">
                                    <div class="input-close h-100 border-zero">
                        <span type="text" class="h-100 fz-15 w-100 p-15">
                          {{ contact.name }} {{ contact.phone }}
                        </span>
                                        <span @click="deleteSelectedContact(index, char.key)" class="mr-20"><i
                                            class="icon-close fz-10"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="beginning-char-group">
                        <div v-for="(char, index) in charCodeList" :key="index">
                            <p @click="selectedBeginning(index)">
                                {{ char.key }}</p>
                        </div>
                    </div>
                </div>
                <!--        <div class="select contact" v-for="(contact, index) in clientContactList" :key="index">-->
                <!--          <div class="input-close h-100 border-zero">-->
                <!--                        <span type="text" class="h-100 fz-15 w-100 p-15">-->
                <!--                          {{ contact.name }} {{ contact.phone }}-->
                <!--                        </span>-->
                <!--            <span @click="deleteSelectedContact(index)" class="mr-20"><i class="icon-close fz-10"></i></span>-->
                <!--          </div>-->
                <!--        </div>-->
            </div>

            <!-- 연락처가 없을 때 -->
            <div v-else class="content-row pt-25">
                <p class="pb-50">총 0명</p>
                <div class="d-flex-row pt-10 jc-center">
                    <p class="color-gray-C4C4C4 fz-16 t-c">연락처를 추가하세요.</p>
                </div>
            </div>
            <button id="sendBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="send">SMS 발송</button>

        </div><!-- /content-wrap -->
    </div>
</template>

<script>
import RPInputBorder from "../../common/RPInputBorder";

export default {
    name: "SMSContactAdd",

    components: {RPInputBorder},

    data() {
        return {
            goodsName: '',  // 상품명
            amount: '', // 결제금액
            taxType: '',  // 과세여부(0: 과세, 1: 비과세)
            sendType: '', // 발송타입(0: 즉시발송, 1: 예약발송)
            sendTime: '', // 예약발송 시간
            memo: '', // 메모
            additionType: '', // 주소요청여부(0: 요청안함 1:주소요청)

            name: '',
            phoneNumber: '',
            selectedPhoneNumber: '',
            selectedName: '',
            selectedCount: 0,

            contactList: [],  // 연락처 리스트
            selectedContactCnt: 0,
            clientContactList: [],

            charCodeList: [], // 자음 코드 리스트
            displayContactInfo: {},

            scrollY: 0,
            displayBeginning: '',
        }
    },
    mounted() {
        this.goodsName = this.$route.params.goodsName
        this.amount = this.$route.params.amount
        this.taxType = this.$route.params.taxType
        this.sendType = this.$route.params.sendType
        this.sendTime = this.$route.params.sendTime
        this.memo = this.$route.params.memo
        this.additionType = this.$route.params.additionType

        if (this.$route.params.clientContactList) {
            this.clientContactList = this.$route.params.clientContactList
            this.changeNextBtn()

            console.log('mounted')
        }

        window.addEventListener(this.$EVENT_CONTACTS, this.setContact)
        window.addEventListener('scroll', this.onScrollEvent, true)

        this.initCharCodeList()
    },
    beforeDestroy() {
        window.removeEventListener(this.$EVENT_CONTACTS, this.setContact)
        window.removeEventListener('scroll', this.onScrollEvent, true)
    },
    methods: {
        initCharCodeList() {
            this.charCodeList = [{"key": "ㄱ", "value": "가".charCodeAt(0), "dataCount": 0},
                {"key": "ㄴ", "value": "나".charCodeAt(0), "dataCount": 0},
                {"key": "ㄷ", "value": "다".charCodeAt(0), "dataCount": 0},
                {"key": "ㄹ", "value": "라".charCodeAt(0), "dataCount": 0},
                {"key": "ㅁ", "value": "마".charCodeAt(0), "dataCount": 0},
                {"key": "ㅂ", "value": "바".charCodeAt(0), "dataCount": 0},
                {"key": "ㅅ", "value": "사".charCodeAt(0), "dataCount": 0},
                {"key": "ㅇ", "value": "아".charCodeAt(0), "dataCount": 0},
                {"key": "ㅈ", "value": "자".charCodeAt(0), "dataCount": 0},
                {"key": "ㅊ", "value": "차".charCodeAt(0), "dataCount": 0},
                {"key": "ㅋ", "value": "카".charCodeAt(0), "dataCount": 0},
                {"key": "ㅌ", "value": "타".charCodeAt(0), "dataCount": 0},
                {"key": "ㅍ", "value": "파".charCodeAt(0), "dataCount": 0},
                {"key": "ㅎ", "value": "하".charCodeAt(0), "dataCount": 0},
                {"key": "힣", "value": "힣".charCodeAt(0), "dataCount": 0},
                {"key": "A", "value": "A", "dataCount": 0},
                {"key": "B", "value": "B", "dataCount": 0},
                {"key": "C", "value": "C", "dataCount": 0},
                {"key": "D", "value": "D", "dataCount": 0},
                {"key": "E", "value": "E", "dataCount": 0},
                {"key": "F", "value": "F", "dataCount": 0},
                {"key": "G", "value": "G", "dataCount": 0},
                {"key": "H", "value": "H", "dataCount": 0},
                {"key": "I", "value": "I", "dataCount": 0},
                {"key": "J", "value": "J", "dataCount": 0},
                {"key": "K", "value": "K", "dataCount": 0},
                {"key": "L", "value": "L", "dataCount": 0},
                {"key": "M", "value": "M", "dataCount": 0},
                {"key": "N", "value": "N", "dataCount": 0},
                {"key": "O", "value": "O", "dataCount": 0},
                {"key": "P", "value": "P", "dataCount": 0},
                {"key": "Q", "value": "Q", "dataCount": 0},
                {"key": "R", "value": "R", "dataCount": 0},
                {"key": "S", "value": "S", "dataCount": 0},
                {"key": "T", "value": "T", "dataCount": 0},
                {"key": "U", "value": "U", "dataCount": 0},
                {"key": "V", "value": "V", "dataCount": 0},
                {"key": "W", "value": "W", "dataCount": 0},
                {"key": "X", "value": "X", "dataCount": 0},
                {"key": "Y", "value": "Y", "dataCount": 0},
                {"key": "Z", "value": "Z", "dataCount": 0},
                {"key": "#", "value": "#", "dataCount": 0}]

            // this.displayContactInfo['#'] = []
            this.addContact()
        },
        addContact() {
            for (let i = 0; i < this.charCodeList.length; i++) {
                this.displayContactInfo[this.charCodeList[i].key] = []
            }

            for (let i = 0; i < this.clientContactList.length; i++) {
                let person = this.clientContactList[i].name
                let isContain = false
                for (let j = 0; j < this.charCodeList.length; j++) {
                    if (person.charCodeAt(0) >= this.charCodeList[j].value && person.charCodeAt(0) < this.charCodeList[j + 1].value) {
                        this.displayContactInfo[this.charCodeList[j].key].push({
                            "idx": i,
                            "name": person,
                            "isChecked": this.clientContactList[i].isChecked,
                            "phone": this.clientContactList[i].phone
                        })
                        this.charCodeList[j].dataCount += 1
                        isContain = true
                        break;
                    } else if (person.substring(0, 1).toUpperCase() === this.charCodeList[j].value) {
                        this.displayContactInfo[this.charCodeList[j].key].push({
                            "idx": i,
                            "name": person,
                            "isChecked": this.clientContactList[i].isChecked,
                            "phone": this.clientContactList[i].phone
                        })
                        this.charCodeList[j].dataCount += 1
                        isContain = true
                        break;
                    }
                }
                if (!isContain) {
                    this.displayContactInfo['#'].push({
                        "idx": i,
                        "name": person,
                        "isChecked": this.clientContactList[i].isChecked,
                        "phone": this.clientContactList[i].phone
                    })
                    this.charCodeList[this.charCodeList.length - 1].dataCount += 1
                }
            }
            console.log(this.displayContactInfo)
        },
        onScrollEvent(e) {
            this.scrollY = e.target.scrollTop

            let showBeginning = false
            for (let i = 0; i < this.charCodeList.length; i++) {
                if (this.charCodeList[i].dataCount > 0) {
                    if (this.scrollY >= window.document.getElementById('contactListDiv').offsetTop + window.document.getElementById('charKeyDiv' + i).offsetTop) {
                        this.displayBeginning = this.charCodeList[i].key
                        showBeginning = true
                    }
                }
            }

            if (!showBeginning) {
                this.displayBeginning = ''
            }
        },
        addPhoneNumber() {  // 전화번호 추가
            if (this.name === '') {
                this.notify('error', '이름을 입력해주세요.')
                return
            }

            if (this.phoneNumber === '') {
                this.notify('error', '연락처를 입력해주세요.')
                return
            }

            if (this.phoneNumber.length < 10) {
                this.notify('error', '연락처를 정확하게 입력해주세요.')
                return
            }

            let added = false
            for (let i = 0; i < this.clientContactList.length; i++) {
                if (this.phoneNumber === this.clientContactList[i].phone) {
                    added = true
                    this.notify('error', '이미 추가된 연락처입니다.')
                    return
                }
            }

            if (!added) {
                this.clientContactList.push({
                    name: this.name,
                    phone: this.phoneNumber,
                    displayValue: this.phoneNumber + '  ' + '이름없음'
                })
                this.phoneNumber = ''
                this.name = ''

                let i = this.clientContactList.length - 1
                let person = this.clientContactList[i].name
                let isContain = false
                for (let j = 0; j < this.charCodeList.length; j++) {
                    if (person.charCodeAt(0) >= this.charCodeList[j].value && person.charCodeAt(0) < this.charCodeList[j + 1].value) {
                        this.displayContactInfo[this.charCodeList[j].key].push({
                            "idx": i,
                            "name": person,
                            "isChecked": this.clientContactList[i].isChecked,
                            "phone": this.clientContactList[i].phone
                        })
                        this.charCodeList[j].dataCount += 1
                        isContain = true
                        break;
                    } else if (person.substring(0, 1).toUpperCase() === this.charCodeList[j].value) {
                        this.displayContactInfo[this.charCodeList[j].key].push({
                            "idx": i,
                            "name": person,
                            "isChecked": this.clientContactList[i].isChecked,
                            "phone": this.clientContactList[i].phone
                        })
                        this.charCodeList[j].dataCount += 1
                        isContain = true
                        break;
                    }
                }
                if (!isContain) {
                    this.displayContactInfo['#'].push({
                        "idx": i,
                        "name": person,
                        "isChecked": this.clientContactList[i].isChecked,
                        "phone": this.clientContactList[i].phone
                    })
                    this.charCodeList[this.charCodeList.length - 1].dataCount += 1
                }

                this.changeNextBtn()
            }
        },
        selectedBeginning(_index) {
            if (this.charCodeList[_index].dataCount > 0) {
                this.$refs.container.scrollTop = window.document.getElementById('contactListDiv').offsetTop + window.document.getElementById('charKeyDiv' + _index).offsetTop
            }
        },
        searchContact() {
            // 연락처 요청
            window.requestContact()
        },

        setContact(e) {
            // this.searchKeyword = ''
            let contacts = e.detail
            this.contactList = JSON.parse(contacts)

            let tmp_clientContactList = {}
            for (let i = 0; i < this.clientContactList.length; i++) {
                tmp_clientContactList[this.clientContactList[i].phone] = true
            }

            this.selectedContactCnt = 0
            for (let i = 0; i < this.contactList.length; i++) {
                if (tmp_clientContactList[this.contactList[i].phone]) {
                    this.contactList[i].isChecked = true
                    this.selectedContactCnt += 1
                } else {
                    this.contactList[i].isChecked = false
                }
            }

            this.$router.push({
                name: 'SMSContactSelect', params: {
                    contactList: this.contactList,
                    goodsName: this.goodsName,
                    amount: this.amount,
                    taxType: this.taxType,
                    sendType: this.sendType,
                    sendTime: this.sendTime,
                    memo: this.memo,
                    additionType: this.additionType,
                    selectedContactCnt: this.selectedContactCnt,
                    clientContactList: this.clientContactList
                }
            })
        },

        deleteSelectedContact(_index, _charKey) {
            this.clientContactList.splice(this.displayContactInfo[_charKey][_index].idx, 1)
            this.displayContactInfo[_charKey].splice(_index, 1)

            this.changeNextBtn()
        },

        send() {
            if (this.clientContactList.length <= 0) {
                this.notify('error', '발송할 연락처를 추가해주세요')
                return
            }

            let formData = new FormData()

            if (this.goodsName !== '') {
                formData.append('goodsName', this.goodsName)
            }

            formData.append('amount', this.amount)

            formData.append('taxType', this.taxType)
            formData.append('sendType', this.sendType)

            if (this.sendType === '1') {
                formData.append('sendTime', this.sendTime.replaceAll('.', '').replace(' ', '').replace(':', ''))  // 202109301919 YYYYMMDDHHMM
            }

            if (this.memo !== '') {
                formData.append('memo', this.memo)
            }

            for (let i = 0; i < this.clientContactList.length; i++) {
                formData.append(`clientList[${i}].name`, this.clientContactList[i].name)
                formData.append(`clientList[${i}].contact`, this.clientContactList[i].phone)
            }

            formData.append('additionType', this.additionType)

            this.$post(this.$PAYMENT_SMS_SEND, 'smscontactadd_smssend', formData, true, (result) => {
                let failList = []
                if (this.sendType === '0') {
                    if (result.data.smsResultList !== null) {
                        let smsResultList = result.data.smsResultList
                        for (let i = 0; i < smsResultList.length; i++) {
                            // code => 알림톡 발송 성공/실패(AS, AF), 문자 발송 성공/실패(SS, SF), 문자 발송 중 내부 처리중(EW)
                            // altCode => 알림톡 발송 코드 (0000: success, 이외 error)
                            // smsCode => sms 발송 코드 ?
                            if (smsResultList[i].altCode !== '0000' && smsResultList[i].code !== 'SS') {  // 카톡, sms 둘다 실패시
                                failList.push(result.data.clientContactList[i])
                            }
                        }
                    }

                    if (result.data.total === 1) {
                        this.customer = result.data.clientContactList[0].contact + ' ' + result.data.clientContactList[0].name
                    } else {
                        this.customer = result.data.clientContactList[0].contact + ' ' + result.data.clientContactList[0].name + ' 외 ' + (result.data.total - 1) + '명'
                    }
                }
                let reservationTime = result.data.sendTime // 2021 10 05 15 25
                let year = reservationTime.substr(0, 4)
                let month = reservationTime.substr(4, 2)
                let day = reservationTime.substr(6, 2)
                let hour = reservationTime.substr(8, 2)
                let minute = reservationTime.substr(10, 2)
                reservationTime = year + '.' + month + '.' + day + ' ' + hour + ':' + minute
                this.$router.push({
                    name: 'SMSPaymentSuccess', params: {
                        isReservation: this.sendType !== '0',
                        count: result.data.total,
                        goodsName: result.data.goodsName,
                        amount: result.data.amount,
                        customer: this.customer,
                        failList: failList,
                        date: this.sendType !== '0' ? reservationTime : ''
                    }
                })

            }, (result) => {
                this.httpError(result.data)
                this.$router.push({
                    name: 'SMSPaymentFail', params: {
                        goodsName: this.goodsName,
                        amount: this.amount,
                        taxType: this.taxType,
                        sendType: this.sendType,
                        sendTime: this.sendTime,
                        memo: this.memo,
                        additionType: this.additionType,
                        clientContactList: this.clientContactList
                    }
                })
            })
        },

        changeNextBtn() {
            if (this.clientContactList.length <= 0) {
                window.document.getElementById("sendBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
            } else {
                window.document.getElementById("sendBtn").className = "align-bottom btn btn-m  btn-pink"
            }
        },
    }
}
</script>

<style scoped>


.scroll-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.scroll-hidden::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}
</style>
