<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>페이스터 회원가입</p>
    </header>
    <div class="content-wrap">
      <div class="sign-up-wrap">
        <div class="sign-box sign-tit">
          <strong class="mb-20 fz-15">휴대전화 인증</strong>
          <button class="btn-l btn-pink btn-round-5 mb-10" @click="niceAuth" :disabled="isNiceAuthed">본인인증</button>
          <span class="color-pink" v-show="isNiceAuthed">본인인증이 완료되었습니다.</span>
        </div>
        <div class="sign-box">
          <div class="checks-box">
            <div class="checks-circle">
              <input type="checkbox" id="totalAgree" v-model="agree" @click="agree = !agree">
              <label for="totalAgree">약관 전체 동의</label>
            </div>
          </div>
          <div class="regist-box">
            <div class="checks" @click="goTerm(term.idx)" v-for="(term, index) in termList" :key="index">
              <input type="checkbox" v-bind:id="'chk' + (index + 1)" disabled v-model="agree">
              <label :for="'chk' + (index + 1)">(필수) {{ term.name }}</label>
              <button type="button" class="btn"><i class="icon-arrow-right"></i></button>
            </div>
          </div>
        </div>

        <button id="nextBtn" class="btn btn-m btn-dgray-4D4D4D" @click="goNext()">다 음</button>
      </div>
    </div>
    <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
      <input type="hidden" name="m" value="checkplusService">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>
  </div>
</template>

<script>
export default {
  name: "SsoTermAgree",
  data() {
    return {
      agree: false,
      isNiceAuthed: false,
      nice: '',
      termList: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'BeforeLogin' || from.name === 'Login') {
        vm.clearData()
      }
    })
  },
  mounted() {
    this.getTermList()
  },
  methods: {
    clearData(){
      this.agree = false
      this.isNiceAuthed = false
      this.nice = ''
    },
    getTermList() {
      this.$get(this.$TERMS, 'termagree_terms', (result) => {
        this.termList = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goTerm(_index){
      this.$router.push({name:'Term', params: { idx : _index }})
    },
    goNext() {
      if (!this.agree) {
        this.notify('error', '약관에 동의해주세요.')
        return
      }

      if (!this.isNiceAuthed) {
        this.notify('error', '본인인증을 해주세요.')
        return
      }

      // 등록된 연락처 체크
      let formData = new FormData()
      formData.append('auth', this.nice)

      this.$post(this.$AUTH_DUPLICATECHECK, 'termAgree_duplicate_check', formData, true, (result) => {  // 중복체크
        let newId = result.data.newId
        if (result.data.duplicate) { // 중복계정 있음
          this.$root.$refs.Confirm.showDialog('등록된 연락처입니다.',
              '입력하신 연락처는 대표 id입니다.<br/>서브id생성을 원할 경우 확인을<br/>' +
              '터치하세요.',
              '확인', '취소', () => { // 확인
                this.$router.replace({name: 'SsoSignUp01', params: {id: newId, auth: this.nice, isClear: true}})

              }, () => {
              })

        } else {
          this.$router.replace({name: 'SsoSignUp01', params: {id: newId, auth: this.nice, isClear: true}})
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },
    niceAuth() {
      // 나이스 인증
      this.$niceAuth((result) => {
        this.niceShow(result.enc_data)

      }, (result) => {
        console.log(result)
      })
    },
    niceShow(enc_data) {
      window.removeEventListener('message', this.niceDone)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      window.$('#nice-enc').val(enc_data)
      window.$('#nice-form').attr('target', 'popupChk')
      window.$('#nice-form').submit()

      window.addEventListener('message', this.niceDone);
    },
      niceDone(e) {
          if (e.origin === 'https://nice.service.payster.co.kr') {
              this.nice = e.data.payload

              window.removeEventListener('message', this.niceDone)

              this.niceCheck()
          }
      },
    niceCheck() {
      this.isNiceAuthed = true

      if (this.agree && this.isNiceAuthed) { // 인증, 동의 완료 시 버튼색상변경
        window.document.getElementById("nextBtn").className = "btn btn-m btn-pink"
      }
    }
  },
  watch: {
    agree() {
      if (this.agree && this.isNiceAuthed) { // 인증, 동의 완료 시 버튼색상변경
        window.document.getElementById("nextBtn").className = "btn btn-m btn-pink"
      } else {
        window.document.getElementById("nextBtn").className = "btn btn-m btn-dgray-4D4D4D"
      }
    },
  }
}
</script>

<style scoped>

</style>
