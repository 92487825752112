<template>
  <div class="all-content">
    <div class="content-wrap-result bg-white content-row">
      <div class="confirm-list">
        <p>
          <img src="/image/wb_logo.png" alt="캐릭터">
          <span>신청이 완료되었습니다.</span>
          <em>심사기간은 영업일 기준 3일 이내 완료됩니다.
            <br>심사완료 후 결제 요청 알림이 전달될 예정이며
            <br>결제를 꼭 진행하셔야 납입이 진행됩니다.
          </em>
        </p>
        <ul class="price-list mb-15">
          <li>
            <strong>신청일</strong>
            <em>{{ date }}</em>
          </li>
          <li>
            <strong>금액</strong>
            <em>{{ amount }}</em>
          </li>
          <li class="info">
            <strong>*영업일: 토요일, 일요일 및 공휴일(임시공휴일 포함)을 제외한 사업주체가 영업을 하는 일자</strong>
          </li>
        </ul>
        <button class="btn-l btn-pink mb-50 btn-round-30" style="height: 60px;" @click="$router.back()">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistSuccess",
  data() {
    return {
      date: '', //신청일
      amount: '', // 납입금액
    }
  },
  mounted() {
    this.date = this.$route.params.date
    this.amount = this.$route.params.amount
  }
}
</script>

<style scoped>


</style>
