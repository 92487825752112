<template>
    <div class="all-content bg-white card-regist">
        <header class="header-wrap">
            <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
            <p>신용카드 등록</p>
        </header>
        <div class="content-wrap" style="height: calc(100% - 110px)">
            <div class="main-wrap pb-80">
                <div class="charge-form pt-0">
          <span>
              <button class="btn pay-btn"
                      :class="{'btn-pink' : selectedType === 0, 'btn-gray-white' : selectedType === 1}"
                      @click="setSelectedCardType(0)">개인카드</button>
              <button class="btn pay-btn ml-10"
                      :class="{'btn-pink' : selectedType === 1, 'btn-gray-white' : selectedType === 0}"
                      @click="setSelectedCardType(1)">법인카드</button>
          </span>
                    <div class="mt-30">
                        <p class="mb-10">카드번호<span class="color-red">*</span></p>
                        <div class="select">
                            <RPInputBorder
                                :type="'text'"
                                :placeholder="'카드번호를 입력해주세요'"
                                :model="cardNo"
                                @update="(_model) => {this.cardNo=_model}"
                                :max-length="16"
                                :input-mode="'numeric'"
                            />
                        </div>
                    </div>
                    <span class="color-red">{{ errors.cardNo }}</span>
                    <div class="mt-30 d-flex-row">
                        <div>
                            <div class="w-100 mr-10">
                                <p class="mb-10">유효기간<span class="color-red">*</span></p>
                                <div class="select">
                                    <RPInputBorder
                                        :type="'text'"
                                        :placeholder="'MMYY'"
                                        :model="expireDate"
                                        @update="(_model) => {this.expireDate=_model}"
                                        :max-length="4"
                                        :input-mode="'numeric'"
                                    />
                                </div>
                            </div>
                            <span class="color-red">{{ errors.expireDate }}</span>
                        </div>
                    </div>
                    <div class="mt-30 w-100">
                        <p class="mb-10">비밀번호<span class="color-red">*</span></p>
                        <div class="select">
                            <RPInputBorder
                                :type="'password'"
                                :placeholder="'비밀번호 4자리를 입력해주세요'"
                                :model="password"
                                @update="(_model) => {this.password=_model}"
                                :input-mode="'number'"
                                :max-length="4"
                            />
                        </div>
                    </div>
                    <span class="color-red">{{ errors.password }}</span>
                    <div class="mt-30 w-100 mb-10">
                        <p class="mb-10">본인확인<span class="color-red">*</span></p>
                        <div class="select">
                            <RPInputBorder
                                :type="'text'"
                                :placeholder="selectedType === 0 ? '주민번호 앞 6자리를 입력해주세요' : '사업자등록번호 10자리를 입력해주세요'"
                                :model="info"
                                @update="(_model) => {this.info=_model}"
                                :max-length="selectedType === 0 ? 6 : 10"
                                :input-mode="'numeric'"
                            />
                        </div>
                        <span class="color-red">{{ errors.info }}</span>
                    </div>
                    <p class="mt-5">
                        <span class="color-gray">
                          *개인카드 : 주민번호 앞 6자리
                          <br/>
                          *법인카드 : 사업자번호 10자리
                        </span>
                    </p>
                </div>
            </div>
            <button class="align-bottom btn btn-m btn-pink position-fixed b-0" @click="registCard">등록</button>
        </div>
    </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";

export default {
    name: "RegistCard",
    components: {
        RPInputBorder,
    },
    data() {
        return {
            selectedType: 0,  // 0: 개인, 1: 법인
            cardNo: '',
            expireDate: '',
            info: '',
            password: '',
            errors: {
                cardNo: '',
                expireDate: '',
                password: '',
                info: ''
            }
        }
    },
    methods: {
        setSelectedCardType(_selectedType) {
            this.selectedType = _selectedType
        },
        errorClear() {
            this.errors = {
                cardNo: '',
                expireDate: '',
                password: '',
                info: ''
            }
        },
        registCard() {
            this.errorClear()

            if (this.cardNo.length < 15 || this.cardNo.length > 16) { /*카드번호 15-16자리*/
                this.notify('error', '카드 번호를 정확하게 입력해주세요.', false)
                return
            }
            if (this.expireDate.length !== 4) {
                this.errors.expireDate = '유효기간을 정확하게 입력해주세요.'
                return
            }
            if (this.password.length !== 4) {
                this.errors.password = '비밀번호를 정확하게 입력해주세요.'
                return
            }
            if (this.selectedType === 0 && this.info.length !== 6) {
                this.errors.info = '생년월일을 정확하게 입력해주세요.'
                return
            }
            if (this.selectedType === 1 && this.info.length !== 10) {
                this.errors.info = '사업자번호를 정확하게 입력해주세요.'
                return
            }

            let formData = new FormData()

            formData.append('type', this.selectedType)
            formData.append('cardNumber', this.cardNo)
            formData.append('validity', this.expireDate)
            formData.append('password', this.password)
            formData.append('identificationNumber', this.info)

            this.$post(this.$CARD, 'registcard_gonext', formData, true, (result) => {
                this.$router.replace({name: 'RegistCardSuccess', params: {cardInfo: result.data}})
            }, (result) => {
                this.httpError(result.data)
                this.$router.replace({name: 'RegistCardFail'})
            })
        }
    },
    watch: {}
}
</script>

<style scoped>

</style>
