<template>
  <div class="confirm-wrap" @click.self="$emit('close')">
    <div class="confirm-layer">
      <button type="button" class="btn-close" @click="close()" style="padding-top: 11px; padding-right: 13px; width: 38px; height: 38px; margin-left: calc(100% - 32px)"><i class="icon-close" style="color: black"></i></button>
      <div class="confirm-layer-box">
        <strong class="fz-20"><b>VAN사 정보 확인하기</b></strong>
        <p class="fz-18 mt-15">단말기: 화면 위쪽에 위치한 로고<br>
         POS: 싸인패드에 있는 로고</p>
        <img class="mt-15" src="/image/van_check_info.svg" style="width: 75%; align-self: center">
        <p class="color-darkgray-4F4F4F fz-16 mt-20">로고를 찍어서 첨부해주세요.</p>
      </div>
<!--      <div class="confirm-layer-box">-->
<!--        <div class="d-flex-row">-->
<!--          <div class="w-100" style="text-align: center">-->
<!--            <span class="color-blue">단말기</span>-->
<!--            <img class="mt-10" src="/image/logo_vertical.svg" alt="로고">-->
<!--          </div>-->
<!--          <div class="w-100" style="text-align: center">-->
<!--            <span class="color-blue">POS</span>-->
<!--            <img class="mt-10" src="/image/logo_vertical.svg" alt="로고">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "VanInfoConfirm",
  data() {
    return {
      show: false,
    }
  },
  methods: {
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>