<template>
    <div v-if="cancelInfo" class="all-content">
        <div class="content-wrap bg-white content-row t-0" style="height: calc(100% - 52px);">
            <div class="confirm-list">
                <p>
                    <img class="check_pink_image" src="/image/check_blue.png" alt="체크 핑크">
                    <span class="check_pink_tit">취소 요청 완료</span>
                    <span class="check_pink_body">취소 요청이 완료되었습니다.</span>
                </p>
                <ul class="price-list" v-if="paymentType === '1' && isReservedCancel">  <!-- 카톡결제 -->
                    <li>
                        <strong>상품명</strong>
                        <em>{{ cancelInfo.goodsName }}</em>
                    </li>
                    <li>
                        <strong>결제금액</strong>
                        <em>{{ cancelInfo.amount | numberWithCommas }}원</em>
                    </li>
                    <li>
                        <strong>부과세</strong>
                        <em>{{ cancelInfo.taxType === '0' ? '과세' : '비과세' }}</em>
                    </li>
                    <li>
                        <strong>예약발송일</strong>
                        <em> {{
                                `${cancelInfo.sendTime.substring(0, 4)}.${cancelInfo.sendTime.substring(4, 6)}.${cancelInfo.sendTime.substring(6, 8)}
                    ${cancelInfo.sendTime.substring(8, 10)}:${cancelInfo.sendTime.substring(10, 12)}`
                            }}</em>
                    </li>
                    <li>
                        <strong>메모</strong>
                        <em>{{ cancelInfo.memo }}</em>
                    </li>
                    <li></li>
                </ul>

                <ul class="price-list" v-else-if="paymentType === '3'"> <!-- QR 결제 -->
                    <li>
                        <strong>결제금액</strong>
                        <em>{{ amount | numberWithCommas }}원</em>
                    </li>
                    <li>
                        <strong>결제일시</strong>
                        <em>{{
                                `${cancelInfo.regDate.substring(0, 4)}.${cancelInfo.regDate.substring(4, 6)}.${cancelInfo.regDate.substring(6, 8)}
                    ${cancelInfo.regDate.substring(8, 10)}:${cancelInfo.regDate.substring(10, 12)}:${cancelInfo.regDate.substring(12, 14)}`
                            }}</em>
                    </li>
                    <li class="b-none"></li>
                    <li class="b-none"></li>
                    <li class="b-none"></li>
                </ul>

                <ul class="price-list" v-else>
                    <li>
                        <strong>결제금액</strong>
                        <em>{{ cancelInfo.amount | numberWithCommas }}원</em>
                    </li>
                    <li>
                        <strong>결제일시</strong>
                        <em>{{ cancelInfo.paymentDateTime ? cancelInfo.paymentDateTime.replaceAll('-', '.').replace('T',' ') : '-' }}</em>
                    </li>
                    <li>
                        <strong>승인번호</strong>
<!--                        <em>{{ cancelInfo.approvalCE.payResponseApprovalNo }}</em>-->
                    </li>
                    <li>
                        <strong>할부기간</strong>
<!--                        <em>{{-->
<!--                                cancelInfo.approvalCE.payResponseInstallment === null ? '일시불' : installment(cancelInfo.approvalCE.payResponseInstallment)-->
<!--                            }}</em>-->
                    </li>
                    <li>
                        <strong>거래번호</strong>
<!--                        <em>{{ cancelInfo.approvalCE.payResponseTranSeq }}</em>-->
                    </li>
                    <li></li>
                </ul>
            </div>
        </div>
        <div class="d-flex-row">
            <button class="btn-pink btn btn-m fz-18" @click="cancelConfirm">확인</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "RequestCancelSuccess",
    data() {
        return {
            paymentType: '',
            cancelInfo: '',
            amount: '',
            isReservedCancel: false,
        }
    },
    mounted() {
        this.paymentType = this.$route.params.paymentType // 0: 수기결제, 1: 카톡결제, 2: 리더기, 3: QR
        this.cancelInfo = this.$route.params.cancelInfo
        console.log(this.cancelInfo)

        if (this.$route.params.isReservedCancel) {
            this.isReservedCancel = this.$route.params.isReservedCancel
        }

        if (this.$route.params.amount) {
            this.amount = this.$route.params.amount
        }
    },
    methods: {
        installment(_val) {
            if (_val === '00') {
                return '일시불'
            } else if (_val === '02') {
                return '2개월'
            } else if (_val === '03') {
                return '3개월'
            } else if (_val === '04') {
                return '4개월'
            } else if (_val === '05') {
                return '5개월'
            } else if (_val === '06') {
                return '6개월'
            } else if (_val === '07') {
                return '7개월'
            } else if (_val === '08') {
                return '8개월'
            } else if (_val === '09') {
                return '9개월'
            } else {
                return _val + '개월'
            }
        },
        cancelConfirm() {
            this.$router.back()
        }
    }
}
</script>

<style scoped>

</style>
