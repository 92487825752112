<template>
  <div id="app">
    <div style="height: 100vh;">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <div id="alert-wrap" class="alert-wrap"></div>
    <confirm ref="Confirm"></confirm>
    <alert ref="Alert"></alert>
    <permission-request ref="permission"></permission-request>
    <p-n-loading ref="PNLoading"></p-n-loading>
  </div>
</template>

<script>
import Confirm from "@/components/common/Confirm";
import PermissionRequest from "@/components/intro/PermissionRequest";
import Alert from "@/components/common/Alert";
import PNLoading from "@/components/common/PNLoading";

export default {
  name: 'App',
  data(){
    return {
      readyMain: false,
      pushPageInfo: '',
    }
  },
  components: {
    PNLoading,
    Confirm,
    PermissionRequest,
    Alert,
  },
  mounted() {
    this.$root.$refs.Confirm = this.$refs.Confirm
    this.$root.$refs.Alert = this.$refs.Alert
    this.$root.$refs.PNLoading = this.$refs.PNLoading

    let token = sessionStorage.getItem('accessToken')
    if(token) {
      this.refreshToken()
    }

    window.addEventListener(this.$EVENT_REQUEST_PERMISSION, () => {
      this.$refs.permission.showDialog()
    })

    window.addEventListener(this.$EVENT_READY_MAIN, this.checkReadyMain)
    window.addEventListener(this.$EVENT_LOAD_FCM_LINK, this.loadFCMLink)
  },
  methods: {
    checkReadyMain() {
      window.setReadyMain(this.readyMain)
    },
    loadPageAfterReady(){
      if (this.readyMain){
        window.setReadyMain(this.readyMain)
      }
    },
    loadFCMLink(e){
      let pushPageInfo = e.detail
      let link = pushPageInfo.split(',')[0]
      let linkIdx = pushPageInfo.split(',')[1]
      // console.log('link' + link + 'linkIdx' + linkIdx)

      let pageName = ''
      if (link === '0'){  // 배달비(상세)
        pageName = 'ChargePaymentHistoryDetail'
      } else if (link === '1'){  // 월세(상세)
        pageName = 'ChargeSalesHistoryDetail'
      } else if (link === '2'){  // 결제(상세)
        pageName = 'PaymentHistoryDetail'
      } else if (link === '3'){  // 공지사항
        pageName = 'NoticeList'
      } else if (link === '4'){ // QnA
        pageName = 'QnaDetail'
      } else {
        return
      }

      if (this.$route.name === "Main"){ // 현재 메인이면
        if (link === '0') { //배달비 상세
          this.$router.push({name: pageName, params: {quickIdx: linkIdx}})
        } else if (link === '1') {  //월세 상세
          this.$router.push({name: pageName, params: {monthlyPaymentIdx: linkIdx}})
        } else if (link === '2') {  //결제 상세
          this.$router.push({name: pageName, params: {idx: linkIdx}})
        } else if (link === '3') {  // 공지사항
          this.$router.push({name: pageName})
        } else if (link === '4') { // QNA
          this.$router.push({name: pageName, params: {idx: linkIdx}})
        }

      } else {  // 메인 아닐 경우
        if (linkIdx !== ""){
          this.$router.push({name: "Main", params: { loadPage: pageName, linkIdx: linkIdx }})
        } else {
          this.$router.push({name: "Main", params: { loadPage: pageName }})
        }
      }
    },
  },
  beforeCreate() {
    let console=(function(oldCons){
      return {
        log: function(){
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.log.apply(console, arguments)
          }
        },
        warn: function() {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.warn.apply(console, arguments)
          }
        },
        error: function() {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.error.apply(console, arguments)
          }
        },
      };
    }(window.console));
    window.console = console
  },
  watch: {
    readyMain(){
      this.loadPageAfterReady()
    },
  }
}
</script>

<style>
/* alert */
.alert-wrap {
  position: absolute;
  z-index: 500;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.alert-content {
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
}

.alert-content + .alert-content {
  margin-top: 10px;
}

.alert-content i {
  position: relative;
  top: 1px;
  margin-right: 5px;
  color: #fff;
}

.alert-content span {
  color: #fff;
  line-height: 20px;
}

.input-disabled {
  opacity: 1;
  -webkit-text-fill-color: #808080;
}

.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.pink-title {
  font-size: 28px !important;
  line-height: 36px !important;
  color:#2358a0 !important;
  font-weight: 500 !important;
  text-align: center !important;
}
.weight-light {
  font-weight: 300 !important;
}
.weight-normal {
  font-weight: 400 !important;
}
.weight-500 {
  font-weight: 500 !important;
}
.weight-bold {
  font-weight: 700 !important;
}
.d-flex-row {
  display: flex;
  flex-direction: row;
}
.jc-between {
  justify-content: space-between;
}
.jc-center {
  justify-content: center;
}
.jc-f-end {
  justify-content: flex-end;
}
.jc-end {
  justify-content: end;
}
.of-auto {
  overflow: auto;
}
.of-x-auto {
  overflow-x: auto;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-28 {
  font-size: 28px !important;
}
.fz-40 {
  font-size: 40px !important;
}
.check-pink {
  width: 60px !important;
  height: 60px !important;
}
.btn-under {
  text-decoration: underline;
}
.cancel-text {
  text-decoration: line-through;
}
/*.color-white {*/
/*  color: #fff !important;*/
/*}*/
.color-black {
  color: #000 !important;
}
.reset-pin-btn {
  position: absolute;
  bottom: 320px;
  font-size: 16px;
  font-weight: 300;
}
.w-auto {
  width: auto !important;
}
.w-47per {
  width: 47% !important;
}
.w-50per {
  width: 50% !important;
}
.h-auto {
  height: auto !important;
}
.bank-item {
  width: 25% !important;
  float: left;
  text-align: center;
}
.round-rb {
  border-radius: 0 0 4px 0 !important;
}
.round-lb {
  border-radius: 0 0 0 4px!important;
}
.profile {
  width: 66px !important;
  height: 66px !important;
  object-fit: cover;
  border-radius: 33px;
}
.big-profile {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover;
  border-radius: 40px;
}
.overflow-unset {
  overflow: unset !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-x-clip {
  overflow-x: clip !important;
}
.obj-fit-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bg-clear {
  background: #ffffff00 !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}
.h-100per-80 {
  height: calc(100% - 80px) !important;
}
.h-100 {
  height: 100% !important;
}
.h-100px {
  height: 100px !important;
}
.h-230px {
  height: 230px !important;
}
.vue-barcode-element {
  width: 100%;
}
.border-bottom-pink {
  border-bottom: 1px solid #2358a0;
}
.border-bottom-dgray {
  border-bottom: 1px solid #808080;
}
.history-charge-btn {
  position: absolute;
  right: 20px;
  top: calc(50% - 20px);
  height: 40px;
  width: 60px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: white;
  font-size: 16px;
}
.border-zero {
  border: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.border-1 {
  border: 1px solid #DDDDDD;
}
/*.border-r-5 {*/
/*  border-radius: 5px;*/
/*}*/
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 1px !important;
}
.p-10 {
  padding: 10px !important;
}
.px-20 {
  padding: 0 20px !important;
}
.py-20 {
  padding: 20px 0 !important;
}
.py-30 {
  padding: 30px 0 !important;
}
.date-box {
  width: auto;
  text-align: center;
  background-color: transparent;
}
.profile-upload-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}
.r-0 {
  right: 0 !important;
}
.r-20 {
  right: 20px !important;
}
.max-w-100 {
  max-width: 100%;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.display-block {
  display: block;
}
.dropdown-menu{position:absolute;top:100%;left:0;z-index:1000;display:none;float:left;min-width:160px;padding:5px 0;margin:2px 0 0;font-size:14px;text-align:left;list-style:none;background-color:#fff;-webkit-background-clip:padding-box;background-clip:padding-box;border:1px solid #ccc;border:1px solid rgba(0,0,0,.15);border-radius:4px;-webkit-box-shadow:0 6px 12px rgba(0,0,0,.175);box-shadow:0 6px 12px rgba(0,0,0,.175)}.dropdown-menu.pull-right{right:0;left:auto}.dropdown-menu .divider{height:1px;margin:9px 0;overflow:hidden;background-color:#e5e5e5}.dropdown-menu>li>a{display:block;padding:3px 20px;clear:both;font-weight:400;line-height:1.42857143;color:#333;white-space:nowrap}.dropdown-menu>li>a:focus,.dropdown-menu>li>a:hover{color:#262626;text-decoration:none;background-color:#f5f5f5}.dropdown-menu>.active>a,.dropdown-menu>.active>a:focus,.dropdown-menu>.active>a:hover{color:#fff;text-decoration:none;background-color:#337ab7;outline:0}.dropdown-menu>.disabled>a,.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover{color:#777}.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover{text-decoration:none;cursor:not-allowed;background-color:transparent;background-image:none;filter:progid:DXImageTransform.Microsoft.gradient(enabled=false)}.open>.dropdown-menu{display:block}.open>a{outline:0}.dropdown-menu-right{right:0;left:auto}.dropdown-menu-left{right:auto;left:0}.dropdown-header{display:block;padding:3px 20px;font-size:12px;line-height:1.42857143;color:#777;white-space:nowrap}.dropdown-backdrop{position:fixed;top:0;right:0;bottom:0;left:0;z-index:990}.pull-right>.dropdown-menu{right:0;left:auto}.dropup .caret,.navbar-fixed-bottom .dropdown .caret{content:"";border-top:0;border-bottom:4px solid}.dropup .dropdown-menu,.navbar-fixed-bottom .dropdown .dropdown-menu{top:auto;bottom:100%;margin-bottom:2px}@media (min-width:768px){.navbar-right .dropdown-menu{right:0;left:auto}.navbar-right .dropdown-menu-left{right:auto;left:0}}
.event-area {
  width: 100%;
  height: 128px;
  border-radius: 8px;
  overflow:hidden;
}

.sub-header {
  height: 50px;
  padding-top: 12px;
  position: fixed;
  top: 60px;
}

.qna-wrap {
  height: calc(100% - 102px);
  overflow: scroll;
}
.qna-state-0 {
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid #808080;
  text-align: center;
  line-height: 18px;
  color: #808080;
  background-color: #ffffff;
}
.qna-state-1 {
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid orange;
  text-align: center;
  line-height: 18px;
  color: #ffffff;
  background-color: orange;
}
.qna-state-2 {
  height: 20px;
  width: 60px;
  border-radius: 10px;
  border: 1px solid #2358a0;
  text-align: center;
  line-height: 18px;
  color: #ffffff;
  background-color: #2358a0;
}
.pay-tab div { line-height: 30px; padding:0 15px; font-size: 19px; color: #808080;}
.pay-tab div.active { position: relative; font-weight: bold; color: #2358a0; }
.pay-tab div.active::after { content: ''; position: absolute; left: 0; bottom: -1px; width: 100%; height: 4px; background: #2358a0; }

.card-line {
  line-height: 30px;
  margin: 0 10px;

}
.payment-card-thumb {
  max-height: 30px;
}
.qna-title {
  width: calc(100% - 80px);
  line-break: anywhere;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bg-red {
  background: rgba(255, 18, 18, 0.7) !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
@keyframes spinner-border{to{transform:rotate(360deg)}}.spinner-border{display:inline-block;width:2rem;height:2rem;vertical-align:text-bottom;border:.25em solid currentColor;border-right-color:transparent;border-radius:50%;animation:spinner-border .75s linear infinite}
.leave-form {
  overflow: auto;
  max-height: calc(100vh - 190px);
}
.faq-content, .faq-content p, .faq-content:first-child {
  padding: 0 !important;
  line-height: normal !important;
  overflow: auto !important;
  text-overflow: unset !important;
  /*white-space: normal !important;*/
  white-space: pre-wrap !important;
}
.faq-content img {
  max-width: 100% !important;
}
.noscroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noscroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.bootstrap-datetimepicker-widget {
  list-style: none;
  box-shadow: none;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #dee2e6;
  color: #007bff;
  text-shadow: none;
}

.bootstrap-datetimepicker-widget table td span.active,
.bootstrap-datetimepicker-widget table td i.active {
  background-color: #dee2e6;
  color: #007bff;
  text-shadow: none;
}

</style>
