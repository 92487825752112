<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list">
        <p>
          <img class="check-pink mt-50" src="/image/check_blue.png" alt="캐릭터">
          <em class="t-c pink-title weight-light">핀번호가 정상적으로<br/>변경되었습니다.</em>
          <!--          <strong class="mt-20 fz-20 weight-normal t-c">{{ $route.params.number | cardNumberMasking }}</strong>-->
          <!--          <strong v-show="$route.params.alias !== ''" class="mt-20 fz-16 weight-normal t-c color-darkgray">신용카드 별명 : {{ $route.params.alias }}</strong>-->
        </p>
        <p>
          <img src="/image/wb_logo.png" alt="캐릭터">
        </p>
<!--        <em class="mb-30 t-c">{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</em>-->
      </div>
    </div>
    <button class="btn-pink mr-auto btn-m" @click="goMain">확 인</button>
  </div>
</template>

<script>
export default {
  name: "ChangePinSuccess",
  methods: {
    goMain() {
      localStorage.setItem('firstPinChecked', '0')
      this.$router.push({ name: 'Main' })
    }
  }
}
</script>

<style scoped>

</style>
