<template>
  <div class="all-content bg-gray">
    <header class="header-wrap border-bottom">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>배달/월세 결제내역</p>
    </header>
    <div class="header-wrap quick-history-tab bg-white t-c d-flex-row w-100 sub-header border-bottom">
      <div class="pb-10 w-50per" @click="$router.replace({name:'QuickPaymentHistory'})">배달비</div>
      <div class="pb-10 w-50per active">월세</div>
    </div>
    <div class="rent-payment-tab">
      <div class="pt-15 d-flex-row pl-20">
        <div class="mr-auto p-date-picker" v-if="selectedTabIdx === 1" style="width: 80px;"> <!--납입완료 일 경우 년도 선택 가능하게 -->
          <date-picker class="fz-16 date-picker t-r"
                       v-model="selectedYear"
                       :config="rentHistoryCalendarOptions"
                       @input="getRentPaymentList"
                       readonly
                       style="width: 50px; background: transparent;"
          />
          <button type="button" class="btn-back pl-10"><i class="icon-arrow-down"></i></button>
        </div>

        <div class="ml-auto">
          <button class="mr-10" :class="[selectedTabIdx === 0 ? 'tab-payment-active' : 'tab-payment']" @click="selectedTab(0)">납입
          </button>
          <button class="ml-10 mr-10" :class="[selectedTabIdx === 1 ? 'tab-payment-active' : 'tab-payment']" @click="selectedTab(1)">납입완료</button>
          <button class="ml-10" :class="[selectedTabIdx === 3 ? 'tab-payment-active' : 'tab-payment']" @click="selectedTab(3)">결제대기
          </button>
          <button class="ml-10" :class="[selectedTabIdx === 2 ? 'tab-payment-active' : 'tab-payment']" @click="selectedTab(2)">심사
          </button>
        </div>
      </div>
    </div>

    <div class="content-wrap-rent">
      <div v-if="items.length > 0">
        <section v-for="(item, idx) in items" :key="idx" class="sales-history-all-section">
            <div class="white-box-br-15">
              <div class="d-flex-row jc-between" :class="{ 'border-bottom' : item.count > 0 }">
                <span v-if="selectedTabIdx === 0" class="fz-18 color-gray-4F4F4F mb-20">이번달 {{ item.type | chargeTypeFilter }}<em v-if="item.count > 1"> ({{ item.count }})</em></span>
                <span v-else class="fz-18 color-gray-4F4F4F mb-20" :class="{ 'color-gray' : item.state === '0' }">{{ item.type | chargeTypeFilter }}<em v-if="item.count > 1"> ({{ item.count }})</em></span>
                <span class="payment-type" :class="[{ 'payment-ing' : item.state === '1' }, { 'payment-fail' : item.state === '3' }, { 'payment-completed' : item.state === '5' }, { 'payment-completed' : item.state === '4' },
                { 'payment-reviewing' : item.state === '0' }, { 'payment-review-return' : item.state === '2' }, { 'payment-ready' : item.state === '6' }]">{{ item.state | stateFilter }}</span>
              </div>
              <div v-for="(details, index) in item.items" :key="index" class="mt-20" @click="goNext(item, details)">
                <div class="d-flex-row jc-between">
                  <div class="d-flex">
                    <span class="fz-17 color-gray-4F4F4F" :class="{ 'color-gray' : item.state === '0' }">{{ details.monthlyName }}</span>
                    <span v-if="details.paymentType === 'R'" class="color-blue-5064AF fz-14 mt-10">정기 <em
                        class="color-gray">{{ details.paymentDate }}</em></span>
                    <span v-else class="fz-14 mt-10" style="color: #2358a0">일반 <em
                        v-if="selectedTabIdx !== 1" class="color-gray">결제 예정</em></span>
                  </div>
                  <div class="d-flex">
                      <span class="fee-tit color-gray-4F4F4F" :class="{ 'color-gray' : item.state === '0' }">
                        {{  details.monthlyAmount | numberWithCommas }}<em>원</em>
                      </span>
                    <span class="fee-body mt-10"><em
                        class="color-gray">{{ details.paymentDuration }}</em></span>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </div>

      <div v-else>
        <div class="list-none-wrap noCard">
          <div class="list-none">
            <p class="list-none-title fz-20 color-darkgray-4F4F4F">등록된 내역이 없습니다.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "RentPaymentHistory",
  data(){
    return {
      selectedTabIdx: 0,
      selectedYear: '',
      // type : '', // 0:월세, 1:관리비, 2:보증금, 3:수리비, 5:기타, '':전체
      items : [],
      subItems: [],

      rentHistoryCalendarOptions : {
        format: 'YYYY' + '년',
        locale: moment.locale('ko'),
        icons: {
          time: 'glyphicon glyphicon-time',
          date: 'glyphicon glyphicon-calendar',
          up: 'icon-arrow-up',
          down: 'icon-arrow-down',
          previous: 'icon-arrow-left',
          next: 'icon-arrow-right',
          today: 'glyphicon glyphicon-screenshot',
          clear: 'glyphicon glyphicon-trash',
          close: 'glyphicon glyphicon-remove'
        },
        ignoreReadonly: true,
      },

      showDatePicker: false,
    }
  },
  mounted() {
    this.initData()
  },
  beforeRouteEnter(to, from, next){
    next( vm => {
      if (from.name === 'QuickPaymentHistory'){
        vm.initData()
      }
    })
  },
  methods: {
    initData(){
      this.selectedYear = new Date().getFullYear() + '년'
        let state = this.$route.params.state
      console.log(state)
        if(state){
            this.selectedTab(state)
        } else {
          this.selectedTab(0)
        }
    },
    selectedTab(_tab){
      this.selectedTabIdx = _tab

      this.getRentPaymentList()
    },
    getRentPaymentList() {
      this.items = []
      let url = this.$LOG_MONTHLY + '/' + this.selectedTabIdx
      if (this.selectedTabIdx === '1' || this.selectedTabIdx === 1){ // 납입완료 일 경우 년도 선택 추가
        url = url + '?year=' + this.selectedYear.substring(0, 4)
      }

      this.$get(url , this.$options.name + '_getRentPaymentList', (result) => {
        this.items = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goNext(item, details) {
      console.log(item, details)
      if (item.state == 6) {
        this.$router.push({
          name: 'RentPaymentInfoInput', params: {
            type: 0,
            idx: details.idx,
            amount: details.monthlyAmount,
          }
        })
      } else {
        this.$router.push({name: 'RentBeforePaymentDetail', params: {monthlyPaymentIdx: details.idx}})
      }
    }
  },
  filters: {
    chargeTypeFilter : (value) => {
      if (value === '0') {
        return '월세'
      } else if (value === '1') {
        return '관리비'
      } else if (value === '2') {
        return '보증금'
      } else if (value === '3') {
        return '수리비'
      } else if (value === '5') {
        return '기타'
      }
    },
    stateFilter: (value) => {
      if (value === '0') {
        return '심사중'
      } else if (value === '1') {
        return '납입중'
      } else if (value === '2') {
        return '심사반려'
      } else if (value === '3') {
        return '납입실패'
      } else if (value === '4') {
        return '납입중단'
      } else if (value === '5') {
        return '납입완료'
      } else if (value === '6') {
        return '결제대기'
      }
    },
  },
}
</script>

<style scoped>

</style>