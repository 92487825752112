<template>
  <div class="all-content">
    <div class="intro-wrap bg-white content-row">
      <div class="confirm-list">
        <p>
          <img class="check-pink mb-20" src="/image/bang_pink.svg" alt="캐릭터">
          <em class="pink-title weight-light">등록된 아이디가 없습니다.</em>
          <small class="color-darkgray">확인 후 다시 시도하세요.</small>
        </p>
        <p class="pb-140">
          <img src="/image/wb_logo.png" alt="캐릭터">
        </p>
      </div>
    </div>
    <button class="align-bottom btn btn-m btn-pink" @click="$router.back()">확인</button>
  </div>
</template>

<script>
export default {
  name: "InactivityFail"
}
</script>

<style scoped>

</style>