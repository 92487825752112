<template>
  <div class="all-content bg-white myinfo">
    <div class="content-wrap">
      <Menu ref="myInfoMenu"/>
      <div class="content-main">
        <section class="section bg-white">
          <div class="list-box border-bottom">
            <span class="fz-16 color-darkgray-4F4F4F">계정</span>
            <span class="fz-16 color-darkgray-4F4F4F">{{ profile.account }}</span>
          </div>
          <div class="list-box border-bottom">
            <span class="fz-16 color-darkgray-4F4F4F">상호명</span>
            <span class="fz-16 color-darkgray-4F4F4F">{{ profile.businessName }}</span>
          </div>
          <div class="list-box border-bottom">
            <span class="fz-16 color-darkgray-4F4F4F">대표자명</span>
            <span class="fz-16 color-darkgray-4F4F4F">{{ profile.representativeName }}</span>
          </div>
          <div class="list-box border-bottom">
            <span class="fz-16 color-darkgray-4F4F4F">사업자등록번호</span>
            <span class="fz-16 color-darkgray-4F4F4F">{{ profile.businessNumber | businessNumberMasking }}</span>
          </div>
          <div class="list-box">
            <span class="fz-16 color-darkgray-4F4F4F">사업장주소</span>
            <span class="fz-16 color-darkgray-4F4F4F">{{ profile.address }}</span>
          </div>
        </section>
        <footer class="list-box p-20 d-flex t-c">
            <p>1877-5916</p>
            <p>Copyright ©2021 WHOLEBIG Co., Ltd.</p>
            <p>All rights reserved.</p>
        </footer>
      </div>

    </div>
  </div>
</template>

<script>
import Menu from '@/components/myinfo/MenuCmp'

export default {
  name: "MyInfo",

  components: {
    Menu: Menu,
  },

  data() {
    return {
      profile: {
        account: '',
        address: '',
        businessName: '',
        businessNumber: '',
        representativeName: '',
      }
    }
  },

  created() {
    this.$get(this.$USER_DETAIL, 'mypage_mounted',(result) => {
      this.profile = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  },

  mounted() {
    this.$refs.myInfoMenu.focusTab(0)
  },
}
</script>

<style scoped>

</style>
