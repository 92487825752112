<template>
  <div class="all-content">
    <div class="content-wrap-result bg-white content-row">
      <div class="confirm-list">
        <p>
          <img src="/image/wb_logo.png" alt="캐릭터">
          <span>결제가 완료되었습니다.</span>
          <em>선택하신 날짜에 자동납부 됩니다.</em>
        </p>
        <ul class="price-list mb-15">
          <li>
            <strong>결제일</strong>
            <em>{{ date }}</em>
          </li>
          <li>
            <strong>납입일/금액</strong>
            <em>{{ p_date }} / {{ amount }}</em>
          </li>
          <li class="info">
<!--            <strong>*영업일: 토요일, 일요일 및 공휴일(임시공휴일 포함)<br>을 제외한 사업주체가 영업을 하는 일자</strong>-->
          </li>
        </ul>
        <button class="btn-l btn-pink mb-50 btn-round-30" style="height: 60px;" @click="$router.back()">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RentPaymentSuccess",
  data() {
    return {
      date: '', //신청일
      p_date: '',  // 납입일
      amount: '', // 납입금액
    }
  },
  mounted() {
    this.p_date = this.$route.params.p_date
    this.date = this.$route.params.date
    this.amount = this.$route.params.amount
  }
}
</script>

<style scoped>


</style>
