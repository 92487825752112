<template>
  <div class="all-content">
    <header class="header-wrap">
      <button @click="$router.back()" class="btn-back"><i class="icon-back"></i></button>
      <p>FAQ</p>
    </header>
    <div class="content-wrap bg-white">
      <div class="alram-list p-0">
        <div class="p-20">
          <div class="border-1 br-5 position-relative" style="height: 50px">
            <RPInputBorder
                :type="'text'"
                :placeholder="'검색어를 입력하세요.'"
                :model="searchKeyword"
                @update="(_model) => {this.searchKeyword=_model}"
            />
          </div>
        </div>
        <ul v-if="searchKeyword === ''">
          <li v-for="(category, index) in faqCategory" :key="'c' + index" class="mb-0 border-bottom">
            <a @click="goFaqSubList(category)" class="display-block position-relative p-20">
              <p class="p-0 mt-5">
                {{ category.name }}
              </p>
              <i class="w-auto h-auto r-20 icon-arrow-right"></i>
            </a>
          </li>
        </ul>
        <ul v-else-if="items.length > 0">
          <li v-for="(item, index) in items" :key="'i' + index" class="mb-0">
            <a @click="goDetail(item.idx)" class="display-block position-relative p-20">
              <p class="p-0 mt-5 faq-content mr-20">
                {{ item.title }}
              </p>
              <i class="w-auto h-auto r-20" :class="{'icon-arrow-down':item.idx !== selectedIdx, 'icon-arrow-up':item.idx === selectedIdx}"></i>
            </a>
            <div class="bg-gray p-1">
              <div v-show="item.idx === selectedIdx" class="p-20">
                <div class="p-0">
                  <div class="faq-content" v-html="item.content"></div>
                </div>
                <div v-for="(img, imgIndex) in item.imageList" :key="'i' + imgIndex">
                  <img class="max-w-100" :src="img">
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="mt-50" v-else-if="searchKeyword.trim() === ''">
          <div class="list-none-wrap p-20">
            <div class="list-none">
              <p><img src="/image/wb_logo.png" alt="캐릭터" style="opacity: 0.5"></p>
              <p class="list-none-title">
                검색어를 입력하세요.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-50" v-else>
          <div class="list-none-wrap p-20">
            <div class="list-none">
              <p><img src="/image/wb_logo.png" alt="캐릭터" style="opacity: 0.5"></p>
              <p class="list-none-title">
                검색결과가 없습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import showdown from "showdown"

export default {
  name: "FaqList",

  components: {
    RPInputBorder
  },

  data() {
    return {
      searchKeyword: '',
      items: [],
      faqCategory: [],
      selectedIdx: '',
    }
  },

  created() {
    this.$get(this.$FAQ_CATEGORY, 'faqlist_mounted',(result) => {
      this.faqCategory = result.data
    }, (result) => {
      this.httpError(result.data)
    })
  },

  methods: {
    getFaq() {
      if (this.searchKeyword.trim() === '') {
        return
      }
      this.selectedIdx = ''
      this.$get(this.$FAQ + '?keyword=' + this.searchKeyword, 'faqlist_getfaq_keyword',(result) => {
        this.items = result.data

        for (let i = 0; i < this.items.length ; i ++){
          let content = this.items[i].content
          let converter = new showdown.Converter()
          this.items[i].content = converter.makeHtml(content).replaceAll('\\', '')
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },

    goFaqSubList(_category) {
      this.$router.push({name:'FaqSubList', params:{idx:_category.idx, title:_category.name}})
    },

    goDetail(_no) {
      if (this.selectedIdx === _no) {
        this.selectedIdx = ''
      } else {
        this.selectedIdx = _no
      }
    }
  },
  watch: {
    searchKeyword() {
      this.getFaq()
    }
  }
}
</script>

<style scoped>

</style>
