<template>
  <div class="all-content bg-white card-regist">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>배대사 등록</p>
    </header>
    <div class="content-wrap" style="height: calc(100% - 110px)">
      <div class="main-wrap pb-80">
        <div class="charge-form pt-0">
          <div>
            <p class="mb-10">배대사 이름<span class="color-red">*</span></p>
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'배대사 이름을 입력해주세요'"
                  :model="accountTag"
                  @update="(_model) => {this.accountTag=_model}"
              />
            </div>
          </div>
          <div class="mt-30 d-flex-row">
            <div>
              <div class="w-100 mr-10">
                <p class="mb-10">은행선택<span class="color-red">*</span></p>
                <div class="select-wrap">
                  <button class="select" v-if="selectedBank !== null" @click="openBankList()">
                    {{ selectedBank.name }}
                  </button>
                  <button class="select color-gray-B8B8B8" v-else @click="openBankList()">은행 선택</button>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-30 d-flex-row">
            <div>
              <div class="w-100 mr-10">
                <p class="mb-10">계좌타입<span class="color-red">*</span></p>
                <div class="select-wrap">
                  <button class="select color-gray-B8B8B8" v-if="selectedType === ''" @click="openAccountType()">
                    계좌 타입 선택
                  </button>
                  <button class="select" v-else-if="selectedType === 1" @click="openAccountType()">
                    개인
                  </button>
                  <button class="select" v-else-if="selectedType === 2" @click="openAccountType()">
                    사업자
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-30 w-100">
            <p class="mb-10">계좌번호<span class="color-red">*</span></p>
            <div class="select">
              <RPInputBorder
                  :type="'number'"
                  :placeholder="'계좌번호를 입력해주세요'"
                  :model="accountNum"
                  @update="(_model) => {this.accountNum=_model}"
                  :input-mode="'numeric'"
              />
            </div>
          </div>
          <div class="mt-30 w-100 mb-10">
            <p class="mb-10">예금주명<span class="color-red">*</span></p>
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'예금주명을 입력해주세요'"
                  :model="accountName"
                  @update="(_model) => {this.accountName=_model}"
              />
            </div>
          </div>
          <div class="mt-30 w-100 mb-10">
            <p class="mb-10">필요서류<span class="color-red">*</span></p>
            <div class="update_img">
              <p class="color-darkgray">가상계좌인증</p>
              <div class="img_item">
                <p class="color-darkgray-4F4F4F">{{ reviewFile ? reviewFile.name : reviewFileName ? reviewFileName : '파일 선택' }}</p>
              </div>
              <div class="img_btn">
                <img class="profile-mypage" id="user_img" src="/image/icon_img.svg"
                     alt="사용자 사진"/>
                <div>
                  <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
                </div>
              </div>
            </div>
          </div>
            <div class="checks-circle-black mt-30">
              <input type="checkbox" v-model="checkAccount" id="account_check" />
              <label class="color-darkgray" for="account_check">계좌검증제외</label>
            </div>
        </div>
      </div>
      <button v-if="!idx" class="align-bottom btn btn-m btn-pink position-fixed b-0" @click="registDeliveryAgency">심사신청</button>
      <div v-else>
        <button class="align-bottom btn btn-m btn-gray position-fixed b-0 w-50" @click="deleteAgency">삭제</button>
        <button class="align-bottom btn btn-m btn-pink position-fixed b-0 w-50" style="right: 0" @click="reviewDeliveryAgency">재심사신청</button>
      </div>
    </div>
    <select-account-type
        v-show="showSelectedType"
        @selected="setSelectedType"
        @close="showSelectedType=false"/>
    <bank-select
        v-show="showBankList"
        :bank-list="bankList"
        @selected="setSelectedBank"
        @close="showBankList=false"
    />
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import BankSelect from "@/components/charge/BankSelect.vue";
import SelectAccountType from "@/components/myinfo/deliveryAgency/regist/SelectAccountType.vue";

export default {
  name: "RegistDeliveryAgency",
  components: {
    SelectAccountType,
    BankSelect,
    RPInputBorder,
  },
  data() {
    return {
      bankList: [],
      selectedBank: null,
      accountTag: '',
      accountNum: '',
      accountName: '',
      showBankList: false,
      selectedType: '',
      showSelectedType: false,
      reviewFile: '',
      itemDetail: [],
      idx: this.$route.params.idx,
      reviewFileName: '',
      checkAccount: false
    }
  },
  mounted() {
    this.getBankList()
    if(this.idx) {
      this.getAgencyDetail()
    }
  },
  methods: {
    deleteAgency() {
      this.$root.$refs.Confirm.showDialog('', '배대사 등록 내역을 삭제하시겠습니까?', '확인', '취소', () => {
        this.$delete(this.$ACCOUNT_DELETE + '/' + this.idx, 'ReviewDeliveryAgency', true, () => { // 등록
          this.$router.back()
        }, (result) => {
          this.httpError(result.data)
        })
      }, () => {
      })
    },
    reviewDeliveryAgency() {
      if (this.accountTag === '') {
        this.notify('error', '배대사 이름을 입력해주세요.', false)
        return
      }
      if (this.selectedBank === null) {
        this.notify('error', '은행을 선택해주세요.', false)
        return
      }
      if (this.selectedType === '') {
        this.notify('error', '계좌타입을 선택해주세요.', false)
        return
      }
      if (this.accountNum === '') {
        this.notify('error', '계좌번호를 입력해주세요.', false)
        return
      }
      if (this.accountNum.length <= 10 || this.accountNum.length >= 20) {
        this.notify('error', '올바른 계좌번호를 입력해주세요', false)
        return
      }
      if (this.accountName === '') {
        this.notify('error', '예금주명을 입력해주세요.', false)
        return
      }
      let formData = new FormData()
      formData.append('type', this.selectedType)
      formData.append('bankCode', this.selectedBank.code)
      formData.append('number', this.accountNum)
      this.$post(this.$ACCOUNT_CHECK, 'accountCheck', formData, true, () => { // 등록
        let formData2 = new FormData()
        formData2.append('nickname', this.accountTag)
        formData2.append('bankIdx', this.selectedBank.idx)
        formData2.append('number', this.accountNum)
        formData2.append('name', this.accountName)
        formData2.append('type', this.selectedType)  // 1:개인, 2: 사업자
        if(this.reviewFile){
          formData2.append('reviewFile', this.reviewFile)
        }

        this.$put(this.$ACCOUNT + '/' + this.idx, 'ReviewDeliveryAgency', formData2, true, () => { // 등록
          this.$router.replace({
            name: 'RegisterComplete',
            params: {
              nickname: this.accountTag,
              type: this.selectedType,
              number: this.accountNum,
              name: this.accountName,
              bank: this.selectedBank.name,
              date: new Date()
            }})  // 심사등록 완료로 이등
        }, (result) => {
          this.httpError(result.data)
        })
      }, (result) => {
        this.httpError(result.data)
      })
    },
    getAgencyDetail() {
      this.$get(this.$ACCOUNT + '/' + this.idx, 'chargeregistaccount_getbanklist', (result) => {
        console.log(result.data)
        this.itemDetail = result.data
        this.accountTag = this.itemDetail.nickname
        this.accountNum = this.itemDetail.accountNumber
        this.accountName = this.itemDetail.depositor
        this.selectedType = this.itemDetail.type
        this.reviewFileName = this.itemDetail.reviewFileName
        this.bankList.find((item) => {
          if(item.idx === this.itemDetail.bankIdx) {
            this.selectedBank = item
          }
        })
      }, (result) => {
        this.httpError(result.data)
      })
    },
    upload(e) {
      if(e.target.files[0]){
        this.reviewFile = []
        this.reviewFile = e.target.files[0]
      }
      console.log(this.reviewFile)
    },
    setSelectedType(_type) {
      this.selectedType = _type
      this.showSelectedType = false
    },
    openAccountType() {
      this.showSelectedType = true
    },
    getBankList() {
      this.$get(this.$BANK, 'chargeregistaccount_getbanklist', (result) => {
        this.bankList = result.data
        console.log(this.bankList)
      }, (result) => {
        this.httpError(result.data)
      })
    },
    openBankList() {
      this.showBankList = true
    },
    setSelectedBank(_bank) {
      this.selectedBank = _bank
      this.showBankList = false
    },

    registerAgency() {
      let formData2 = new FormData()
      formData2.append('nickname', this.accountTag)
      formData2.append('bankIdx', this.selectedBank.idx)
      formData2.append('number', this.accountNum)
      formData2.append('name', this.accountName)
      formData2.append('type', this.selectedType)  // 1:개인, 2: 사업자
      formData2.append('reviewFile', this.reviewFile)

      this.$post(this.$ACCOUNT, 'RegistDeliveryAgency', formData2, true, () => { // 등록
        this.$router.replace({
          name: 'RegisterComplete',
          params: {
            nickname: this.accountTag,
            type: this.selectedType,
            number: this.accountNum,
            name: this.accountName,
            bank: this.selectedBank.name,
            date: new Date()
          }})  // 심사등록 완료로 이등
      }, (result) => {
        this.httpError(result.data)
      })
    },

    registDeliveryAgency() {
      if (this.accountTag === '') {
        this.notify('error', '배대사 이름을 입력해주세요.', false)
        return
      }
      if (this.selectedBank === null) {
        this.notify('error', '은행을 선택해주세요.', false)
        return
      }
      if (this.selectedType === '') {
        this.notify('error', '계좌타입을 선택해주세요.', false)
        return
      }
      if (this.accountNum === '') {
        this.notify('error', '계좌번호를 입력해주세요.', false)
        return
      }
      if (this.accountNum.length <= 10 || this.accountNum.length >= 20) {
        this.notify('error', '올바른 계좌번호를 입력해주세요', false)
        return
      }
      if (this.accountName === '') {
        this.notify('error', '예금주명을 입력해주세요.', false)
        return
      }
      if (!this.reviewFile) {
        this.notify('error', '필요서류를 등록해주세요.', false)
        return
      }

      if(!this.checkAccount) {
        let formData = new FormData()
        formData.append('type', this.selectedType)
        formData.append('bankCode', this.selectedBank.code)
        formData.append('number', this.accountNum)
        this.$post(this.$ACCOUNT_CHECK, 'accountCheck', formData, true, () => { // 등록
          this.registerAgency()
        }, (result) => {
          this.httpError(result.data)
        })
      } else {
        this.registerAgency()
      }
    }
  },
  watch: {}
}
</script>

<style scoped>
.update_img {
  display: flex;
  align-items: center;
}
.img_item {
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 5px;
  background: #F3F4F6;
  border-radius: 5px;
  width: 60%;
  height: 50px;
}
.img_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;
  background: #A09E9E;
  border-radius: 5px;
}
.custom-input[type='file'] {
  opacity: 0;
  position: absolute;
  height: 26px;
  width: 26px;
  cursor: pointer;
}
</style>
