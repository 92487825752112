<template>
  <div class="all-content bg-pink sms-pay">
    <header class="header-wrap bg-pink">
      <p class="color-white">QR 생성</p>
      <button type="button" class="btn-close color-white" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="content-wrap d-flex-row">
      <div class="m-auto pb-80 t-c">
        <p class="color-white fz-18">아래의 QR코드를 스캔해주세요!</p>
        <p class="color-white mt-10">▼</p>
        <p class="mt-30 mb-50">
          <vue-qrcode :value="qrStr" style="width: 250px;"/>
        </p>
        <p>
          <button @click="refresh()">
            <img src="/image/icon_refresh_white.svg">
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: "QRPayment",
  components: {
    VueQrcode,
  },
  data() {
    return {
      qrStr:'',
      amount: '',
      timeout: null,
    }
  },
  mounted() {
    this.amount = this.$route.params.amount
    this.createQRCode('0')
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    refresh() {
      this.createQRCode('1')
    },
    createQRCode(_type){
      let formData = new FormData()
      formData.append('amount',this.amount)
      formData.append('type',_type)

      this.$post(this.$PAYMENT_QR_CREATE, 'qrpayment_qrcreate', formData, true,(result) => {
        this.qrStr = result.data.qr

        this.getPaymentState()

      }, (result) => {
        this.httpError(result.data)
      })
    },
    getPaymentState() {
      if (this.$route.name !== 'QRPayment') {
        return
      }

      let formData = new FormData()
      formData.append('qr', this.qrStr)

      this.$post(this.$PAYMENT_QR_LOOKUPRESULT, 'qrpayment_lookupresult', formData, false,(result) => {
        // 결제성공
        this.$router.replace({name:'QRPaymentSuccess', params: {
            payAmount: this.amount,
            paymentInfo: JSON.stringify(result.data)
        }})

      }, (result) => {
        if (result.data.state === 400 && result.data.data.state === '1') {  // 결제대기중
          this.timeout = setTimeout(() => {
            this.getPaymentState()
          }, 1000)

        } else {  // 결제 실패
          this.$router.push({name:'QRPaymentFail'})
        }

      })
    }
  }
}
</script>

<style scoped>
.m-auto {
  margin: auto;
}
</style>
