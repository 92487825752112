import { render, staticRenderFns } from "./QRCardPaymentFail.vue?vue&type=template&id=17faa0c6&scoped=true&"
import script from "./QRCardPaymentFail.vue?vue&type=script&lang=js&"
export * from "./QRCardPaymentFail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17faa0c6",
  null
  
)

export default component.exports