<template>
  <div ref="container" class="all-content bg-white sms-pay">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p class="color-gray-4F4F4F h-100 sms-back-title">카톡 예약 상세</p>
      <p class="btn-close fz-13 color-gray-4F4F4F sms-cancel-reserve" v-if="beforeSendDate" @click="cancelReservation">전체예약취소</p> <!-- 발송일 전일 경우-->
      <p class="btn-close fz-13 color-gray-4F4F4F sms-cancel-reserve" v-else-if="state !== '0' && state !== '5'" @click="deleteSMSHistory">내역삭제</p>
    </header>

    <div v-if="itemInfo" class="content-wrap">
      <div class="main-wrap pb-25">
        <div class="charge-form pt-0">
          <section>
            <div class="white-box">
              <div class="payment-SMS-detail-top-box">
                <div class="d-flex-row jc-between">
                  <span class="payment-SMS-detail-tit">상품명</span>
                  <span class="payment-SMS-detail-body">{{ itemInfo.goodsName }}</span>
                </div>
                <div class="d-flex-row jc-between mt-10 mb-10">
                  <span class="payment-SMS-detail-tit">상품금액</span>
                  <span class="payment-SMS-detail-body">{{ itemInfo.amount | numberWithCommas }} 원</span>
                </div>
                <div class="d-flex-row jc-between mt-10 mb-10">
                  <span class="payment-SMS-detail-tit">부과세</span>
                  <span class="payment-SMS-detail-body">{{ itemInfo.taxType === '0' ? '과세' : '비과세' }}</span>
                </div>
                <div class="d-flex-row jc-between mt-10 mb-10">
                  <span class="payment-SMS-detail-tit">예약일</span>
                  <span class="payment-SMS-detail-body">
                    {{ itemInfo.createAt }}
                    <!--                    {{ `${itemInfo.sendTime.substring(0,4) }.${itemInfo.sendTime.substring(4,6) }.${itemInfo.sendTime.substring(6,8) }-->
                    <!--                    ${itemInfo.sendTime.substring(8,10) }:${itemInfo.sendTime.substring(10,12) }` }}-->
                  </span>
                </div>
                <div class="d-flex-row jc-between mt-10 mb-10">
                  <span class="payment-SMS-detail-tit">예약발송일</span>
                  <span class="payment-SMS-detail-body">
                    {{ itemInfo.sendTime }}
                    <!--                    {{ `${itemInfo.sendTime.substring(0,4) }.${itemInfo.sendTime.substring(4,6) }.${itemInfo.sendTime.substring(6,8) }-->
                    <!--                    ${itemInfo.sendTime.substring(8,10) }:${itemInfo.sendTime.substring(10,12) }` }}-->
                  </span>
                </div>
                <div class="d-flex-row jc-between mt-10">
                  <span class="payment-SMS-detail-tit">메모</span>
                  <span class="payment-SMS-detail-body">{{ itemInfo.memo }}</span>
                </div>
              </div>
            </div>
          </section>

          <!--          <p class="mb-10 mt-30 fz-16">연락처<span class="color-red">*</span></p>-->
          <!--          <div class="d-flex-row">-->
          <!--            <div class="select no-right-border">-->
          <!--              <RPInputBorder-->
          <!--                  :type="'text'"-->
          <!--                  :placeholder="'전화번호를 입력해주세요'"-->
          <!--                  :model="phoneNumber"-->
          <!--                  @update="searchPhoneNumber"-->
          <!--                  :max-length="11"-->
          <!--                  :input-mode="'number'"-->
          <!--              />-->
          <!--            </div>-->
          <!--            <button class="input-address btn-gray-E8E8E8 font-16" style="align-self: center" @click="addPhoneNumber">-->
          <!--              추가-->
          <!--            </button>-->
          <!--          </div>-->
          <!--          <button class="search-box" @click="searchContact">내 연락처에서 찾기</button>-->
        </div><!-- /charge-form -->
      </div><!-- /main-wrap -->
      <div class="bar-10"></div>

      <div v-if="displayBeginning !== ''" class="fixed-beginning-add">
        <strong class="fz-15 color-darkgray">{{ displayBeginning }}</strong>
      </div>

      <!-- 연락처가 있을 때 -->
      <div id="contactListDiv" v-if="contactList.length > 0" class="content-row pt-25 pb-50">
        <p class="pb-10">총 {{ contactList.length }}명</p>

        <div class="d-flex-row">
          <div style="width: calc(100% - 30px)">
            <div v-for="(char, index) in charCodeList" :key="index">
              <div v-if="char.dataCount > 0" :class="{ 'pt-30' : index > 0}">
                <strong :id="'charKeyDiv' + index" class="fz-15 color-darkgray">{{ char.key }}</strong>
                <div class="select contact" v-for="(contact, index) in displayContactInfo[char.key]"
                     :key="'contact' + index" :class="{ 'mt-10' : index === 0 }">
                  <div class="input-close h-100 border-zero">
                        <span type="text" class="h-100 fz-15 w-100 p-15">
                          {{ contact.name }} {{ contact.phone }}
                        </span>
                    <span v-if="beforeSendDate" @click="deleteSelectedContact(index, char.key)" class="mr-20"><i class="icon-close fz-10"></i></span> <!-- 발송일 전일 경우-->
                    <span v-else class="individual-state" :class="[{ 'individual-state-wait': contact.state === '0', 'individual-state-success': contact.state === '1', 'individual-state-fail': contact.state === '2' }]">{{ contact.state | paymentStateFilter }}</span> <!-- 발송일 이후일 경우-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="beginning-char-group">
            <div v-for="(char, index) in charCodeList" :key="index">
              <p @click="selectedBeginning(index)">
                {{ char.key }}</p>
            </div>
          </div>
        </div>
<!--        <div v-for="(item, index) in itemInfo.reservedClientList" :key="index" class="select contact">-->
<!--          &lt;!&ndash; v-for 적용 &ndash;&gt;-->
<!--          <div class="input-close h-100 border-zero">-->
<!--            <span type="text" class="h-100 fz-15 w-100 p-15">-->
<!--              {{ item.clientName }} {{ item.clientContact }}-->
<!--            </span>-->
<!--            <span @click="deleteSelectedContact(index)" class="mr-20"><i class="icon-close fz-10"></i></span>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <!-- 연락처가 없을 때 -->
      <div v-else class="content-row pt-25">
        <p class="pb-50">총 0명</p>
        <div class="d-flex-row pt-10 jc-center">
          <p class="color-gray-C4C4C4 fz-16 t-c">연락처를 추가하세요.</p>
        </div>
      </div>

    </div><!-- /content-wrap -->
  </div>
</template>

<script>
// import RPInputBorder from "../../common/RPInputBorder";
import moment from "moment";

export default {
  name: "PaymentSMSHistoryDetail",
  // components: {RPInputBorder},
  data() {
    return {
      state : 0,

      phoneNumber: '',
      idx: '',
      itemInfo: '',
      cancelInfo: {},
      tokenList: [],

      contactList: [],  // 연락처 리스트
      // tmp_clientContactList : {}

      charCodeList: [], // 자음 코드 리스트
      displayContactInfo: {},
      displayBeginning: '',
      scrollY: 0,

      beforeSendDate: false,
    }
  },
  mounted() {
    this.idx = this.$route.params.idx
    this.state = this.$route.params.state

    this.initCharCodeList()

    window.addEventListener(this.$EVENT_CONTACTS, this.setContact)
    window.addEventListener('scroll', this.onScrollEvent, true)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_CONTACTS, this.setContact)
    window.removeEventListener('scroll', this.onScrollEvent, true)
  },
  methods: {
    checkBeforeDate() { /*발송일전 여부 체크*/
      let current = moment(new Date(), 'YYYY.MM.DD A hh:mm')
      let sendDate = moment(this.itemInfo.sendTime, 'YYYY.MM.DD A hh:mm')

      if (sendDate.isAfter(current)){
        this.beforeSendDate = true
      } else {
        this.beforeSendDate = false
      }
    },
    onScrollEvent(e){
      this.scrollY = e.target.scrollTop

      let showBeginning = false
      for(let i=0 ; i < this.charCodeList.length ; i++) {
        if (this.charCodeList[i].dataCount > 0) {
          if (this.scrollY >= window.document.getElementById('contactListDiv').offsetTop + window.document.getElementById('charKeyDiv' + i).offsetTop) {
            this.displayBeginning = this.charCodeList[i].key
            showBeginning = true
          }
        }
      }

      if (!showBeginning){
        this.displayBeginning = ''
      }
    },
    initCharCodeList() {
      this.charCodeList = [{"key": "ㄱ", "value": "가".charCodeAt(0), "dataCount": 0},
        {"key": "ㄴ", "value": "나".charCodeAt(0), "dataCount": 0},
        {"key": "ㄷ", "value": "다".charCodeAt(0), "dataCount": 0},
        {"key": "ㄹ", "value": "라".charCodeAt(0), "dataCount": 0},
        {"key": "ㅁ", "value": "마".charCodeAt(0), "dataCount": 0},
        {"key": "ㅂ", "value": "바".charCodeAt(0), "dataCount": 0},
        {"key": "ㅅ", "value": "사".charCodeAt(0), "dataCount": 0},
        {"key": "ㅇ", "value": "아".charCodeAt(0), "dataCount": 0},
        {"key": "ㅈ", "value": "자".charCodeAt(0), "dataCount": 0},
        {"key": "ㅊ", "value": "차".charCodeAt(0), "dataCount": 0},
        {"key": "ㅋ", "value": "카".charCodeAt(0), "dataCount": 0},
        {"key": "ㅌ", "value": "타".charCodeAt(0), "dataCount": 0},
        {"key": "ㅍ", "value": "파".charCodeAt(0), "dataCount": 0},
        {"key": "ㅎ", "value": "하".charCodeAt(0), "dataCount": 0},
        {"key": "힣", "value": "힣".charCodeAt(0), "dataCount": 0},
        {"key": "A", "value": "A", "dataCount": 0},
        {"key": "B", "value": "B", "dataCount": 0},
        {"key": "C", "value": "C", "dataCount": 0},
        {"key": "D", "value": "D", "dataCount": 0},
        {"key": "E", "value": "E", "dataCount": 0},
        {"key": "F", "value": "F", "dataCount": 0},
        {"key": "G", "value": "G", "dataCount": 0},
        {"key": "H", "value": "H", "dataCount": 0},
        {"key": "I", "value": "I", "dataCount": 0},
        {"key": "J", "value": "J", "dataCount": 0},
        {"key": "K", "value": "K", "dataCount": 0},
        {"key": "L", "value": "L", "dataCount": 0},
        {"key": "M", "value": "M", "dataCount": 0},
        {"key": "N", "value": "N", "dataCount": 0},
        {"key": "O", "value": "O", "dataCount": 0},
        {"key": "P", "value": "P", "dataCount": 0},
        {"key": "Q", "value": "Q", "dataCount": 0},
        {"key": "R", "value": "R", "dataCount": 0},
        {"key": "S", "value": "S", "dataCount": 0},
        {"key": "T", "value": "T", "dataCount": 0},
        {"key": "U", "value": "U", "dataCount": 0},
        {"key": "V", "value": "V", "dataCount": 0},
        {"key": "W", "value": "W", "dataCount": 0},
        {"key": "X", "value": "X", "dataCount": 0},
        {"key": "Y", "value": "Y", "dataCount": 0},
        {"key": "Z", "value": "Z", "dataCount": 0},
        {"key": "#", "value": "#", "dataCount": 0}]

      for(let i=0 ; i < this.charCodeList.length ; i++) {
        this.displayContactInfo[this.charCodeList[i].key] = []
      }

      this.getReservedSMSDetail()
    },
    initDisplayContactList() {
      for(let i=0 ; i < this.contactList.length ; i++) {
        let person = this.contactList[i].clientName
        let isContain = false

        if (person != null && person !== ''){
          for(let j=0 ; j < this.charCodeList.length; j++) {
            if(person.charCodeAt(0) >= this.charCodeList[j].value && person.charCodeAt(0) < this.charCodeList[j+1].value) {
              this.displayContactInfo[this.charCodeList[j].key].push({"idx": i, "name": person, "phone": this.contactList[i].clientContact, "state": this.contactList[i].state})
              this.charCodeList[j].dataCount += 1
              isContain = true
              break;
            } else if (person.substring(0,1).toUpperCase() === this.charCodeList[j].value){
              this.displayContactInfo[this.charCodeList[j].key].push({"idx": i, "name": person, "phone": this.contactList[i].clientContact, "state": this.contactList[i].state})
              this.charCodeList[j].dataCount += 1
              isContain = true
              break;
            }
          }

        } else {
          isContain = false
        }

        if(!isContain) {
          this.displayContactInfo[this.charCodeList[this.charCodeList.length - 1].key].push({"idx": i, "name": person, "phone": this.contactList[i].clientContact, "state": this.contactList[i].state})
          this.charCodeList[this.charCodeList.length - 1].dataCount += 1
        }
      }
    },
    selectedBeginning(_index){
      if (this.charCodeList[_index].dataCount > 0){
        this.$refs.container.scrollTop = window.document.getElementById('contactListDiv').offsetTop + window.document.getElementById('charKeyDiv' + _index).offsetTop
      }
    },
    cancelReservation() {
      this.$root.$refs.Confirm.showDialog('',
          '전체예약을 취소하시겠습니까?<br/>',
          '확인', '취소', () => {
            let formData = new FormData()
            for (let i = 0; i < this.itemInfo.reservedClientList.length; i++) {
              formData.append('smsPaymentCancelList[' + i + '].clientContact', this.itemInfo.reservedClientList[i].clientContact)
              formData.append('smsPaymentCancelList[' + i + '].token', this.itemInfo.reservedClientList[i].token)
            }

            this.$post(this.$PAYMENT_SMS_CANCEL, 'PaymentSMSHistoryDetail_cancelReservation', formData, true, () => {
              // this.$router.replace({name:'RequestCancelSuccess', params : { cancelInfo: this.itemInfo, paymentType: '1', isReservedCancel: true }})
              this.notify('error', '전체예약을 취소하였습니다.')
              this.$router.go(-2) // 메인으로 이동

            }, (result) => {
              this.httpError(result.data)
            })

          }, () => {
            this.$router.back()
          })
    },
    deleteSelectedContact(_index) {
      this.$root.$refs.Confirm.showDialog('',
          '<div class="cancel-popup">예약 취소</div>해당 사용자의 예약을<br/>취소하시겠습니까?<br/>',
          '확인', '취소', () => {
            let formData = new FormData()

            formData.append('smsPaymentCancelList[0].clientContact', this.itemInfo.reservedClientList[_index].clientContact)
            formData.append('smsPaymentCancelList[0].token', this.itemInfo.reservedClientList[_index].token)

            this.$post(this.$PAYMENT_SMS_CANCEL, 'PaymentSMSHistoryDetail_cancelReservation', formData, true, () => {
              this.notify('success', '예약이 취소되었습니다.')
              // this.$router.replace({
              //   name: 'RequestCancelSuccess',
              //   params: {cancelInfo: this.itemInfo, paymentType: '1', isReservedCancel: true}
              // })
            }, (result) => {
              this.httpError(result.data)
            })


          }, () => {
            // this.$router.back()
          })
    },
    getReservedSMSDetail() {
      this.$get(this.$RESERVED_SMS_DETAIL + this.idx, this.$options.name + '_getReservedSMSDetail', (result) => {
        this.itemInfo = result.data
        this.contactList = this.itemInfo.reservedClientList

        this.checkBeforeDate()
        this.initDisplayContactList()

      }, (result) => {
        this.httpError(result.data)
      })
    },
    deleteSMSHistory() {
      this.$root.$refs.Confirm.showDialog('',
          '해당 예약내역을<br/>삭제하시겠습니까?<br/>',
          '확인', '취소', () => {

            this.$put(this.$RESERVED_SMS_DETAIL + this.itemInfo.sendMasterIdx , this.$options.name + '_deleteSMSHistory', null, true,() => {
              this.notify('success', '내역이 삭제되었습니다.')
              this.$router.back()

            }, (result) => {
              this.httpError(result.data)
            })

          }, () => {
            // this.$router.back()
          })
    }
  },
  filters: {
    paymentStateFilter: (value) => {
      if (value === '0') {  // 요청
        return '대기'
      } else if (value === '1') { // 성공
        return '완료'
      } else if (value === '2') { // 실패
        return '실패'
      }
    },
  },
}
</script>

<style scoped>

.individual-state {
  margin-right: 20px;
  font-size: 12px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  height: 27px;
  border-radius: 45px;
  line-height: 27px;
  white-space: nowrap;
  text-overflow: clip;
}

.individual-state-wait {
  background: #C4C4C4;
}

.individual-state-success {
  background: #5D8BE1;
}

.individual-state-fail {
  background: #F66161;
}

</style>
