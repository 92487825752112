export default Object.freeze  ({
    install(Vue) {
        Vue.prototype.$EVENT_REQUEST_PERMISSION = '$EVENT_REQUEST_PERMISSION'
        Vue.prototype.$EVENT_BIO_RESULT = '$EVENT_BIO_RESULT'
        Vue.prototype.$EVENT_QR_STRING = '$EVENT_QR_STRING'
        Vue.prototype.$EVENT_BARCODE_STRING = '$EVENT_BARCODE_STRING'
        Vue.prototype.$EVENT_CONTACTS = '$EVENT_CONTACTS'
        Vue.prototype.$EVENT_CLOSE_PINCHECK = '$EVENT_CLOSE_PINCHECK'
        Vue.prototype.$EVENT_ACCOUNT = '$EVENT_ACCOUNT'
        Vue.prototype.$EVENT_CONNECT_DEVICE = '$EVENT_CONNECT_DEVICE'
        Vue.prototype.$EVENT_READ_CARD = '$EVENT_READ_CARD'
        Vue.prototype.$EVENT_PRINT_RESET = '$EVENT_PRINT_RESET'
        Vue.prototype.$EVENT_PRINT = '$EVENT_PRINT'
        Vue.prototype.$EVENT_REFRESH_CARDINFO = '$EVENT_REFRESH_CARDINFO'
        Vue.prototype.$EVENT_REFRESH_CARDINFO_REPAY = '$EVENT_REFRESH_CARDINFO_REPAY'
        Vue.prototype.$EVENT_CHECK_COMPLETED_AUTH = '$EVENT_CHECK_COMPLETED_AUTH'
        Vue.prototype.$EVENT_READY_MAIN = '$EVENT_READY_MAIN'
        Vue.prototype.$EVENT_LOAD_FCM_LINK = '$EVENT_LOAD_FCM_LINK'
    }
})
