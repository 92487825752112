<template>
    <div class="all-content">
      <div class="bg-white content-row-new">
        <div class="confirm-list">
          <p>
            <img v-if="logoImg" :src=logoImg alt="logo" style="height: 140px; object-fit: contain">
            <img v-else src="/image/wb_logo.png" alt="캐릭터">
            <span class="mt-20">{{ amount | numberWithCommas }}원<br>결제 완료되었습니다.</span>
<!--            <i class="mt-20">결제가 완료되었습니다.</i>-->
          </p>
          <ul class="price-list">
            <li>
              <strong>배대사</strong>
              <em>{{ deliveryAgency.nickname ? deliveryAgency.nickname : '이름없음(' + deliveryAgency.idx + ')'}} ({{ deliveryAgency.bankName }} {{ deliveryAgency.accountNumber }} {{ deliveryAgency.depositor }})</em>
            </li>
            <li>
              <strong>카드번호</strong>
              <em>{{ cardInfo }}</em>
            </li>
            <li>
              <strong>할부개월</strong>
              <em>{{ installment.name }}</em>
            </li>
            <li>
              <strong>결제일시</strong>
              <em>{{ convertedDate }}</em>
            </li>
            <li class="b-none">
            </li>
          </ul>
        </div>
      </div>
      <button class="btn-l btn-pink" @click="$router.back()">확인</button>
    </div>
</template>

<script>
export default {
  name: "ChargeSuccess",
  data() {
    return {
      amount: '',
      cardInfo: '',
      regDate: '',
      installment: [],
      convertedDate: '',
      deliveryAgency: '',
      logoImg: ''
    }
  },
  mounted() {
    this.installment = this.$route.params.installment
    this.amount = this.$route.params.amount
    this.cardInfo = this.$route.params.cardInfo
    this.regDate = this.$route.params.regDate
    this.deliveryAgency = this.$route.params.deliveryAgency

    let convertDate = this.regDate.replace('T', ' ')
    convertDate = convertDate.substr(0, 19)
    this.convertedDate = convertDate
    this.getLogoImg()
  },
  methods: {
    getLogoImg() {
      this.$get(this.$USER_LOGO_BRANCH, 'logo_img',(result) => {
        console.log(result.data)
        this.logoImg = result.data
      }, (result) => {
        console.log(result.data.message)
      })
    },
  }
}
</script>

<style scoped>

</style>