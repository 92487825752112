<template>
  <div class="all-content security bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.push({ name: 'SecuritySetting' })"><i class="icon-back"></i></button>
      <p>비밀번호 변경</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap pb-80">
        <div class="charge-form">
          <p class="mb-10">기존 비밀번호</p>
          <div class="select">
            <RPInputBorder
                :type="'password'"
                :placeholder="'기존 비밀번호를 입력해주세요.'"
                :model="existingPwd"
                :max-length="20"
                @update="(_model) => {this.existingPwd = _model}"
                :input-mode="'text'"
            />
          </div>
          <p class="color-red fz-13 pl-10">{{ errors.existingPwd }}</p>
          <p class="mb-10 mt-30">신규 비밀번호</p>
          <div class="select">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호(영문, 숫자, 특수문자 포함 8자 이상)'"
                :model="newPwd"
                :max-length="20"
                @update="(_model) => {this.newPwd = _model}"
                :input-mode="'text'"
            />
          </div>

          <p class="color-red fz-13 pl-10">{{ errors.newPwd }}</p>
          <p class="mb-10 mt-30">비밀번호 확인</p>
          <div class="select">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호를 한번 더 입력해주세요.'"
                :model="newPwdConfirm"
                :max-length="20"
                @update="(_model) => {this.newPwdConfirm = _model}"
                :input-mode="'text'"
            />
          </div>

          <p class="color-red fz-13 pl-10">{{ errors.newPwdConfirm }}</p>
        </div>
      </div>
    </div>
    <button class="btn-pink mr-auto btn-m b-0 position-fixed" @click="changePwd">확 인</button>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";

export default {
  name: "ChangePassword",

  components: {
    RPInputBorder
  },

  data() {
    return {
      existingPwd: '',
      newPwd: '',
      newPwdConfirm: '',
      errors: {
        newPwdConfirm: '',
        existingPwd: '',
        newPwd: ''
      },
    }
  },

  methods: {
    errorClear(){
      this.errors = {newPwConfirm: '', existingPwd: '', newPwd: ''}
    },
    changePwd() {
      this.errorClear()

      if(this.existingPwd === '') {
        this.errors.existingPwd = '기존 비밀번호를 입력해주세요.'
        return
      }

      if(this.newPwd === '') {
        this.errors.newPwd = '비밀번호를 입력해주세요.'
        return
      }

      if(this.newPwdConfirm === '') {
        this.errors.newPwdConfirm = '비밀번호를 한번 더 입력해주세요.'
        return
      }

      if(this.newPwd !== this.newPwdConfirm) {
        this.errors.newPwdConfirm = '비밀번호가 일치하지 않습니다.'
        return
      }

      let formData = new FormData()
      formData.append('existingPwd', this.existingPwd)
      formData.append('renewalPwd', this.newPwd)
      formData.append('renewalPwdCheck', this.newPwdConfirm)

      this.$put(this.$USER_PASSWORD, 'user_password', formData, true,() => {
        this.$router.push({ name: 'ChangePasswordSuccess' })
      }, (result) => {
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
