<template>
  <div class="all-content" id="alarmDiv">
    <header class="header-wrap border-zero">
      <button @click="$router.back()" class="btn-back"><i class="icon-back"></i></button>
      <p>1:1 문의</p>
    </header>
    <div class="content-wrap bg-white top-117px">
      <div class="list-none-wrap p-20" v-if="loaded && items.length === 0">
        <div class="list-none">
          <p><img src="/image/wb_logo.png" alt="캐릭터" style="opacity: 0.5"></p>
          <p class="list-none-title">등록된 문의가 없습니다.</p>
        </div>
        <button class="btn-l btn-pink btn-round mb-50" @click="$router.push({name:'QnaRegist'})">문의하기</button>
      </div>
      <div class="alram-list p-0 qna-wrap" v-if="items.length > 0">
        <ul>
          <li v-for="(item, index) in items" :key="'i' + index" class="mb-0 border-bottom">
            <a @click="goDetail(item.idx)" class="display-block position-relative p-20">
              <div class="d-flex-row">
                <small class="color-gray">{{ item.regDate }}</small>
                <small class="ml-auto" :class="stateClass(item.state)">{{ item.state | state }}</small>
              </div>
              <p class="p-0 mt-5" style="text-overflow: ellipsis; white-space: nowrap;">
                {{ item.title }}
              </p>
            </a>
          </li>
        </ul>
        <div class="px-20 profile-upload-btn w-100">
          <button class="btn-l btn-pink btn-round mb-50" @click="$router.push({name:'QnaRegist'})">문의하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QnaList",
  data() {
    return {
      items: [],
      loaded: false
    }
  },
  mounted() {
    this.getQna()
  },
  methods: {
    getQna() {
      this.selectedIdx = ''
      this.$get(this.$QNA_LIST, 'qnalist_getqna', (result) => {
        this.items = result.data
        this.loaded = true
      }, (result) => {
        this.httpError(result.data)
        this.loaded = true
      })
    },
    goDetail(_no) {
      this.$router.push({name:'QnaDetail', params:{idx:_no}})
    },
    stateClass(value) {
      if (value === '0' ) {
        return 'qna-state-0'
      } else if (value === '1' ) {
        return 'qna-state-1'
      } else if (value === '2' ) {
        return 'qna-state-2'
      }
      return ''
    }
  },
  filters: {
    state: (value) => {
      if (value === '0' ) {
        return '답변대기'
      } else if (value === '1' ) {
        return '처리중'
      } else if (value === '2' ) {
        return '답변완료'
      }
      return ''
    },

  }
}
</script>

<style scoped>

</style>
