<template>
  <div class="intro-wrap">
    <div class="row">
      <div class="intro-wrap-tit">
        <p>결제의 새로운 패러다임을 만나다</p>
        <img src="/image/logo_white.png" alt="로고" style="width: 70%">
      </div>
      <div class="intro-wrap-btn">
<!--        <p>Copyright © 2021 REAP PAY Inc.</p>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  mounted() {
    setTimeout(() => {

      // 자동 로그인
      let passToken = localStorage.getItem('passToken')
      if (passToken) {
        let formData = new FormData()
        formData.append('passToken', passToken)

        this.$post(this.$AUTH_AUTOLOGIN, 'intro_login_doAutoLogin', formData, false, (result) => {
          sessionStorage.setItem('accessToken', result.data.accessToken)
          sessionStorage.setItem('refreshToken', result.data.refreshToken)
          localStorage.setItem('passToken', result.data.passToken)

          setTimeout(() => {
            this.refreshToken()
          }, this.refreshTokenRate)

          this.refreshPush()

          // this.setPushAlarm(() => {
          localStorage.setItem('firstPinChecked', '0')
          localStorage.setItem('checkedCI', '0')
          this.$router.push({name: 'Main'})
          // })

        }, (result) => {
          sessionStorage.removeItem('accessToken')
          sessionStorage.removeItem('refreshToken')
          localStorage.removeItem('passToken')
          // localStorage.removeItem('useLocalBio')

          if (result.data.state === 400) {
            // state : 0(일반)
            if (result.data.data.state === '1') {  // 심사중
              this.$router.push({name: 'ReviewingUser'})

            } else if (result.data.data.state === '2') { // 탈퇴
              this.$router.push({name: 'LeaveUser'})

            } else if (result.data.data.state === '3') { // 정지
              this.$root.$refs.Alert.showAlert('페이스터 정책 위반',
                  '페이스터 정책 위반으로 현재 계정은<br/>사용정지 처리되었습니다.<br/><br/>' +
                  '사유 : ' + result.data.reason + '<br/>' + result.data.time,
                  () => {
                    this.$router.replace({name: 'BeforeLogin'})
                  }, false, false)

            } else if (result.data.data.state === '4') { // 휴먼
              this.$root.$refs.Confirm.showDialog('페이스터 휴먼계정',
                  '현재 계정은 1년간 접속하지 않아<br/>휴먼 상태로 전환된 상태입니다.<br/>' +
                  '휴먼 계정 해제를 통해<br/>정상적으로 이용하실 수 있습니다.',
                  '휴먼계정 해제', '취소', () => {
                    this.$router.push({name: 'InactivityRequest'})
                  }, () => {
                    this.$router.replace({name: 'BeforeLogin'})
                  }, false, false)

            } else {
              this.httpError(result.data)
              this.$router.replace({name: 'BeforeLogin'})
            }

          } else {
            this.httpError(result.data)
            this.$router.replace({name: 'BeforeLogin'})
          }
        })

      } else {
        this.$router.replace({name: 'BeforeLogin'})
      }
    }, 500)
  },
}
</script>

<style scoped>

</style>