<template>
  <div class="all-content">
      <div class="certification-wrap">
        <header class="header-wrap bg-pink">
          <button type="button" class="btn-close color-white" @click="$router.push({ name: 'SecuritySetting' })"><i class="icon-close"></i></button>
        </header>
        <div class="certification-box bg-pink">
          <span class="fz-28">기존 핀번호 입력</span>
          <p class="mb-30"><strong>핀번호</strong>를 입력해주세요.</p>
          <div class="certification-bullets">
            <span :class="{'bullets-active' : pin.length >= 1}"></span>
            <span :class="{'bullets-active' : pin.length >= 2}"></span>
            <span :class="{'bullets-active' : pin.length >= 3}"></span>
            <span :class="{'bullets-active' : pin.length >= 4}"></span>
            <span :class="{'bullets-active' : pin.length >= 5}"></span>
            <span :class="{'bullets-active' : pin.length >= 6}"></span>
          </div>
          <button class="btn-under color-white reset-pin-btn" @click="$router.replace({name:'RestorePin'})">핀번호를 잊어버렸어요.</button>
        </div>
        <div class="number-area">
          <ul>
            <li>
              <button @click="numBtn('1')">1</button>
              <button @click="numBtn('2')">2</button>
              <button @click="numBtn('3')">3</button>
            </li>
            <li>
              <button @click="numBtn('4')">4</button>
              <button @click="numBtn('5')">5</button>
              <button @click="numBtn('6')">6</button>
            </li>
            <li>
              <button @click="numBtn('7')">7</button>
              <button @click="numBtn('8')">8</button>
              <button @click="numBtn('9')">9</button>
            </li>
            <li>
              <button @click="cancel()" class="cancel">취소</button>
              <button @click="numBtn('0')">0</button>
              <button @click="backspace()">
                <img src="/image/cancel.svg" alt="지우기">
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "ChangePinCheck",
  data() {
    return {
      pin: '',
    }
  },
  mounted() {

  },
  methods: {
    numBtn(_num) {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin + _num
      if (this.pin.length === 6 ) {
        let formData = new FormData()
        formData.append('pinNumber', this.pin)
        this.$post(this.$USER_CHECK_PIN, 'changepincheck_numbtn', formData, true,() => {
          this.$router.replace({name:'ChangePinRegist',params:{ old_pin: this.pin }})
        }, (result) => {
          this.httpError(result.data)
          this.pin = ''
        })
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
