import { render, staticRenderFns } from "./SsoFindPwNoId.vue?vue&type=template&id=53a44183&scoped=true&"
import script from "./SsoFindPwNoId.vue?vue&type=script&lang=js&"
export * from "./SsoFindPwNoId.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a44183",
  null
  
)

export default component.exports