<template>
  <div class="intro-wrap">
    <div class="row">
      <div class="intro-wrap-tit">
        <p>결제의 새로운 패러다임을 만나다</p>
        <img src="/image/logo_white.png" alt="로고" style="width: 70%">
      </div>
      <div class="intro-wrap-btn">
        <button class="btn-l btn-white br-5" @click="goLogin">로그인</button>
        <button class="btn-s color-white fz-18 mt-5" @click="goSignUp">회원가입</button>

        <div class="d-flex-row mt-40">
          <button class="color-white fz-14 weight-light" style="text-decoration-line: underline;"
                  @click="$router.push({ name: 'FindId' })">아이디찾기
          </button>
          <span class="ml-5 mr-5"> </span>
          <button class="color-white fz-14 weight-light" style="text-decoration-line: underline;"
                  @click="$router.push({ name: 'FindPw' })">비밀번호찾기
          </button>
        </div>

<!--        <p class="mt-30">Copyright © 2021 REAP PAY Inc.</p>-->
      </div>
    </div>
    <div v-if="isTest">
      before login
      <br/><br/><br/>블루투스 연동 테스트<br/>
      <button @click="requestConnectDevice()">기기연결 요청</button><br/><br/>
      <button @click="requestReadCard()">카드 정보 읽기 요청</button><br/><br/>
      <button @click="requestPrint()">테스트 인쇄</button><br/><br/>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeforeLogin",
  data() {
    return {
      isTest: false,
    }
  },
  //test
  mounted() {
    if (this.isTest) {
      window.addEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
      window.addEventListener(this.$EVENT_READ_CARD, this.setCardInfo)
      window.addEventListener(this.$EVENT_PRINT_RESET, this.resetPrintResult)
      window.addEventListener(this.$EVENT_PRINT, this.printResult)
    }
  },
  beforeDestroy() {
    if (this.isTest) {
      window.removeEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
      window.removeEventListener(this.$EVENT_READ_CARD, this.setCardInfo)
      window.removeEventListener(this.$EVENT_PRINT_RESET, this.resetPrintResult)
      window.removeEventListener(this.$EVENT_PRINT, this.printResult)
    }
  },
  //:test
  methods: {
    goLogin() {
      this.$router.push({name: 'Login'})
    },
    goSignUp() {
      this.$router.push({name: 'TermAgree'})
    },

    //test:
    requestConnectDevice() {
      window.requestConnectDevice()
    },
    connectResult(e) {
      let result = e.detail
      if (result === '1') {
        alert('연결에 성공했습니다.')
      } else {
        alert('연결에 실패했습니다.')
      }
    },
    requestReadCard() {
      window.requestReadCard()
    },
    setCardInfo(e) {
      let cardInfo = e.detail
      if (cardInfo === '//') {
        alert('카드인식에 실패 했습니다.')
        return
      }

      let cardNo = cardInfo.split('/')[0]
      let limitMM = cardInfo.split('/')[1]
      let limitYY = cardInfo.split('/')[2]
      alert('cardNo : ' + cardNo + ',\nMM : ' + limitMM + ',\nYY : ' + limitYY)
    },
    requestPrint() {
      // window.resetPrintData()
      window.addPrintBoldString('영수증\n')
      window.addPrintString('상호명 : 청도국밥\n')
      window.addPrintString('금액 : 100,000 원')
      window.requestPrintStart()
    },
    resetPrintResult(e) {
      let result = e.detail
      if (result === '1') {
        // alert('프린터 초기화에 성공했습니다.')
        window.addPrintBoldString('영수증\n')
        window.addPrintString('상호명 : 청도국밥\n')
        window.addPrintString('금액 : 100,000 원')
        window.requestPrintStart()
      } else {
        alert('프린터 초기화에 실패했습니다.')
      }
    },
    printResult(e) {
      let result = e.detail
      if (result === '1') {
        // alert('인쇄에 성공했습니다.')
      } else {
        alert('인쇄에 실패했습니다.')
      }
    }
    //:test
  }
}
</script>

<style scoped>

</style>
