<template>
    <div class="all-content bg-white sms-pay">
        <header class="header-wrap">
            <button type="button" class="btn-back" @click="$router.push({ name: 'Main' })"><i class="icon-back"></i>
            </button>
            <p>카톡 결제</p>
        </header>
        <div class="content-wrap h-auto">
            <div class="main-wrap pb-80">
                <div class="charge-form pt-0">
                    <p class="mb-10 fz-16">상품명</p>
                    <div class="d-flex-row">
                        <div class="select">
                            <RPInputBorder
                                :type="'text'"
                                :placeholder="'상품명을 입력해주세요'"
                                :model="itemName"
                                @update="(_model) => {this.itemName = _model}"
                                :input-mode="'text'"
                            />
                        </div>
                    </div>
                    <p class="mb-10 mt-30 fz-16">결제금액<span class="color-red">*</span></p>
                    <div class="d-flex-row">
                        <div class="select">
                            <RPInputBorder
                                :type="'text'"
                                :placeholder="'상품금액을 입력해주세요.(100원 이상)'"
                                :model="payAmt"
                                @update="(_model) => {this.payAmt=_model}"
                                :input-mode="'numeric'"
                                :withComma="true"
                            />
                        </div>
                    </div>
                    <span class="color-red">{{ errors.payAmt }}</span>
                    <p class="mb-10 mt-30 fz-16">부과세</p>
                    <div class="tab-tax">
                        <div :class="{ activeTax: isTax }" @click="isTax = !isTax">과세</div>
                        <div :class="{ activeNoTax: !isTax }" @click="isTax = !isTax">면세</div>
                    </div>
                    <div class="send">
                        <span class="mt-30 fz-16">발송</span>
                        <div class="mt-30 radios">
                            <input type="radio" id="immediate" value="immediate" v-model="sendMethod">
                            <label for="immediate" class="mr-10 fz-16">즉시발송</label>
                            <input type="radio" id="reserve" value="reserve" v-model="sendMethod">
                            <label for="reserve" class="fz-16">예약발송</label>
                        </div>
                    </div>
                    <div class="select-wrap mt-10 date-con" v-show="sendMethod === 'reserve'">
                        <!--                        <button class="select" @click="openSelect('date')">{{ selectedDate | moment('YYYY.MM.DD')}}</button>-->
                        <div class="select" style="width: calc(100% - 110px);">
                            <date-picker class="h-100 fz-16 w-100 t-c br-5"
                                         v-model="selectedDate"
                                         :config="this.smsCalendarOptions"
                                         readonly/>
                        </div>
                        <button class="select ml-7" style="width: 110px;" @click="openSelect('time')">
                            {{ selectedTime | moment('HH:00') }}
                        </button>
                    </div>
                    <p class="mb-10 mt-30 fz-16">메세지요청(최대30자)</p>
                    <div class="d-flex-row">
                        <div class="select">
                            <RPInputBorder
                                :type="'text'"
                                :placeholder="'고객에게 전달할 메시지를 입력해주세요'"
                                :model="memo"
                                @update="(_model) => {this.memo = _model}"
                                :max-length="30"
                            />
                        </div>
                    </div>
                    <div class="mt-10 mb-20 checks-circle-black">
                        <input type="checkbox" id="chk" v-model="requestChk">
                        <label for="chk" class="fz-16">고객주소정보 요청 시 체크</label>
                    </div>
                </div>
            </div>
            <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext">다음</button>
        </div>
        <s-m-s-pay-select
            v-show="showSelect"
            :dateList="dateList"
            :timeList="timeList"
            @selectedDate="setSelectedDate"
            @selectedTime="setSelectedTime"
            @close="showSelect=false"/>
    </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import SMSPaySelect from "@/components/payment/smsPayment/SMSPaySelect";
import moment from "moment";

export default {
    name: "SMSPayment",

    components: {
        RPInputBorder,
        SMSPaySelect
    },

    data() {
        return {
            memo: '',
            showSelect: false,
            dateList: [],
            timeList: [],
            selectedDate: null,
            selectedTime: new Date().setTime(new Date().getTime() + (1 * 60 * 60 * 1000)),
            isTax: true,
            sendMethod: 'immediate',
            requestChk: false,
            payAmt: '',
            itemName: '',
            errors: {
                payAmt: '',
            },
            smsCalendarOptions: null,
        }
    },
    beforeMount() {
        this.initCalendarOptions()
    },
    mounted() {

    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'Main') {
                vm.clearData()
            }
        })
    },
    methods: {
        clearData() {
            this.memo = ''
            this.showSelect = false
            this.dateList = []
            this.timeList = []
            this.selectedDate = null
            this.selectedTime = new Date().setTime(new Date().getTime() + (1 * 60 * 60 * 1000))
            this.isTax = true
            this.sendMethod = 'immediate'
            this.requestChk = false
            this.payAmt = ''
            this.itemName = ''
            this.smsCalendarOptions = null
            this.errors = {
                payAmt: ''
            }

            this.initCalendarOptions()
        },
        initCalendarOptions() {
            this.smsCalendarOptions = {
                format: 'YYYY.MM.DD',
                locale: moment.locale('ko'),
                minDate: new Date(),
                icons: {
                    time: 'glyphicon glyphicon-time',
                    date: 'glyphicon glyphicon-calendar',
                    up: 'icon-arrow-up',
                    down: 'icon-arrow-down',
                    previous: 'icon-arrow-left',
                    next: 'icon-arrow-right',
                    today: 'glyphicon glyphicon-screenshot',
                    clear: 'glyphicon glyphicon-trash',
                    close: 'glyphicon glyphicon-remove'
                },
                ignoreReadonly: true,
            }

            this.selectedDate = new Date()
        },

        openSelect(option) {
            if (option === 'date') {
                let tmr = new Date()
                tmr.setDate(tmr.getDate() + 1)
                let afterTmr = new Date()
                afterTmr.setDate(afterTmr.getDate() + 2)
                this.dateList = [new Date(), tmr, afterTmr]
                this.timeList = []
            } else if (option === 'time') {
                this.dateList = []
                this.timeList = []

                let currentDate = new Date()
                if (moment(this.selectedDate).format('YYYY-MM-DD') === moment(currentDate).format('YYYY-MM-DD')) {
                    for (let i = 0; i < 24; i++) {
                        let time = currentDate.getTime() + ((i + 1) * 60 * 60 * 1000)
                        this.timeList.push(time)

                        if (new Date(time).getHours() === 23) {
                            break
                        }
                    }

                } else {
                    for (let i = 0; i < 24; i++) {
                        let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
                        let time = date.getTime() + (i * 60 * 60 * 1000)
                        this.timeList.push(time)
                    }
                }
            }

            this.showSelect = true
        },

        setSelectedDate(date) {
            this.selectedDate = date
            this.showSelect = false
        },

        setSelectedTime(time) {
            this.selectedTime = time
            this.showSelect = false
        },

        goNext() {
            let amount = Number(this.payAmt.replaceAll(',', ''))
            if (!amount) {
                this.errors.payAmt = '결제금액을 입력해주세요.'
                return
            }

            if (amount * 1 < 100) {
                this.errors.payAmt = '100원보다 큰 금액을 입력해주세요.'
                return
            }

            if (!/^[0-9-][0-9,.]*$/.test(amount)) {
                this.errors.payAmt = '결제금액을 정확하게 입력해주세요.'
                return
            }

            let sendDate = ''
            let regex_date = /^\d{4}\.\d{1,2}\.\d{1,2}$/;
            if (regex_date.test(String(this.selectedDate))) {
                sendDate = String(this.selectedDate)
            } else {
                sendDate = moment(String(this.selectedDate)).format('YYYY.MM.DD')
            }

            this.$router.push({
                name: 'SMSContactAdd', params: {
                    goodsName: this.itemName,
                    amount: amount,
                    taxType: this.isTax ? '0' : '1',
                    sendType: this.sendMethod === 'immediate' ? '0' : '1',
                    sendTime: sendDate + ' ' + moment(this.selectedTime).format('HH:00'),
                    memo: this.memo,
                    additionType: this.requestChk ? '1' : '0'
                }
            })
        },

        changeNextBtn() {
            if (this.payAmt === '' || this.payAmt <= 0 || !/^[0-9-][0-9,.]*$/.test(this.payAmt)) {
                window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
            } else {
                window.document.getElementById("nextBtn").className = "align-bottom btn btn-m  btn-pink"
            }
        },
    },
    watch: {
        payAmt() {
            this.changeNextBtn()
        }
    }
}
</script>

<style scoped>

</style>
