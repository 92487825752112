import Vue from 'vue'
import App from './App.vue'
import router from './router'

router.beforeEach((to, from, next) => {
    let tokenMiddle = [ // 로그인후 이용가능한 화면 추가
        'SignUpPinRegist',
        'SignUpPinCheck',
        'SignUpPinFinish',
        'SignUpBioSuccess',
        'SignUpBioFail',
        'Main',
        'SearchHistory',
        'NotificationList',
        'NotificationRentRepay',
        'Charge',
        'ChargeSuccess',
        'ChargeFail',
        'HandPayment',
        'HandPaymentCardInfo',
        'HandPaymentSuccess',
        'HandPaymentFail',
        'QRPayment',
        'QRScanInputAmount',
        'QRPaymentFast',
        'QRPaymentSuccess',
        'QRPaymentFail',
        'QRCardScanInputAmount',
        'QRCardPaymentFast',
        'QRCardPaymentSuccess',
        'QRCardPaymentFail',
        'PreviewFileImage',
        'RegistAccount',
        'RentRegistSuccess',
        'RentPayment',
        'RentPaymentInfoInput',
        'RentTerm',
        'CardInstallmentPlan',
        'RentPaymentSuccess',
        'SMSContactAdd',
        'SMSContactSelect',
        'SMSPayment',
        'SMSPaymentFail',
        'SMSPaymentSuccess',
        'TerminalPayment',
        'TerminalPaymentCardRead',
        'TerminalPaymentFail',
        'TerminalPaymentSuccess',

        'QuickPaymentHistory',
        'RentPaymentHistory',

        'ChangeRentName',
        // 'ChargePaymentHistory',
        'ChargePaymentHistoryDetail',
        // 'ChargeSalesHistory',
        // 'ChargeSalesHistoryCardDetail',
        'ChargeSalesHistoryDetail',
        'ChargeSalesMonthlyRepay',
        'ChargeSalesTypeSelect',
        'RentRepaymentSuccess',
        'StopPaying',

        'PGPaymentHistory',
        'SMSReservationHistory',

        'PaymentHistory',
        'PaymentHistoryDetail',
        'PaymentSMSHistoryDetail',
        'RequestCancelReservation',
        'RequestCancelSuccess',

        'MyInfo',
        'DeliveryAgencyList',
        'RegistDeliveryAgency',
        'SalesAlert',
        'SecuritySetting',
        'RegistCard',
        'RegistCardSuccess',
        'RegistCardFail',
        'CardList',
        'ChangePassword',
        'ChangePasswordFail',
        'ChangePasswordSuccess',
        'ChangePinCheck',
        'ChangePinRegist',
        'ChangePinRegistRE',
        'ChangePinSuccess',
        'RestorePin',
        'NoticeList',
        'NotificationSetting',
        'FaqList',
        'FaqSubList',
        'Leave',
        'LeaveSuccess',
    ]

    if (tokenMiddle.indexOf(to.name) !== -1) {
        let token = sessionStorage.getItem('accessToken')
        if (!token) {
            next({name: 'BeforeLogin'})
        }
    }

    next()
})

Vue.config.productionTip = false

import Vuex from 'vuex'

Vue.use(Vuex)

import Notifications from 'vue-notification'

Vue.use(Notifications)

import api from './commonjs/api';

Vue.use(api)

import common from "@/commonjs/common";

Vue.use(common)

import event from './commonjs/event';

Vue.use(event)

import vueMoment from 'vue-moment'

Vue.use(vueMoment)

import VueCryptojs from "vue-cryptojs"

Vue.use(VueCryptojs)

import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'

Vue.component('datePicker', datePicker)

import Loading from 'vue-loading-overlay'

Vue.component('Loading', Loading)

const EventBus = new Vue();
export default EventBus

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
