<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>무이자 할부 혜택</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap">
        <div v-if="cardInstallmentList != null && cardInstallmentList.length > 0">
          <section class="sales-history-all-section">
            <div class="white-box-br-15" :class="{ 'mt-15' : index > 0 }"
                 v-for="(installment, index) in cardInstallmentList" :key="index" style="border: 1px solid #dddddd">
              <div class="d-flex-row jc-between" :class="{ 'border-bottom' : installment.installInfo02 !== '' }">
                <span class="fz-16" :class="{ 'mb-20' : installment.installInfo02 !== '' }">{{
                    installment.cardName
                  }}</span>
                <span class="fz-14" :class="{ 'mb-20' : installment.installInfo02 !== '' }">{{
                    installment.installInfo01
                  }}</span>
              </div>
              <div class="mt-20" v-if="installment.installInfo02 !== ''">
                <span class="fee-tit fz-14 ml-auto display-block">{{ installment.installInfo02 }}</span>
              </div>
            </div>
          </section>
        </div>
        <div v-else>
          <p>현재 제공가능한 카드사 무이자 할부 혜택이 없습니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardInstallmentPlan",
  data(){
    return {
      cardInstallmentList: [],
      // test start
      // cardInstallmentList: [
      //   {
      //     cardName: '삼성카드',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '120만원 이상 7~24개월',
      //   },
      //   {
      //     cardName: '국민카드',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '120만원 이상 7~24개월',
      //   },
      //   {
      //     cardName: '신한카드',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '',
      //   },
      //   {
      //     cardName: '현대카드',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '120만원 이상 8~24개월',
      //   },
      //   {
      //     cardName: 'BC카드',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '',
      //   },
      //   {
      //     cardName: 'NH농협카드',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '',
      //   },
      //   {
      //     cardName: 'LOTTECARD',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '',
      //   },
      //   {
      //     cardName: '하나카드',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '',
      //   },
      //   {
      //     cardName: 'CITI카드',
      //     installInfo01: '5만원 이상 2~6개월',
      //     installInfo02: '',
      //   },
      // ]
      // test end
    }
  },
  mounted() {
    this.getInstallmentPlan()
  },
  methods: {
    getInstallmentPlan(){
      this.$get(this.$MONTHLY_INTERESTFREE, 'cardinstallmentplan_getinstallmentlist',(result) => {
        this.cardInstallmentList = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>