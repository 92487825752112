<template>
  <div class="all-content" id="alarmDiv">
    <header class="header-wrap">
      <button @click="$router.back()" class="btn-back"><i class="icon-back"></i></button>
      <p>{{ $route.params.title }}</p>
    </header>
    <div class="content-wrap bg-white">
      <div class="alram-list p-0">
        <ul>
          <li v-for="(item, index) in items" :key="'i' + index" class="mb-0">
            <a @click="goDetail(item.idx)" class="display-block position-relative p-20">
              <p class="p-0 mt-5 faq-content mr-20">
                {{ item.title }}
              </p>
              <i class="w-auto h-auto r-20" :class="{'icon-arrow-down':item.idx !== selectedIdx, 'icon-arrow-up':item.idx === selectedIdx}"></i>
            </a>
            <div class="bg-gray p-1">
              <div v-show="item.idx === selectedIdx" class="p-20">
                <div class="p-0">
                  <div class="faq-content" v-html="item.content"></div>
                </div>
                <div v-for="(img, imgIndex) in item.imageList" :key="'i' + imgIndex">
                  <img class="max-w-100" :src="img">
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import showdown from "showdown"

export default {
  name: "FaqSubList",
  data() {
    return {
      items: [],
      selectedIdx: '',
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$get(this.$FAQ + '?idx=' + this.$route.params.idx, 'faqlist_getfaq',(result) => {
          console.log('item', result)
        this.items = result.data

        for (let i = 0; i < this.items.length ; i ++){
          let content = this.items[i].content
          let converter = new showdown.Converter()
          this.items[i].content = converter.makeHtml(content).replaceAll('\\', '')
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goDetail(_no) {
      if (this.selectedIdx === _no) {
        this.selectedIdx = ''
      } else {
        this.selectedIdx = _no
      }
    }
  }
}
</script>

<style scoped>

</style>
