<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list h-60">
      <div class="d-flex-row jc-between">
        <h5 class="pt-0">신용카드 선택</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul class="content-list">
        <li v-for="(card, index) in cards" :key="index" >
          <a @click="selectCard(card)">
            <span>{{ card.name }} {{ card.number }}<i v-show="card.main">주카드</i></span>
          </a>
        </li>
        <li>
          <a @click="$router.push({name:'RegistCard'})">
            <span class="color-pink">+ 카드 등록</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChargeCardSelect",
  props: ['cards'],
  methods: {
    selectCard(_card) {
      this.$emit('selected', _card)
    }
  },
  filters: {
    bankImage: (value) => {
      if (value === '') {
        return ''
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>