<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list h-60">
      <div class="d-flex-row jc-between">
        <h5>VAN사 선택</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul class="content-list">
        <li v-for="(van, index) in vanList" :key="index">
          <a @click="selected(van)">
            <span>{{ van.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "VanSelect",
  props: ['vanList'],
  methods: {
    selected(_van) {
      this.$emit('selected', _van)
    }
  }
}
</script>

<style scoped>

</style>