<template>
    <div class="overlay" @click.self="$emit('close')">
        <div class="select-list h-60">
            <div class="d-flex-row jc-between">
                <h5>카드사 선택</h5>
                <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
            </div>
            <ul class="content-list">
                <li v-for="(item, index) in items" :key="index">
                    <a @click="selected(item)">
                        <span>{{ item.name }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardCodeList",
    props: ['items'],
    methods: {
        selected(_item) {
            this.$emit('selected', _item)
        }
    }
}
</script>

<style scoped>

</style>