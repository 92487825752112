<template>
    <div class="all-content overflow-x-hidden bg-navy">
        <header class="main-header-wrap bg-navy">
            <button type="button" class="btn-user">
                <img class="profile" :src="item.path ? item.path : '/image/user.png'" alt="사용자 사진">
            </button>
            <button type="button" class="btn-search" @click="$router.push({name:'SearchHistory'})">
                <img src="/image/icon_search.svg" alt="찾기">
            </button>
            <button type="button" class="btn-alram" @click="$router.push({name:'NotificationList'})">
                <img :src="item.newNotification ? '/image/alram_on.svg' : '/image/alram_off.svg'" alt="알림 off">
            </button>
            <span class="span-name">{{ user.businessName }}</span>
            <p class="span-id" @click="$router.push({ name : 'MyPage' })">
                {{ user.account }}
                <i class="icon-arrow-right pl-5"></i>
            </p>
        </header>
        <div class="main-wrap-footer">
            <div class="main-wrap overflow-x-clip">
                <div class="mb-12 section-radius bg-white d-flex" @click="goPaster">
                    <img class="w-100" src="/image/main_banner.svg">
                </div>

                <section class="section-radius bg-pink mb-10" @click="goBanner">
                    <div class="d-flex-row" style="justify-content: space-between">
                        <div class="d-flex self-center ml-20" style="align-items: flex-start">
                            <span class="color-white mb-10 fz-13" style="font-weight: 300;">다양한 결제수단, 매출 관리와 정산</span>
                            <img src="/image/payster_logo.svg" alt="로고">
                        </div>
                        <div class="d-flex p-15 pl-10">
                            <img src="/image/icon_analysis_small.png">
                            <span class="color-white fz-13 d-flex-row items-center" style="font-weight: 300;">
                                무이자 할부 안내
                                <span class="color-white fz-10 pl-5"><i class="icon-arrow-right"></i></span>
                            </span>
                        </div>
                    </div>
                </section>

                <div class="d-flex-row w-100 mb-12">
                    <div class="section-radius bg-purple grid pay-con" @click="goCharge">
                        <img src="/image/ico_quick.svg" class="ml-13 pt-15">
                        <button class="fz-15">배달비 결제</button>
                    </div>
                    <div class="section-radius ml-10 bg-purple grid pay-con"
                         @click="showSelectRentType">
                        <img src="/image/ico_rent.svg" class="ml-13 pt-15">
                        <button class="fz-15">월세 결제</button>
                    </div>
                    <div class="section-radius bg-gray ml-10 grid pg-con"
                         @click="$router.push({ name: 'QuickPaymentHistory' })">
                        <img src="/image/ico_receipt.svg" class="ml-13">
                        <div>
                            <button class="fz-15">배달/월세<br>결제내역</button>
                            <img src="/image/icon-pghistory.svg" alt="화살표" style="margin-left: 4px; width: 22px; margin-top: 30px"/>
                        </div>
                    </div>
                </div>
                <div class="d-flex-row w-100">
                    <div class="section-radius bg-purple grid pay-con" @click="$router.push({ name: 'SMSPayment' })">
                        <img src="/image/icon-kakaopayment.svg" class="ml-13 pt-15">
                        <button class="fz-15">카톡 결제</button>
                    </div>
                    <div class="section-radius ml-10 bg-purple grid pay-con"
                         @click="$router.push({ name: 'HandPayment' })">
                        <img src="/image/icon-handpayment.svg" class="ml-13 pt-15">
                        <button class="fz-15">수기 결제</button>
                    </div>
                    <div class="section-radius bg-gray ml-10 grid pg-con"
                         @click="$router.push({ name: 'PGPaymentHistory' })">
                        <img src="/image/ico_receipt.svg" class="ml-13">
                        <div>
                            <button class="fz-15">카톡/수기<br>결제내역</button>
                            <img src="/image/icon-pghistory.svg" alt="화살표" style="margin-left: 4px; width: 22px; margin-top: 30px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <pin-check
            ref="PinCheck"
            :title="'PIN 확인'"
            :amount="0"
            :unit="''"
            :sub-amount="0"
            :sub-unit="''"
            :show-close="false"
        />

        <rent-type-select
            v-show="showRentType"
            :conditions="typeList"
            @selected="setSelectedType"
            @close="showRentType = false"
        />

        <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
            <input type="hidden" name="m" value="checkplusService">
            <input type="hidden" name="EncodeData" id="nice-enc">
        </form>

    </div>
</template>

<script>
import PinCheck from "@/components/common/PinCheck";
import RentTypeSelect from "@/components/main/RentTypeSelect";

export default {
    name: "Main",
    components: {
        RentTypeSelect
        , PinCheck
    },
    data() {
        return {
            item: {
                idx: null,
                businessName: '',
                account: '',
                path: '',
                pin: false,
            },

            typeList: [
                {
                    groupType: '0',
                    name: '월세',
                },
                {
                    groupType: '1',
                    name: '관리비',
                },
                {
                    groupType: '2',
                    name: '보증금',
                },
                {
                    groupType: '3',
                    name: '수리비',
                },
                // {
                //   groupType: '4',
                //   name: '교육비',
                // },
                {
                    groupType: '5',
                    name: '기타',
                },
            ],
            showRentType: false,
            nice: '',
            authedCI: true,
            historyLength: false,
            user: {
                businessName: '',
                account: '',
            }
        }
    },
    mounted() {
        if (localStorage.getItem('checkedCI') !== '1') {
            this.getCIRegistInfo()  // ci 등록여부 조회
        } else {
            this.getMainInfo()
        }
        this.getRentLength()
        this.getUser()

        if (this.$route.params.loadPage) {
            // this.$router.push({name: this.$route.params.loadPage, params: { linkIdx : this.$route.params.linkIdx }})
            let loadPage = this.$route.params.loadPage
            let linkIdx = this.$route.params.linkIdx
            if (loadPage === 'ChargePaymentHistoryDetail') { // 배달비(상세)
                this.$router.push({name: loadPage, params: {quickIdx: linkIdx}})
            } else if (loadPage === 'ChargeSalesHistoryDetail') {  // 월세(상세)
                this.$router.push({name: loadPage, params: {monthlyPaymentIdx: linkIdx}})
            } else if (loadPage === 'PaymentHistoryDetail') {  // 결제(상세)
                this.$router.push({name: loadPage, params: {idx: linkIdx}})
            } else if (loadPage === 'NoticeList') { // 공지사항
                this.$router.push({name: loadPage})
            } else if (loadPage === 'QnaList') { // QNA
                this.$router.push({name: loadPage, params: {idx: linkIdx}})
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener(this.$EVENT_CHECK_COMPLETED_AUTH, this.checkCompletedCI)
    },
    methods: {
        getRentLength() {
            this.historyLength = false
            this.$get(this.$LOG_MONTHLY, this.$options.name + '_getRentPaymentList', (result) => {
                console.log(result.data)
                console.log(result.data.length)
                this.historyLength = result.data.length > 0;
            }, (result) => {
                this.httpError(result.data)
            })
        },
        checkCompletedCI() {
            if (!this.authedCI && this.nice === '') {
                this.notify('error', '본인인증 후 이용가능합니다.', false)
                this.$router.replace({name: 'BeforeLogin'})
            }
        },
        getCIRegistInfo() {
            this.$get(this.$AUTH_CI, 'main_ci', (result) => {
                if (result.data) {
                    this.getMainInfo()

                } else { // ci 등록 안되어있음
                    this.$root.$refs.Alert.showAlert('',
                        '본인인증이 되어있지 않은 계정이라,<br/>본인인증이 필요합니다.',
                        () => {
                            window.addEventListener(this.$EVENT_CHECK_COMPLETED_AUTH, this.checkCompletedCI)

                            this.authedCI = false
                            this.niceAuth()
                        }, false, false)
                }
            }, (result) => {
                this.httpError(result.data)
            })
        },
        getMainInfo() {
            localStorage.setItem('checkedCI', '1')

            this.$get(this.$MAIN_INFO, 'main_info', (result) => {
                this.item = result.data
                console.log(result.data, 'main data')
                if (this.item.pin !== true) {
                    this.$router.push({name: 'SignUpPinRegist'})

                } else if (localStorage.getItem('firstPinChecked') !== '1') {
                    //로그인, 자동 로그인 후 첫 메인에서 핀체크 한번
                    this.$refs.PinCheck.showPin(() => {
                        localStorage.setItem('firstPinChecked', '1')
                        this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)

                        this.$parent.$data.readyMain = true // 메인화면 load완료 값 변경
                    })

                } else {
                    this.$parent.$data.readyMain = true // 메인화면 load완료 값 변경
                }

            }, (result) => {
                this.httpError(result.data)
            })
        },
        getUser() {
            this.$get(this.$USER, 'get_user', (result) => {
                this.user = result.data
            }, () => {
                // console.log(result)
            })
        },
        goPaster() {
            window.open('https://payster.co.kr')
        },
        goBanner() {
            this.$router.push({ name: 'NoticeList' })
        },
        showSelectRentType() {
            if (this.historyLength) {
                this.$router.push({name: 'RentBeforePayment'})
            } else {
                this.showRentType = true
            }
        },
        setSelectedType(_type) {
            this.showRentType = false

            this.$router.push({
                name: 'RentPayment', params: {
                    groupType: _type.groupType,
                    randomVal: Math.random().toString(),
                    type: '0', //등록
                }
            })
        },
        goCharge() {
            // 계좌정보 조회
            this.$get(this.$ACCOUNT_APPROVED, 'main_checkaccount', (result) => { // 계좌정보 있으면, 결제화면으로 이동
                if (result.data.length > 0) {
                    this.$router.push({name: 'Charge'})  // 결제
                } else {
                    this.notify('error', '배대사 등록 후 이용가능합니다.', false)
                    this.$router.push({name: 'DeliveryAgencyList'})
                }
            }, () => {
                this.notify('error', '배대사 등록 후 이용가능합니다.', false)
                this.$router.push({name: 'DeliveryAgencyList'})
            })
        },
        niceAuth() {
            this.$niceAuth((result) => {
                this.niceShow(result.enc_data)

            }, (result) => {
                console.log(result)
            })
        },
        niceShow(enc_data) {
            window.removeEventListener('message', this.niceDone)

            window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
            window.$('#nice-enc').val(enc_data)
            window.$('#nice-form').attr('target', 'popupChk')
            window.$('#nice-form').submit()

            window.addEventListener('message', this.niceDone);
        },
        niceDone(e) {
            if (e.origin === 'https://nice.service.payster.co.kr') {
                this.nice = e.data.payload

                window.removeEventListener('message', this.niceDone)

                this.niceCheck()
            }
        },
        niceCheck() {
            let formData = new FormData()
            formData.append('auth', this.nice)

            this.$post(this.$AUTH_CI, 'main_authci', formData, true, () => {
                this.authedCI = true
                this.getMainInfo()

            }, (result) => {
                this.httpError(result.data)
                this.$router.replace({name: 'BeforeLogin'})
            })
        },
    }
}

</script>

<style scoped>

/*.bg-image {*/
/*  background-image: url("/image/main_banner.png");*/
/*}*/

.dream-button {
    margin-top: 30px;
    padding: 5px 12px;
    background: linear-gradient(264.9deg, #7B61FF -9.03%, #007FCD 112.17%);
    color: white;
    border-radius: 20px;
    font-size: 12px;
    line-height: 21px;
}

.grid {
    height: calc((100vw - 60px) / 3) !important;
}

.bg-img {
    background-size: contain;
    background-repeat: no-repeat;
}

.menu-ratio {
    aspect-ratio: 1;
}


</style>
