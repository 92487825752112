<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i>
      </button>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <div class="charge-form mb-50">
          <em class="pink-title">결제 정보를<br>입력해주세요</em>

          <p class="mb-10 mt-30 fz-18">납입타입</p>

          <div class="d-flex-row mb-10"
            :class="{ 'mb-30' : paymentType === 'N'}"
          >
            <div class="radios-circle">
              <input type="radio" id="normalPayment" :checked="paymentType === 'N'"
                     @click="selectedPaymentType('N')">
              <label for="normalPayment">일반결제</label>
            </div>

            <!-- 수리비(3)는 일반결제만 -->
            <div class="radios-circle ml-20" v-if="groupType !== '3'">
              <input type="radio" id="regularPayment" :checked="paymentType === 'R'"
                     @click="selectedPaymentType('R')">
              <label for="regularPayment">정기결제</label>
            </div>
          </div>
          <em class="color-gray" v-if="paymentType === 'R'">정기결제의 경우 최초 1회 회원 직접 결제를 진행합니다. 이후 납입 예정일 D-5일전에 자동 결제됩니다.</em>

          <div>
            <p class="mb-10 mt-20 fz-18"
            >납입예정일을 선택해주세요</p>
            <div class="select-wrap">
              <button class="select" v-if="selectedType === ''" @click="openPaymentType()">
                납입일 방식을 선택하세요.
              </button>
              <button class="select" v-else-if="selectedType === 'default'" @click="openPaymentType()">
                D+5 (영업일 기준)
              </button>
              <button class="select" v-else @click="openPaymentType()">
                사용자 선택
              </button>
            </div>
            <div class="select mb-5" v-if="selectedType !== ''">
              <div class="select" v-if="selectedType === 'default'">
                <p class="input-box h-100 border-zero d-flex-row items-center" style="justify-content: center">{{ payDate }}</p>
              </div>
              <date-picker class="fz-16 t-c h-100 w-100 br-5"
                           v-else
                           v-model="payDate"
                           :config="this.rentCalendarOptions1"
                           @input="selectedDatePicker(0)"
                           :disabled="selectedType === 'default'"
                           readonly/>
            </div>
            <em class="color-gray" v-if="selectedType === ''">납입 예정일은 방식에 따라 날짜 설정이 달라집니다.</em>
            <em class="color-gray" v-else-if="selectedType === 'default'">· 납입예정일은 영업일 기준 D+5 고정입니다.<br>· 공휴일 선택 시 공휴일이 끝난 영업일에 납입이 됩니다.</em>
            <em class="color-gray" v-else>· 납입예정일은 영업일 기준 D+6 이후 선택이 가능합니다.<br>· 공휴일 선택 시 공휴일이 끝난 영업일에 납입이 됩니다.</em>
            <div v-if="paymentType === 'R' && selectedType !== ''">
              <div class="count_month">
                <button class="count_month_btn" style="border-radius: 5px 0 0 5px;" :class="{ 'disabled_count' : monthCount === 2 }" @click="setMonthCount(false)">－</button>
                <div>
                  <input type="number" v-model="monthCount" style="width: 30px; text-align: right;">
                  <span>개월</span>
                </div>
                <button class="count_month_btn" style="border-radius: 0 5px 5px 0;" :class="{ 'disabled_count' : monthCount === 24 }" @click="setMonthCount(true)">＋</button>
              </div>
              <em class="color-gray">납입 기간을 설정하세요.<br>선택기간 : {{ startDate }} ~ {{ endDate }}</em>
            </div>
          </div>

          <p class="mb-20 mt-30 fz-18">납입상세<span class="color-red"> *</span></p>
          <div class="d-flex-row w-100">
            <p class="fz-16 w-22 self-center">납입금액</p>
            <div class="select w-78">
              <p class="input-box h-100 border-zero d-flex-row items-center right" style="justify-content: right">{{ paymentAmount01 | numberWithCommas }}원</p>
            </div>
          </div>
          <span class="color-red">{{ errors.paymentAmount01 }}</span>

          <div class="d-flex-row w-100 mt-10">
            <p class="fz-16 w-22 self-center">수수료</p>
            <div class="select-disable w-78">
              <div class="input-box h-100 border-zero d-flex-row items-center right" style="justify-content: space-between;">
                <span class="color-gray-B8B8B8 fz-16">{{ displayFee }}</span>
                <span class="fz-16">{{ combinedTaxAmount | numberWithCommas }}원</span>
              </div>
            </div>
          </div>

          <div class="d-flex-row w-100 mt-10">
            <p class="fz-16 w-22 self-center">결제금액</p>
            <div class="select-disable w-78">
              <p class="input-box h-100 border-zero d-flex-row items-center right" style="justify-content: right">{{ paymentAmount02 | numberWithCommas }}원</p>
            </div>
          </div>

          <p class="mb-10 mt-30 fz-18">카드선택<span class="color-red"> *</span></p>

          <div class="select-wrap">
            <button class="select" v-if="selectedCard !== null" @click="openCardList()">
              {{ selectedCard.name + ' ' }}{{ selectedCard.number | cardNumberMasking }}
            </button>
            <button class="select color-gray" v-else-if="cardList.length > 0" @click="openCardList()">카드선택
            </button>
            <button class="select color-blue-5D8BE1" v-else @click="openCardList()">+ 카드등록 바로가기</button>
          </div>

          <div v-if="paymentAmount02 >= 50000">
            <p class="mb-10 mt-30 fz-16 color-darkgray weight-light">할부기간을 선택해주세요</p>
            <div class="select-wrap">
              <button class="select" @click="openInstallmentPeriod()">
                {{ selectedInstallment.name }}
              </button>
            </div>
            <button class="fz-16 mt-10 color-blue-5D8BE1 ml-auto display-block" @click="goInstallmentPlan">
              카드사별 무이자 혜택 >
            </button>
          </div>

          <div class="payment-sign-box mt-40">
            <div class="checks-box">
              <div class="checks-circle">
                <input type="checkbox" id="autoLogin" @click="agree = !agree" v-model="agree">
                <label for="autoLogin">약관 전체 동의</label>
              </div>
            </div>
            <div class="regist-box">
              <div class="checks" @click="goTerm(term.idx)" v-for="(term, index) in termList"
                   :key="index">
                <input type="checkbox" v-bind:id="'chk' + (index + 1)" disabled v-model="agree">
                <label :for="'chk' + (index + 1)" class="color-gray-B8B8B8 fz-15">
                  (필수) {{ term.name }}
                </label>
                <button type="button" class="btn"><i class="icon-arrow-right color-gray-B8B8B8"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <em class="color-gray-B8B8B8 fz-15">결제 후 납입 예정일에 자동 납부 처리됩니다.</em>
      </div>
      <button id="applyPayBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="applyPayment()">결제</button>
    </div>

    <select-payment-type
        v-show="showSelectedType"
        @selected="setSelectedType"
        @close="showSelectedType=false"/>

    <charge-card-select
        v-show="showCardList"
        :cards="cardList"
        @selected="setSelectedCard"
        @close="showCardList=false"/>

    <installment-period-select
        v-show="showInstallmentList"
        :periods="installmentList"
        @selected="setInstallmentPeriod"
        @close="showInstallmentList=false"/>

  </div>
</template>

<script>
import ChargeCardSelect from "@/components/charge/ChargeCardSelect";
import InstallmentPeriodSelect from "@/components/payment/rentPayment/InstallmentPeriodSelect";
import moment from "moment";
import EventBus from "@/main";
import SelectPaymentType from "@/components/payment/rentPayment/SelectPaymentType.vue";

export default {
  name: "RentPaymentInfoInput",
  components: {
    SelectPaymentType,
    InstallmentPeriodSelect,
    // RPInputBorder,
    ChargeCardSelect,

  },
  data() {
    return {
      groupType: '',  // 0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타

      zipCode: '',
      address: '',
      addressDetail: '',

      lessor: '', // 임대인 이름
      lessorBankCode: '', // 은행코드
      lessorBankName: '', // 은행
      lessorAccount: '',  // 계좌
      mid: '',  // 계좌 인증 후 생성되는 고유 코드

      depositor: '',  // 예금주
      rentName: '', // 월세명

      files: [],  // 첨부파일

      rentCalendarOptions1: {},
      rentCalendarOptions2: {},

      paymentType: 'N', // N: 일반결제, R: 정기결제

      // 일반결제
      payDate: '',  // 납입예정일

      // 정기결제
      paymentPeriodType: 0,

      minDate: '',
      minPlusOneDate: '',
      startDate: '',
      endDate: '',

      // 납입상세
      paymentAmount01: '',  // 납입금액
      fee: '',  // 수수료
      tax: '', // 부가세
      combinedTax: '', // 합산세율(수수료률 + 부가세률)
      // feeAmount: '',  // 수수료 금액
      combinedTaxAmount: '',  // 합산세율 금액
      displayFee: '',
      placeHolderFee: '',
      paymentAmount02: '',  // 결제금액

      cardList: [],
      selectedCard: null, // 결제카드
      showCardList: false,
      selectedType: '',
      showSelectedType: false,
      monthCount: 6,
      showInstallmentList: false, // 할부기간
      installmentList: [
        {
          'installment': '00',
          'name': '일시불'
        },
        {
          'installment': '02',
          'name': '2개월'
        },
        {
          'installment': '03',
          'name': '3개월'
        },
        {
          'installment': '04',
          'name': '4개월'
        },
        {
          'installment': '05',
          'name': '5개월'
        },
        {
          'installment': '06',
          'name': '6개월'
        },
        {
          'installment': '07',
          'name': '7개월'
        },
        {
          'installment': '08',
          'name': '8개월'
        },
        {
          'installment': '09',
          'name': '9개월'
        },
        {
          'installment': '10',
          'name': '10개월'
        },
        {
          'installment': '11',
          'name': '11개월'
        },
        {
          'installment': '12',
          'name': '12개월'
        },
      ],
      selectedInstallment: null,

      agree: false,   // 약관동의

      errors: {
        period: '', // 기간
        paymentAmount01: '',  // 납입금액
        // fee: '',  // 수수료
        combinedTax: '',  // 합산세율
        paymentAmount02: '',  // 결제금액
        selectedCard: '', // 결제카드
        agree: '',  // 약관동의
      },

      type: this.$route.params.type, //0: 등록, 1:수정, 2:재심사 3:재결제
      usePurpose: '',

      //수정시 사용
      deleteIdxs: [],
      idx: this.$route.params.idx,

      termList: []
    }
  },
  mounted() {
    console.log('mounted')
    this.getForm()
    this.getTermList()
    console.log(this.$route.params)
    EventBus.$on(this.$EVENT_REFRESH_CARDINFO, function () {
      this.getCardList()  // 카드 정보 가져오기
    }.bind(this))
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'RentPayment') {
        vm.clearData()
      }
    })
  },
  methods: {
    clearData() {
      console.log('clear')
      if (this.$route.params.isClear) {
        this.type = this.$route.params.type
        this.groupType = ''
        this.zipCode = ''
        this.address = ''
        this.addressDetail = ''
        this.lessor = ''
        this.lessorBankCode = ''
        this.lessorBankName = ''
        this.lessorAccount = ''
        this.depositor = ''
        this.rentName = ''
        this.usePurpose = ''
        this.files = []
        this.selectedInstallment = null
        this.showInstallmentList = false
        this.selectedType = null
        this.showSelectedType = false
        this.selectedCard = null
        this.showCardList = false
        this.paymentType = 'N'
        this.payDate = ''
        this.paymentPeriodType = 0
        this.startDate = ''
        this.endDate = ''
        this.rentCalendarOptions1 = {}
        this.rentCalendarOptions2 = {}
        this.paymentAmount01 = ''
        this.cardList = []
        this.fee = ''
        this.tax = ''
        // this.feeAmount = ''
        this.combinedTaxAmount = ''
        this.displayFee = ''
        this.placeHolderFee = ''
        this.paymentAmount02 = ''
        this.agree = false
        this.idx = this.$route.params.idx
        this.deleteIdxs = []
      }

      this.getForm()
    },
    getForm() {
      this.$get(this.$MONTHLY + '/' + this.idx, 'rentPayment_monthly', (result) => {
        console.log(result.data, 'monthly', this.idx)
        this.fee = Number(result.data.fee)
        this.tax = Number(result.data.tax)
        this.cardList = result.data.cardList
        this.bankList = result.data.bankList
        this.paymentAmount01 = this.$route.params.amount
        this.combinedTax = (this.fee * 1) + (this.tax * 1)
        this.placeHolderFee = '(' + this.combinedTax + '%)'
        this.minDate = new Date(result.data.minDate)
        this.minPlusOneDate = new Date(result.data.minPlusOneDate)

        this.selectedInstallment = this.installmentList[0]
      }, (result) => {
        this.httpError(result.data)
      })
    },
    getTermList() {
      this.$get(this.$TERMS, 'rentpaymentinfoinput_terms', (result) => {
        this.termList = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goTerm(_index) {
      this.$router.push({name: 'RentTerm', params: {idx: _index}})
    },
    getCardList() {
      if (this.$route.params.idx) {
        this.$get(this.$MONTHLY + '/' + this.$route.params.idx, 'rentPayment_monthly', (result) => {
          this.cardList = result.data.cardList

        }, (result) => {
          this.httpError(result.data)
        })
      } else {
        this.$get(this.$MONTHLY, 'rentPayment_monthly', (result) => {
          this.cardList = result.data.cardList
          console.log('test cardList', this.cardList.length)

        }, (result) => {
          this.httpError(result.data)
        })
      }
    },
    getMinDate() {
      let minDate
      if(this.selectedType === 'default') {
          minDate = this.minDate
      } else {
          minDate = this.minPlusOneDate
      }
      console.log(minDate)
      let year = minDate.getFullYear()
      let month = minDate.getMonth()
      let day = minDate.getDate()
      this.initCalendarOptions(minDate)
      let minMonth = month + 1
      let _month = minMonth < 10 ? '0' + minMonth : minMonth
      let minDay = day
      let _day = minDay < 10 ? '0' + minDay : minDay
      let _minDate = year + '-' + _month + '-' + _day
      this.payDate = _minDate
      console.log(this.payDate, 'this.paydate')
      if(this.paymentType === 'R') {
        let setMonth = moment(_minDate).add(this.monthCount-1, 'months').calendar()
        this.startDate = year + '.' + _month
        this.endDate = setMonth.slice(0, 7)
        console.log(this.startDate)
        console.log(this.endDate)
      }
    },
    initCalendarOptions(_minDate) {
      this.rentCalendarOptions1 = {
        format: 'YYYY-MM-DD',
        locale: moment.locale('ko'),
        minDate: _minDate,
        icons: {
          time: 'glyphicon glyphicon-time',
          date: 'glyphicon glyphicon-calendar',
          up: 'icon-arrow-up',
          down: 'icon-arrow-down',
          previous: 'icon-arrow-left',
          next: 'icon-arrow-right',
          today: 'glyphicon glyphicon-screenshot',
          clear: 'glyphicon glyphicon-trash',
          close: 'glyphicon glyphicon-remove'
        },
        ignoreReadonly: true,
      }
    },
    errorClear() {
      this.errors = {
        period: '', // 기간
        paymentAmount01: '',  // 납입금액
        // fee: '',  // 수수료
        combinedTax: '',  // 합산세율
        paymentAmount02: '',  // 결제금액
        selectedCard: '', // 결제카드
        agree: '',  // 약관동의
      }
    },
    selectedPaymentType(_type) {
      this.paymentType = _type
      // console.log(this.paymentType)
    },
    selectedPeriod(_type) {
      this.paymentPeriodType = _type
      let year = this.startDate.substr(0, 4)
      let month = this.startDate.substr(5, 2)
      switch (_type) {
        case 0: // 직접입력
          window.document.getElementById("startDatePicker").disabled = false
          window.document.getElementById("endDatePicker").disabled = false
          if (Number(month)+1 === 12) {
            this.endDate = ((year * 1) + 1) + '-' + '01'
          } else {
            this.endDate = year + '-' + (Number(month)+1 > 9 ? '' : '0') + (Number(month)+1)
          }
          console.log(this.endDate, 'end')
          break

        case 1: // 1년 계약
          // 종료일을 startdate에서 1년 이후로 설정
          window.document.getElementById("startDatePicker").disabled = true
          window.document.getElementById("endDatePicker").disabled = true
          if (month === '01') {
            this.endDate = year + '-' + '12'
          } else {
            this.endDate = ((year * 1) + 1) + '-' + (month - 1)
          }
          break

        case 2: // 2년 계약
          // 종료일을 startdate에서 2년 이후로 설정
          window.document.getElementById("startDatePicker").disabled = true
          window.document.getElementById("endDatePicker").disabled = true
          if (month === '01') {
            this.endDate = ((year * 1) + 1) + '-' + '12'
          } else {
            this.endDate = ((year * 1) + 2) + '-' + (month - 1)
          }
          break
      }
    },
    selectedDatePicker(_type) { // 예정일, 정기결제 시작일, 정기결제 종료일
      switch (_type) {
        case 0: // 예정일

          break

        case 1:

          break

        case 2:

          break
      }
      // this.year = this.selectedDate.split('.')[0]
      // this.month = this.selectedDate.split('.')[1]
      // this.day = this.selectedDate.split('.')[2]
    },
    calculatePayAmount() {
      if (this.paymentAmount01 !== '') {
        this.combinedTaxAmount = Math.floor(this.paymentAmount01 * (this.combinedTax / 100))
        this.displayFee = "(" + this.combinedTax + "%) "
        this.paymentAmount02 = (this.paymentAmount01 * 1) + (this.combinedTaxAmount * 1)

      } else {
        this.combinedTaxAmount = ''
        this.displayFee = ''
        this.paymentAmount02 = ''
      }

      if (this.paymentAmount02 === '' || this.paymentAmount02 < 50000) {
        this.selectedInstallment = this.installmentList[0]
      }
    },
    openCardList() {
      this.showCardList = true
    },
    setSelectedCard(_card) {
      this.selectedCard = _card
      this.showCardList = false

      this.changeNextBtn()
    },
    openInstallmentPeriod() {
      this.showInstallmentList = true
    },
    setInstallmentPeriod(_period) {
      this.selectedInstallment = _period
      this.showInstallmentList = false
    },
    openPaymentType() {
      this.showSelectedType = true
    },
    setSelectedType(_type) {
      this.selectedType = _type
      this.showSelectedType = false
    },
    setMonthCount(_count){
      if(_count && this.monthCount < 24){
        this.monthCount++
      } else if(!_count && this.monthCount > 2){
        this.monthCount--
      }
    },
    goInstallmentPlan() {
      this.$router.push({name: 'CardInstallmentPlan'})
    },
    applyPayment() {
      this.errorClear()
      this.type = String(this.type)
      if (!this.type) {
        this.notify('error', '오류가 발생 했습니다. 처음부터 다시 진행 해주세요.')
        this.$router.back()
        return
      }
      console.log(this.payDate, '123123')
      // 필수값 체크
      if (this.paymentAmount01 === '') { // 납입금액
        this.notify('error', '납입금액을 입력해주세요')
        return
      }

      if (this.paymentAmount01 * 1 < 10000) { // 납입금액
        this.notify('error', '납입금액을 10,000원 이상 입력해주세요')
        return
      }

      if (this.selectedCard === null) {
        this.notify('error', '신용카드를 선택해주세요')
        return
      }

      if (!this.agree) {
        this.notify('error', '약관에 동의해주세요')
        return
      }

      let formData = new FormData()

      //type : 0:등록, 1:수정, 2:재심사, 3:재결제
      formData.append('type', this.type)
      formData.append('idx', this.idx)
      formData.append('paymentType', this.paymentType)
      if (this.paymentType === 'R') {
        formData.append('startDate', this.startDate.slice(0,4)+'-'+this.startDate.slice(5))
        formData.append('endDate', this.endDate.slice(0,4)+'-'+this.endDate.slice(5))
        formData.append('payDate', this.payDate.slice(8))
      } else {
        formData.append('payDate', this.payDate)
      }
      // formData.append('amount', this.paymentAmount01)
      formData.append('fee', this.fee)
      formData.append('tax', this.tax)
      formData.append('cardIdx', this.selectedCard.idx)
      formData.append('installment', this.selectedInstallment.installment)


      this.$post(this.$MONTHLY_STEPTWO, 'rentpaymentinfoinput_gonext', formData, true, (result) => {
        console.log(result, '등록완료')
        this.$router.replace({
          name: 'RentPaymentSuccess', params: {
            date: result.data.date,
            amount: result.data.amount,
            p_date: result.data.p_date,
          }
        })
      }, (result) => {
        this.httpError(result.data)
      })

      // this.$router.replace({name: 'RentPaymentSuccess'})  // 납입일, 납입금액
    },
    changeNextBtn() {
      if (!this.type || !this.groupType || this.paymentAmount01 === '' || this.selectedCard === null || !this.agree) {
        window.document.getElementById("applyPayBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      } else {
        window.document.getElementById("applyPayBtn").className = "align-bottom btn btn-m btn-pink"
      }
    },
    setSelectedDate(){
      let date = new Date(this.payDate)
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      let dateMonth = month + 1
      let _month = dateMonth < 10 ? '0' + dateMonth : dateMonth
      let dateDay = day
      let _day = dateDay < 10 ? '0' + dateDay : dateDay
      let _dateDate = year + '-' + _month + '-' + _day
      if(this.paymentType === 'R') {
        let setMonth = moment(_dateDate).add(this.monthCount-1, 'months').calendar()
        this.startDate = year + '.' + _month
        this.endDate = setMonth.slice(0, 7)
      }
    }
  }
  ,
  watch: {
    payDate(){
      this.setSelectedDate()
    },
    selectedType(){
      console.log(this.selectedType)
      this.getMinDate()
    },
    monthCount(){
      if(this.monthCount < 2 && this.monthCount !== ''){
        this.monthCount = 2
      } else if(this.monthCount > 24 && this.monthCount !== '') {
        this.monthCount = 24
      }
      this.setSelectedDate()
    },
    paymentAmount01() {
      this.calculatePayAmount()
      this.changeNextBtn()
    }
    ,
    agree() {
      this.changeNextBtn()
    }
  }
}
</script>

<style scoped>
.count_month {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  margin-top: 20px;
  margin-bottom: 5px;
}
.count_month_btn {
  width: 50px;
  height: 50px;
  background: #2358a0;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
.disabled_count {
  background: #858585;
}
</style>
