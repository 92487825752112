<template>
    <div class="all-content bg-white">
        <header class="header-wrap">
            <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
            <p>아이디 찾기</p>
        </header>
        <div class="content-wrap">
            <div class="main-wrap pb-80 d-flex h-100">
                <div class="find-id-form">
                    <em class="pink-title">본인인증</em>
                    <p class="mt-10">
                        <em class="color-darkgray-4F4F4F fz-16">아이디 찾기를 위해<br/>본인 인증을 해주세요.</em>
                    </p>
                </div>
            </div>
            <button class="align-bottom btn btn-m btn-pink" @click="niceAuth()">본인인증</button>
        </div>
        <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
            <input type="hidden" name="m" value="checkplusService">
            <input type="hidden" name="EncodeData" id="nice-enc">
        </form>
    </div>
</template>

<script>
export default {
    name: "FindId",
    data() {
        return {
            nice: '',
            phone: '',
        }
    },
    methods: {
        niceAuth() {
            this.$niceAuth((result) => {
                this.niceShow(result.enc_data)

            }, (result) => {
                console.log(result)
            })
        },
        niceShow(enc_data) {
            window.removeEventListener('message', this.niceDone)

            window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
            window.$('#nice-enc').val(enc_data)
            window.$('#nice-form').attr('target', 'popupChk')
            window.$('#nice-form').submit()

            window.addEventListener('message', this.niceDone);
        },
        niceDone(e) {
            if (e.origin === 'https://nice.service.payster.co.kr') {
                this.nice = e.data.payload

                window.removeEventListener('message', this.niceDone)

                this.niceCheck()
            }
        },
        niceCheck() {
            let formData = new FormData()
            formData.append('auth', this.nice)

            this.$post(this.$AUTH_FINDID, 'findid_findid', formData, true, (result) => {
                let idList = result.data
                this.$router.replace({name: 'FindIdSuccess', params: {idList: idList}})

            }, (result) => {
                this.httpError(result.data)
                this.$router.replace({name: 'FindIdFail'})
            })
        },
    }
}
</script>

<style scoped>
.find-id-form {
    text-align: center;
    margin: auto;
    padding-bottom: 40px;
    padding-top: 20px;
}

</style>