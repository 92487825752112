<template>
  <div class="all-content">
    <div class="content-wrap bg-white content-row t-0" style="height: calc(100% - 52px);">
      <div v-if="items" class="confirm-list">
        <p>
          <img src="/image/wb_logo.png" alt="캐릭터">
          <span class="mt-20 color-black">{{ `'${ paymentName }'` }} 납부를<br>중단하시겠습니까?</span>
        </p>
        <ul class="price-list">
          <li>
            <strong>결제일</strong>
            <em v-if="paymentType === 'R'">매월 {{ items.paymentDay }}일</em>
            <em v-else>{{ items.paymentDay | moment('YYYY.MM.DD') }}</em>
          </li>
          <li v-if="items.currentPaymentCount !== null">
            <strong>결제횟수</strong>
            <em>{{ `${ items.currentPaymentCount }회/` }}{{ `${ items.totalPaymentCount }회` }}</em>
          </li>
          <li>
            <strong>결제금액</strong>
            <em>{{ items.amount | numberWithCommas }} 원</em>
          </li>
          <li></li>
        </ul>
      </div>
    </div>
    <div class="d-flex-row">
      <button class="btn-gray mr-auto btn-half" @click="$router.replace({ name : 'ChargeSalesHistoryDetail', params : { monthlyPaymentIdx: idx }})">취소</button>
      <button class="btn-pink ml-auto btn-half" @click="stopPaying">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "StopPaying",
  data() {
    return {
      idx: 0,
      paymentName : '',
      items: '',
      paymentType: '',
      type: '',
    }
  },
  mounted() {
    this.type = this.$route.params.type // 0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타
    this.paymentType = this.$route.params.paymentType // N: 일반, R: 정기
    this.idx = this.$route.params.monthlyPaymentIdx

    if (this.$route.params.oldRentName) {
      this.paymentName = this.$route.params.oldRentName
    } else {
      switch (this.type){
        case '0':
          this.paymentName = '월세'
          break

        case '1':
          this.paymentName = '관리비'
          break

        case '2':
          this.paymentName = '보증금'
          break

        case '3':
          this.paymentName = '수리비'
          break

        case '5':
          this.paymentName = '기타'
          break
      }
    }

    this.getPaymentInfo()
  },
  methods: {
    stopPaying(){
      let formData = new FormData()
      formData.append('monthlyPaymentIdx', this.idx)

      this.$put(this.$MONTHLY_STOP_PAYMENT.replace('?',this.idx), this.$options.name + '_stopPaying', formData, true,() => {
        this.notify('success', '납부가 중단되었습니다.', false)
        this.$router.replace({name: 'RentPaymentHistory'})

      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({ name : 'ChargeSalesHistoryDetail', params : { monthlyPaymentIdx: this.idx }})
      })
    },
    getPaymentInfo() {
      this.$get(this.$MONTHLY_STOP_PAYMENT_CHECK.replace('?',this.idx), this.$options.name + '_getPaymentInfo',(result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({ name : 'ChargeSalesHistoryDetail', params : { monthlyPaymentIdx: this.idx }})
      })
    },
  }
}
</script>

<style scoped>

</style>