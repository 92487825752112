<template>
  <div class="all-content">
    <header class="header-wrap">
    </header>
    <div class="content-wrap bg-white content-row pb-80">
      <div class="confirm-list">
        <p>
          <img src="/image/wb_logo.png" alt="캐릭터">
          <span>
            {{ payAmount | numberWithCommas }}
            <i> PAY</i>
            <br/>
            결제완료
          </span>
          <em>결제가 완료되었습니다.</em>
        </p>
        <ul class="price-list">
          <li>
          </li>
          <li>
            <strong>결제일시</strong>
<!--            <em>{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</em>-->
            <em>{{ paymentInfo.localDateTime | moment('YYYY.MM.DD HH:mm:ss') }}</em>
          </li>
          <li class="b-none">
          </li>
        </ul>
      </div>
    </div>
    <div class="align-bottom d-flex-row w-100">
      <button class="btn-dgray-4D4D4D mr-auto btn-half" @click="printReceipt">영수증 확인</button>
      <button class="btn-pink ml-auto btn-half" @click="$router.go(-2)">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QRPaymentSuccess",
  data() {
    return {
      payAmount: 0,
      paymentInfo: {
        shopData: {
          shopName: "",
          business: "",
          address: "",
          addressDetail: "",
          name: "",
          shopHp: "",
          goodsName: ""
        },
        qrPayment: {
          idx: '',
          qr: "",
          userIdx: '',
          reappayTid: "",
          state: ""
        },
        localDateTime: "",
        idx:"",
      },
      timeout : null,
    }
  },
  mounted() {
    //영수증 데이터 받아올것
    this.payAmount = this.$route.params.payAmount
    this.paymentInfo = JSON.parse(this.$route.params.paymentInfo)

    window.addEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
    window.addEventListener(this.$EVENT_PRINT, this.printResult)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
    window.removeEventListener(this.$EVENT_PRINT, this.printResult)

    clearTimeout(this.timeout)
  },
  methods: {
    printReceipt() {
      this.$router.push({ name : 'PaymentHistoryDetail', params : { idx: this.paymentInfo.idx, confirmReceipt: true }})
      //디바이스 연결 상태 요청
      // window.requestConnectDevice()
    },
    connectResult(e) {
      let result = e.detail
      if (result === '1') {
        this.timeout = setTimeout(() => {
          // let date = this.paymentInfo.localDateTime.replace('T', ' ')
          let createdAt = this.paymentInfo.localDateTime.split('T')
          let date = createdAt[0].replaceAll('-', '.')
          let time = createdAt[1].split('.')[0]
          let convertedDate = date + ' ' + time

          //인쇄
          window.addPrintBoldString('영수증\n')
          window.addPrintString('상호명 : ' + this.paymentInfo.shopData.shopName + '\n')
          window.addPrintString('대표자 : ' + this.paymentInfo.shopData.name + '\n')
          window.addPrintString('사업자번호 : ' + this.paymentInfo.shopData.business + '\n')
          window.addPrintString('전화번호 : ' + this.paymentInfo.shopData.shopHp + '\n')
          window.addPrintString('주소 : ' + this.paymentInfo.shopData.address + ' ' + this.paymentInfo.shopData.addressDetail + '\n')
          window.addPrintString('거래일시 : ' + convertedDate + '\n')
          window.addPrintString('TID : ' + this.paymentInfo.qrPayment.reappayTid + '\n')
          window.addPrintBoldString('거래금액 : ' + this.$options.filters.numberWithCommas(this.payAmount) + ' PAY\n')
          window.addPrintString('감사합니다.\n')

          window.requestPrintStart()

        }, 1000)
      } else {
        this.notify('error', '기기 연결에 실패했습니다.', false)
      }
    },
    printResult(e) {
      let result = e.detail
      if (result === '1') {
        console.log('인쇄에 성공했습니다.')
      } else {
        console.log('인쇄에 실패했습니다.')
      }
    }
  }
}
</script>

<style scoped>

</style>
