<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <div class="input-box search-input" style="width: calc(100% - 70px)">
        <input type="text" placeholder="주문번호로 검색하세요" v-model="searchTxt"
               style="background: transparent; width: calc(100% - 55px)"/>
        <button v-show="searchTxt !== ''" type="button" class="close-circle-search" @click="deleteAll()"></button>
        <button type="button" style="position: absolute; top:20px; right: 55px; " @click="search"><img
            src="/image/search.svg" alt="찾기">
        </button>
      </div>
      <button type="button" class="btn-close" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="content-wrap">
      <div class="list-none-wrap" v-if="paymentData === null">
        <div class="list-none">
          <p class="list-none-title-search t-c" v-if="isSearched && searchTxt !== ''">검색결과가 없습니다.</p>
          <p class="list-none-title-search t-c" v-else>주문번호 검색으로<br>결제내역을 확인 할 수 있습니다.</p>
        </div>
      </div>

      <div class="main-wrap pt-0" v-else-if="isSearched && paymentData !== null">
        <section class="p-section border-bottom" @click="goHistoryDetail()">
          <div class="white-box-br-10">
            <div class="p-box">
              <div class="search-history">
                <div class="d-flex-row jc-between">
                  <span class="fz-15 color-pink fwb">{{ paymentData.type }}</span>
                  <span class="fz-13">{{ paymentData.date }}</span>
                </div>
                <div class="mt-10">
                  <em class="fz-15">{{ paymentData.productName }}</em>
                </div>
                <div class="d-flex-row jc-between mt-5 mb-10">
                  <span>{{ paymentData.hp }}</span>
                  <em class="fz-18" v-if="paymentData.paymentType === '3'">{{ paymentData.amount | numberWithCommas }}</em>
                  <em class="fz-18" v-else>{{ paymentData.amount | numberWithCommas }}</em>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchHistory",
  data() {
    return {
      isSearched: false,
      searchTxt: '',

      paymentData: null
    }
  },
  methods: {
    deleteAll() {
      this.searchTxt = ''
      this.isSearched = false
      this.paymentData = null
    },
    search() {
      if (this.searchTxt === '') {
        this.notify('error', '검색하실 주문번호를 입력해주세요')
        return
      }

      this.isSearched = false

      // search api 연동
      this.$get(this.$SEARCH + '?orderCode=' + this.searchTxt, 'searchHistory_search', (result) => {
        this.paymentData = result.data
        this.isSearched = true

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goHistoryDetail() { // 결제내역 상세로 이동
      this.$router.replace({name: 'PaymentHistoryDetail', params: { idx: this.paymentData.idx }})
    }
  },
}
</script>

<style scoped>

</style>