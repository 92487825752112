<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list h-90">
      <ul class="content-list-none-title bank_list">
        <li v-for="(bank, index) in bankList" :key="index" class="bank-item">
          <a role="button" @click="selected(bank)">
            <div class="thmb">
              <img :src="bank.code | bankImage" :alt="bank.name">
            </div>
            <span class="fz-16">{{ bank.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "BankSelect",
  props: ['bankList'],
  methods: {
    selected(_bank) {
      this.$emit('selected', _bank)
    }
  },
  filters: {
    bankImage: (value) => {
      if (value === '037') { // 전북은행
        return 'image/bank_jb.svg'
      } else if (value === '020') {  // 우리은행
        return '/image/bank_woori.svg'
      } else if (value === '002') {  // 산업은행
        return '/image/bank_kdb.svg'
      } else if (value === '088') {  // 신한은행
        return '/image/bank_shingan.svg'
      } else if (value === '007') {  // 수협
        return '/image/bank_sh.svg'
      } else if (value === '023') {  // SC 제일은행
        return '/image/bank_sc.svg'
      } else if (value === '035') {  // 제주은행
        return '/image/bank_shingan.svg'
      } else if (value === '048') {  // 신협은행
        return '/image/bank_cu.svg'
      } else if (value === '027') {  // 시티은행
        return '/image/bank_citi.svg'
      } else if (value === '004') {  // 국민은행
        return '/image/bank_kb.svg'
      } else if (value === '031') {  // 대구은행
        return '/image/bank_dgb.svg'
      } else if (value === '032') {  // 부산은행
        return '/image/bank_bnk.svg'
      } else if (value === '034'){  // 광주은행
        return 'image/bank_jb.svg'
      } else if (value === '039') {  // 경남은행
        return '/image/bank_bnk.svg'
      } else if (value === '011') {  // 농협
        return '/image/bank_nh.svg'
      } else if (value === '003') {  // 기업은행
        return '/image/bank_ibk.svg'
      } else if (value === '005') {  // 외환은행
        return '/image/bank_hana.svg'
      } else if (value === '081') {  // KEB 하나은행
        return '/image/bank_hana.svg'
      } else if (value === '071') {  // 우체국
        return '/image/bank_post.svg'
      } else if (value === '045') {  // 새마을금고
        return '/image/bank_kfcc.svg'
      } else if (value === '089') {  // K 뱅크
        return '/image/bank_k.svg'
      } else if (value === '090') {  // 카카오뱅크
        return '/image/bank_kakao.svg'
      }
      return '/image/bank_default.svg'
    }
  }
}
</script>

<style scoped>
.bank_list {
    display: flex;
    flex-wrap: wrap;
}
</style>
