<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list h-60">
      <div class="d-flex-row jc-between">
        <h5>월 납입일 선택</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul class="content-list">
        <li v-for="(date, index) in dates" :key="index">
          <a @click="selected(date)">
            <span>{{ date.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonthlyDateSelect",
  props: ['dates'],
  methods: {
    selected(_date) {
      this.$emit('selected', _date)
    }
  }
}
</script>

<style scoped>

</style>