<template>
  <div class="all-content">
    <div class="content-wrap-result bg-white content-row">
      <div class="confirm-list">
        <p>
          <img src="/image/wb_logo.png" alt="캐릭터">
          <span>심사 신청이 완료되었습니다.</span>
          <em>심사기간은 영업일 기준 평균 3일 이내 완료됩니다.
            <br>심사완료가 늦어지는 경우 지사에 문의하세요.
          </em>
          <em class="mt-50">{{ nickname }} / {{ type === 1 ? '개인' : '사업자' }}
            <br>{{ bank }} {{ number }}({{ name }})
          </em>
        </p>
        <ul class="price-list mb-15">
          <li>
            <strong>신청일</strong>
            <em>{{ date | formatDateAndTime }}</em>
          </li>
          <li class="info">
            <strong>*영업일: 토요일, 일요일 및 공휴일(임시공휴일 포함)을 제외한 사업주체가 영업을 하는 일자</strong>
          </li>
        </ul>
        <button class="btn-l btn-pink mb-50 btn-round-30" style="height: 60px;" @click="$router.back()">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterComplete",
  data() {
    return {
      nickname: '',
      type: '',
      number: '',
      name: '',
      bank: '',
      date: ''
    }
  },
  mounted() {
    this.nickname = this.$route.params.nickname
    this.type = this.$route.params.type
    this.number = this.$route.params.number
    this.name = this.$route.params.name
    this.bank = this.$route.params.bank
    this.date = this.$route.params.date
  },
}
</script>

<style scoped>

</style>