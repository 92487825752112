<template>
  <div class="all-content">
    <div class="certification-wrap">
      <div class="certification-box bg-pink">
        <span class="fz-28">핀번호 설정</span>
        <p class="mb-30">변경할 핀번호를 입력해주세요.</p>
        <div class="certification-bullets">
          <span :class="{'bullets-active' : pin.length >= 1}"></span>
          <span :class="{'bullets-active' : pin.length >= 2}"></span>
          <span :class="{'bullets-active' : pin.length >= 3}"></span>
          <span :class="{'bullets-active' : pin.length >= 4}"></span>
          <span :class="{'bullets-active' : pin.length >= 5}"></span>
          <span :class="{'bullets-active' : pin.length >= 6}"></span>
        </div>
      </div>
      <div class="number-area">
        <ul>
          <li>
            <button @click="numBtn('1')">1</button>
            <button @click="numBtn('2')">2</button>
            <button @click="numBtn('3')">3</button>
          </li>
          <li>
            <button @click="numBtn('4')">4</button>
            <button @click="numBtn('5')">5</button>
            <button @click="numBtn('6')">6</button>
          </li>
          <li>
            <button @click="numBtn('7')">7</button>
            <button @click="numBtn('8')">8</button>
            <button @click="numBtn('9')">9</button>
          </li>
          <li>
            <button @click="cancel()" class="cancel">취소</button>
            <button @click="numBtn('0')">0</button>
            <button @click="backspace()">
              <img src="/image/cancel.svg" alt="지우기">
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePinRegist",

  data() {
    return {
      old_pin:'',
      pin: '',
      type: '0',
    }
  },
  mounted() {
    this.old_pin = this.$route.params.old_pin
    this.type = this.$route.params.type
  },
  methods: {
    numBtn(_num) {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin + _num
      if (this.pin.length === 6 ) {
        this.$router.replace({name:'ChangePinRegistRE',params:{ old_pin:this.old_pin, new_pin:this.pin, type:this.type }})
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
