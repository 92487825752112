<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>회원가입 정보 입력</p>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <div class="charge-form">
          <p class="mb-10 fz-16 color-darkgray-4F4F4F">계정</p>
          <div class="select-disable">
            <RPInputBorder
                :type="'text'"
                :model="id"
                :disable="true"
                @update="(_model) => {this.id=_model}"
            />
          </div>
          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">비밀번호<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호(영문, 숫자, 특수문자 포함 8자 이상)'"
                :model="password"
                :max-length="20"
                @update="(_model) => {this.password=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">비밀번호 확인<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호를 한번 더 입력해주세요'"
                :model="passwordCk"
                :max-length="20"
                @update="(_model) => {this.passwordCk=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">상호명<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'상호명을 입력해주세요'"
                :model="shopName"
                @update="(_model) => {this.shopName=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">대표자명<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'대표자명을 입력해주세요'"
                :model="name"
                @update="(_model) => {this.name=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">사업자 등록번호<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'사업자 등록번호를 입력해주세요'"
                :model="business"
                :input-mode="'numeric'"
                :max-length="10"
                @update="(_model) => {this.business=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">사업장 주소<span class="color-red">*</span></p>

          <div class="d-flex-row">
            <div class="select-disable">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'우편번호'"
                  :model="zipcode"
                  disable="true"
                  @update="(_model) => {this.zipcode=_model}"
              />
            </div>
            <button class="input-address btn-dgray-A09E9E ml-10" @click="searchAddress">
              주소검색
            </button>
          </div>

          <div class="select-disable mt-10">
            <RPInputBorder
                :type="'text'"
                :model="address"
                disable="true"
                @update="(_model) => {this.address=_model}"
            />
          </div>
          <div class="select mt-10">
            <RPInputBorder
                :type="'text'"
                :placeholder="'상세주소를 입력해주세요'"
                :model="addressDetail"
                @update="(_model) => {this.addressDetail=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">사업장 연락처<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'사업장 연락처를 입력해주세요'"
                :model="shopHp"
                :input-mode="'numeric'"
                :max-length="11"
                @update="(_model) => {this.shopHp=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">이메일<span class="color-red">*</span></p>
          <div class="select">
            <RPInputBorder
                :type="'email'"
                :placeholder="'이메일을 입력해주세요'"
                :model="email"
                @update="(_model) => {this.email=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">상위 영업점 코드</p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'상위 영업점 코드를 입력해주세요'"
                :model="branchCode"
                :max-length="7"
                @update="(_model) => {this.branchCode=_model}"
            />
          </div>

          <p class="mb-10 mt-30 fz-16 color-darkgray-4F4F4F">필요서류<span class="color-red">*</span></p>
          <div class="d-flex-row" :class="{ 'mt-10' : index > 0}" v-for="(file, index) in files" :key="index">
            <span class="file-title">{{
                file.title
              }}</span>
            <div class="input-box question-input-box d-flex-row w-50">
              <input type="text" class="w-100" style="background: transparent" readonly v-model="file.fileName">
              <button v-show="file.fileName !== ''" type="button" class="btn-file-delete"
                      @click="deleteFileList(index)"><i
                  class="icon-close color-darkgray"></i></button>
            </div>

            <input ref="fileUploader" type="file" class="input-box question-input-box w-50" @change="upload($event, index)"
                   v-bind:id="'imageFileUpload0' + (index+1)" accept="image/*" hidden>
            <button class="w-20 self-center"
                    @click="uploadImage(index)"><img src="/image/upload_file.svg">
            </button>
          </div>
          <button class="btn-file-add"
                  v-show="files.length < 6"
                  @click="addFileList">+ 추가
          </button>
        </div>
      </div>
      <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext()">다음</button>
    </div>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";

export default {
  name: "SsoSignUp01",
  components: {
    RPInputBorder,
  },
  data() {
    return {
      auth: '',
      id: '',
      password: '',
      passwordCk: '',
      shopName: '',
      name: '',
      business: '',
      zipcode: '',
      address: '',
      addressDetail: '',
      email: '',
      shopHp: '',
      branchCode: '', // 상위 영업점 코드

      files: [
        {
          title: '사업자등록증',
          fileName: '',
          file: null,
        },
        {
          title: '대표자신분증',
          fileName: '',
          file: null,
        },
      ],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'TermAgree') {
        vm.clearData()
      }
    })
  },
  mounted() {
    this.id = this.$route.params.id
    this.auth = this.$route.params.auth
  },
  methods: {
    clearData() {
      this.id = this.$route.params.id
      this.auth = this.$route.params.auth

      if (this.$route.params.isClear) {
        this.password = ''
        this.passwordCk = ''
        this.shopName = ''
        this.name = ''
        this.business = ''
        this.zipcode = ''
        this.address = ''
        this.addressDetail = ''
        this.email = ''
        this.shopHp = ''
        this.branchCode = '' // 상위 영업점 코드

        this.files = [
          {
            title: '사업자등록증',
            fileName: '',
            file: null,
          },
          {
            title: '대표자신분증',
            fileName: '',
            file: null,
          },
        ]
      }
    },
    searchAddress() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          console.log('postdata', data)
          let fullAddr = data.address; // 최종 주소 변수
          let extraAddr = ''; // 조합형 주소 변수

          // 기본 주소가 도로명 타입일때 조합한다.
          if (data.addressType === 'R') {
            //법정동명이 있을 경우 추가한다.
            if (data.bname !== '') {
              extraAddr += data.bname;
            }
            // 건물명이 있을 경우 추가한다.
            if (data.buildingName !== '') {
              extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
            fullAddr += (extraAddr !== '' ? ' (' + extraAddr + ')' : '');
          }

          this.zipcode = data.zonecode;
          this.address = fullAddr;
        }
      }).open()
    },
    addFileList(){
      if (this.files.length === 2){
        this.files.push({title: '추가서류', fileName: '', file: null})
      } else {
        this.files.push({title: '', fileName: '', file: null})
      }
    },
    deleteFileList(_index) {
      this.files[_index].fileName = ''
      this.files[_index].file = null

      this.changeNextBtn()
    },
    uploadImage(_index) {
      window.document.getElementById('imageFileUpload0' + (_index+1)).click()
    },
    upload(e, _index) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      this.files[_index].file = files[0]
      this.files[_index].fileName = files[0].name

      this.$refs.fileUploader[_index].value = ''

      this.changeNextBtn()
    },
    goNext() {
      // 필수값 체크 (비밀번호, 상호명, 대표자명, 사업자등록번호, 우편번호/주소, 파일)
      if (this.password === '') {
        this.notify('error', '비밀번호를 입력해주세요.')
        return
      }
      if (this.passwordCk === '') {
        this.notify('error', '비밀번호를 한번 더 입력해주세요.')
        return
      }

      if (this.password !== this.passwordCk) {
        this.notify('error', '비밀번호가 일치하지 않습니다.')
        return
      }
      if (!this.validatePw(this.password)){
        this.notify('error', '비밀번호는 영문, 숫자, 특수문자 포함 8자 이상 20자 이하로 입력해주세요.')
        return
      }
      if (this.shopName === ''){
        this.notify('error', '상호명을 입력해주세요.')
        return
      }
      if (this.name === ''){
        this.notify('error', '대표자명을 입력해주세요.')
        return
      }
      if (this.business === ''){
        this.notify('error', '사업자 등록번호를 입력해주세요.')
        return
      }
      if (this.zipcode === '' || this.address === ''){
        this.notify('error', '주소를 검색해주세요.')
        return
      }
      if (this.shopHp === ''){
        this.notify('error', '사업장 연락처를 입력해주세요.')
        return
      }
      if (this.email === ''){
        this.notify('error', '이메일을 입력해주세요.')
        return
      }
      if (!this.validateEmail(this.email)){
        this.notify('error', '이메일을 정확히 입력해주세요.')
        return
      }
      if (this.files[0].file === '' || this.files[0].fileName === '' || this.files[1].file === '' || this.files[1].fileName === ''){  // 사업자등록증, 대표자신분증
        this.notify('error', '필수서류들을 첨부해주세요.')
        return
      }

      // 회원가입(step01)
      let formData = new FormData()
      formData.append('id', this.id)
      formData.append('password', this.password)
      formData.append('passwordCk', this.password)
      formData.append('shopName', this.shopName)
      formData.append('shopHp', this.shopHp)  // 가맹점 연락처
      formData.append('name', this.name)
      formData.append('business', this.business)
      formData.append('address', this.address)
      formData.append('addressDetail', this.addressDetail)
      formData.append('zipcode', this.zipcode)
      formData.append('email', this.email) // 이메일
      formData.append('branchCode', this.branchCode)
      formData.append('auth', this.auth) // 나이스 인증 번호
      formData.append('businessImg', this.files[0].file)  // 사업자등록증00
      formData.append('bossIdImg', this.files[1].file)  // 대표자신분증
      // formData.append('accountImg', this.files[2].file) // 통장사본

      for (let i = 2; i < this.files.length ; i ++){
        if (this.files[i].file != null){
          formData.append('attachments', this.files[i].file)  // 추가 첨부서류
        }
      }

      this.$post(this.$AUTH_SIGNUP_STEP01, 'signup01_signupstep01', formData, true,() => { // 회원가입(step01)
        this.$router.push({ name: 'SsoSignUp02', params: { id: this.id, password: this.password, shopName: this.shopName, shopHp: this.shopHp, name: this.name, business: this.business, address: this.address,
            addressDetail: this.addressDetail, zipcode: this.zipcode, email: this.email, branchCode: this.branchCode, auth: this.auth, files: this.files, isClear: true }})

      }, (result) => {
        this.httpError(result.data)
      })
    },
    changeNextBtn() {
      if (this.password !== '' && this.passwordCk !== '' && (this.password === this.passwordCk) && this.validatePw(this.password)
          && this.shopName !== '' && this.name !== '' && this.business !== '' && this.zipcode !== '' && this.address !== '' && this.shopHp !== '' && this.email !== '' && this.validateEmail(this.email)
          && this.files[0].file !== '' && this.files[0].fileName !== '' && this.files[1].file !== '' && this.files[1].fileName !== '') {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-pink"
      } else {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      }
    }
  },
  watch: {
    password() {
      this.changeNextBtn()
    },
    passwordCk() {
      this.changeNextBtn()
    },
    shopName() {
      this.changeNextBtn()
    },
    name() {
      this.changeNextBtn()
    },
    business() {
      this.changeNextBtn()
    },
    zipcode() {
      this.changeNextBtn()
    },
    address() {
      this.changeNextBtn()
    },
    shopHp() {
      this.changeNextBtn()
    },
    email() {
      this.changeNextBtn()
    },
  }
}
</script>

<style scoped>
.file-title {
  width: 30%;
  align-self: center;
  margin-right: 27px;
  font-size: 16px;
  color: #808080;
}

.btn-file-add {
  border: 1px solid #C4C4C4;
  width: 60px;
  height: 27px;
  border-radius: 50vh;
  display: block;
  font-size: 13px;
  margin-left: auto;
  margin-top: 10px;
  color: #B8B8B8;
}

.btn-file-delete {
  position: relative;
  align-self: center;
  width: 24px;
  height: 24px;
  margin-left: 5px !important;
}
</style>
