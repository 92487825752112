<template>
  <div class="all-content bg-gray" style="padding-top:120px; padding-bottom: 80px;">
    <header class="header-wrap border-bottom">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>월세 결제</p>
    </header>
    <div class="rent-payment-tab" style="top:60px;">
      <div class="pt-15 d-flex-row pl-20">
        <div class="ml-auto">
          <button class="mr-10" :class="[selectedTabIdx === null ? 'tab-payment-active' : 'tab-payment']"
                  @click="selectedTab(null)">전체
          </button>
          <button class="mr-10" :class="[selectedTabIdx === 0 ? 'tab-payment-active' : 'tab-payment']"
                  @click="selectedTab(0)">심사중
          </button>
          <button class="mr-10" :class="[selectedTabIdx === 1 ? 'tab-payment-active' : 'tab-payment']"
                  @click="selectedTab(1)">결제대기
          </button>
          <button :class="[selectedTabIdx === 2 ? 'tab-payment-active' : 'tab-payment']" @click="selectedTab(2)">심사반려
          </button>
        </div>
      </div>
    </div>

    <div>
      <div v-if="items.length > 0">
        <section v-for="(item, idx) in items" :key="idx" class="sales-history-all-section">
          <div class="white-box-br-15">
            <div class="d-flex-row jc-between" :class="{ 'border-bottom' : item.count > 0 }">
              <span v-if="selectedTabIdx === 0"
                    class="fz-18 color-gray-4F4F4F mb-20">이번달 {{ item.type | chargeTypeFilter }}<em
                  v-if="item.count > 1"> ({{ item.count }})</em></span>
              <span v-else class="fz-18 color-gray-4F4F4F mb-20"
                    :class="{ 'color-gray' : item.state === '0' }">{{ item.type | chargeTypeFilter }}<em
                  v-if="item.count > 1"> ({{ item.count }})</em></span>
              <span class="payment-type"
                    :class="[
                      { 'payment-reviewing' : item.state === '0' },
                      { 'payment-review-return' : item.state === '2' },
                      { 'payment-ready' : item.state === '6' }
                    ]">{{ item.state | stateFilter }}</span>
            </div>
            <div v-for="(details, index) in item.items" :key="index" class="mt-20"
                 @click="goNext(item, details)">
              <div class="d-flex-row jc-between">
                <div class="d-flex">
                  <span class="fz-17 color-gray-4F4F4F"
                        :class="{ 'color-gray' : item.state === '0' }">{{ details.monthlyName }}</span>
                  <span v-if="details.paymentType === 'R'" class="color-blue-5064AF fz-14 mt-10">정기 <em
                      class="color-gray">{{ details.paymentDate }}</em></span>
                  <span v-else class="fz-14 mt-10" style="color: #2358a0">일반 <em
                      v-if="selectedTabIdx !== 1" class="color-gray">결제 예정</em></span>
                </div>
                <div class="d-flex">
                      <span class="fee-tit color-gray-4F4F4F" :class="{ 'color-gray' : item.state === '0' }">
                        {{ details.monthlyAmount | numberWithCommas }}<em>원</em>
                      </span>
                  <span class="fee-body mt-10"><em
                      class="color-gray">{{ details.paymentDuration }}</em></span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div v-else>
        <div class="list-none-wrap noCard">
          <div class="list-none">
            <p class="list-none-title fz-20 color-darkgray-4F4F4F">등록된 내역이 없습니다.</p>
          </div>
        </div>
      </div>

    </div>
    <button id="nextBtn" class="btn_bottom btn btn-m btn-pink" @click="showSelectRentType">월세 결제 추가 신청</button>
    <rent-type-select
        v-show="showRentType"
        :conditions="typeList"
        @selected="setSelectedType"
        @close="showRentType = false"
    />
  </div>
</template>

<script>
import RentTypeSelect from "@/components/main/RentTypeSelect.vue";

export default {
  name: "RentBeforePayment",
  components: {RentTypeSelect},
  data() {
    return {
      selectedTabIdx: null,
      // type : '', // 0:월세, 1:관리비, 2:보증금, 3:수리비, 5:기타, '':전체
      items: [],
      subItems: [],
      typeList: [
        {
          groupType: '0',
          name: '월세',
        },
        {
          groupType: '1',
          name: '관리비',
        },
        {
          groupType: '2',
          name: '보증금',
        },
        {
          groupType: '3',
          name: '수리비',
        },
        // {
        //   groupType: '4',
        //   name: '교육비',
        // },
        {
          groupType: '5',
          name: '기타',
        },
      ],
      showRentType: false,
      fee: '',
      tax: '',
      cardList: [],
      bankList: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.selectedTab(null)
    },
    selectedTab(_tab) {
      this.selectedTabIdx = _tab

      this.getRentPaymentList()
    },
    getRentPaymentList() {
      this.items = []
      let url
      if (this.selectedTabIdx === null) {
        url = this.$LOG_MONTHLY
      } else {
        url = this.$LOG_MONTHLY + '?type=' + this.selectedTabIdx
      }

      this.$get(url, this.$options.name + '_getRentPaymentList', (result) => {
        console.log(result.data)
        this.items = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    setSelectedType(_type) {
      this.showRentType = false

      this.$router.push({
        name: 'RentPayment', params: {
          groupType: _type.groupType,
          randomVal: Math.random().toString(),
          type: '0', //등록
        }
      })
    },
    showSelectRentType() {
      this.showRentType = true
    },
    goNext(item, details) {
      if(item.state == 6){
        this.$router.push({
          name: 'RentPaymentInfoInput', params: {
                type: 0,
                idx: details.idx,
                amount: details.monthlyAmount,
            }
        })
      } else {
        this.$router.push({ name : 'RentBeforePaymentDetail', params: { monthlyPaymentIdx: details.idx } })
      }
    },
    // getForm(idx){
    //   this.$get(this.$MONTHLY + '/' + idx, 'rentPayment_monthly',(result) => {
    //     console.log(result.data, 'monthly data')
    //     this.cardList = result.data.cardList
    //     this.bankList = result.data.bankList
    //     this.minDate = result.data.minDate
    //     this.fee = result.data.fee
    //     this.tax = result.data.tax
    //
    //   }, (result) => {
    //     this.httpError(result.data)
    //   })
    // }
  },
  filters: {
    chargeTypeFilter: (value) => {
      if (value === '0') {
        return '월세'
      } else if (value === '1') {
        return '관리비'
      } else if (value === '2') {
        return '보증금'
      } else if (value === '3') {
        return '수리비'
      } else if (value === '5') {
        return '기타'
      }
    },
    stateFilter: (value) => {
      if (value === '0') {
        return '심사중'
      } else if (value === '1') {
        return '납입중'
      } else if (value === '2') {
        return '심사반려'
      } else if (value === '3') {
        return '납입실패'
      } else if (value === '4') {
        return '납입중단'
      } else if (value === '5') {
        return '납입완료'
      } else if (value === '6') {
        return '결제대기'
      }
    },
  },
}
</script>

<style scoped>
.btn_bottom {
  position: fixed;
  bottom: 0;
}
</style>