<template>
  <div class="all-content bg-white card">
    <Menu ref="myInfoMenu"/>
    <div class="content-wrap" v-show="items.length === 0">
      <div class="list-none-wrap noCard">
        <div class="list-none">
          <p class="list-none-title fz-20 color-darkgray-4F4F4F">등록된 배대사가 없습니다.</p>
        </div>
        <button class="btn-l btn-pink btn-round-5" @click="registDeliveryAgency()">배대사 등록</button>
      </div>
    </div>
    <div class="content-wrap-footer" v-show="items.length > 0">
      <div class="main-wrap">
        <section class="section-radius p-15 bg-white mb-10" v-for="item in items" :key="item.idx">
          <div class="bank" style="justify-content: space-between" @click="openSetting(item)">
            <div class="text align-f card-detail ml-5">
              <strong>{{ item.nickname ? item.nickname: '이름없음('+item.idx+')' }}</strong>
              <em class="fz-16 color-darkgray-4F4F4F">{{ item.bankName }} {{ item.accountNumber }} {{ item.depositor }}</em>
            </div>
            <div class="mr-5">
              <p :class="formState(item.reviewState).color">{{ formState(item.reviewState).state }}</p>
            </div>
          </div>
        </section>
      </div>
    </div>

    <footer class="btn-wrap" v-show="items.length > 0">
      <div class="row">
        <button class="btn-l btn-pink btn-round-5" @click="registDeliveryAgency()">배대사 등록</button>
      </div>
    </footer>

    <delivery-agency-setting
        v-show="showSetting"
        :conditions="typeList"
        :selectedDeliveryAgency="selectedDeliveryAgency"
        @selected="setSelectedCondition"
        @close="showSetting=false"
    />
  </div>
</template>

<script>
import Menu from '@/components/myinfo/MenuCmp'
import DeliveryAgencySetting from "@/components/myinfo/deliveryAgency/DeliveryAgencySetting.vue";

export default {
  name: "DeliveryAgencyList",
  components: {DeliveryAgencySetting, Menu},
  data() {
    return {
      items: [],
      showSetting: false,
      typeList: [
        {
          type: '1',
          name: '배대사 삭제',
        },
      ],
      selectedDeliveryAgency: {},
    }
  },

  created() {
    this.getItems()
  },

  mounted() {
    this.$refs.myInfoMenu.focusTab(1)
  },

  methods: {
    formState(state) {
      let t
      switch (state) {
        case 'UNDER_REVIEW':
          t = {
            state: '심사중',
            color: 'color-normal'
          }
          break;
        case 'APPROVED_REVIEW':
          t = {
            state: '심사완료',
            color: 'color-pink'
          }
          break;
        case 'DECLINED_REVIEW':
          t = {
            state: '심사반려',
            color: 'color-red'
          }
          break;
      }
      return t
    },

    getItems() {
      this.$get(this.$ACCOUNT, 'deliveryagencylist_mounted',(result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },

    openSetting(_card) {
      console.log(_card)
      this.selectedDeliveryAgency = _card
      if(this.selectedDeliveryAgency.reviewState === 'UNDER_REVIEW'){
        this.typeList = [
          {
            type: '1',
            name: '심사 취소 및 삭제',
          },
        ]

        this.showSetting = true
      } else if(this.selectedDeliveryAgency.reviewState === 'APPROVED_REVIEW'){
        this.typeList = [
          {
            type: '1',
            name: '배대사 삭제',
          },
        ]

        this.showSetting = true
      } else if(this.selectedDeliveryAgency.reviewState === 'DECLINED_REVIEW'){
        this.$router.push({ name: 'RegistDeliveryAgency', params: { idx: this.selectedDeliveryAgency.idx }})
      }
    },

    setSelectedCondition(_condition) {
      this.showSetting = false
      let confirmText = ''
      console.log(this.selectedDeliveryAgency)
      if(this.selectedDeliveryAgency.reviewState === 'UNDER_REVIEW'){
        confirmText = '배대사 가상계좌 심사중입니다. 심사 취소 후 삭제하시겠습니까?'
      } else {
        confirmText = '배대사 가상계좌를 삭제하시겠습니까?'
      }
      if (_condition.type === '1') {
        //삭제
        this.$root.$refs.Confirm.showDialog('', confirmText, '확인', '취소', () => {
          this.$delete(this.$ACCOUNT_DELETE + '/' + this.selectedDeliveryAgency.idx, 'ReviewDeliveryAgency', true, () => {
            this.notify('success', '성공적으로 삭제되었습니다.', false)
            this.getItems()
          }, (result) => {
            this.httpError(result.data)
          })
        }, () => {
        })
      }
    },

    registDeliveryAgency() {
      this.$router.push({name:'RegistDeliveryAgency'})
    },
  }
}
</script>

<style scoped>

</style>
